/* Modal Container */
.modal-container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid #e3e7ec;
  max-width: 600px;
  width: 100%;
}
.interestedUserModal :global(.modal-content) {
  min-width: 560px;
  max-width: 720px;
}
.interestedUserModal :global(.modal-dialog) {
  margin: auto !important;
  min-width: 560px;
  max-width: 720px;
}

/* Modal Title */
.modal-title {
  text-align: center;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 12px;
}

/* Table Container */
.table-container {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #ffffff;
  overflow-x: auto; /* Ensures responsiveness for smaller screens */
}

/* User Table */
.user-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header */
.user-table thead tr {
  background-color: #f2f2f2; /* Soft grey header */
  color: #000000;
  text-align: left;
  border-bottom: 2px solid #000000; /* Black border for sharpness */
  font-weight: 600;
}

.user-table th,
.user-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #ccc;
  color: #000000;
  font-size: 14px;
}

/* Alternating Rows */
.user-table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.user-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover Effect */
.user-table tbody tr:hover {
  background-color: #eaeaea;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
}

/* Buttons */
.download-button,
.close-button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  font-size: 16px !important;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.download-button:hover,
.close-button:hover {
  background-color: #333333 !important;
  transform: translateY(-2px);
}

.close-button {
  background-color: #d9534f;
  font-size: 16px !important;
}

.close-button:hover {
  background-color: #c9302c;
}

/* Horizontal Rule */
hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 16px 0;
}

/* ================= Responsive Design ================== */

/* Tablets & Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {
  .modal-container {
    width: 95%;
  }

  .modal-title {
    font-size: 20px;
  }

  .table-container {
    overflow-x: auto;
  }

  .user-table {
    min-width: 600px;
  }

  .user-table th,
  .user-table td {
    padding: 10px 12px;
    white-space: nowrap;
  }

  .modal-footer {
    flex-direction: row;
    gap: 8px;
  }

  .download-button,
  .close-button {
    padding: 4px 12px;
  }
}

/* Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .modal-title {
    font-size: 18px;
  }

  .user-table th,
  .user-table td {
    padding: 8px 10px;
    font-size: 14px;
  }
  .interestedUserModal :global(.modal-content) {
    min-width: 100%;
    max-width: 100%;
  }
  .interestedUserModal :global(.modal-dialog) {
    min-width: 100%;
    max-width: 100%;
    margin: auto !important;
    padding: 12px !important;
  }
  .download-button,
  .close-button {
    padding: 4px 12px;
  }
}
