.interaction-btn {
  margin: 0;
  padding: 0;
  color: #666 !important;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  display: flex;
  align-items: center;
  border: none;
}

.interaction-btn img {
  margin-right: 4px;
}

.request-search-input {
  background: #fff;
  border: 1px solid #666;
  border-radius: 50px !important;
  color: #666;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
  max-width: 398px;
  padding: 8px 18px;
}

.requestPopupContainer :global(.popover) {
  max-width: 500px;
  min-width: 300px;
}

.userContainer {
  border-top: 1px solid #d2d2d2;
  padding: 8px 0px 0px 0px;
  display: flex;
}

.userSelect {
  align-self: center;
  padding: 0px 8px;
}

.avatarList {
  padding: 0px 0px 12px 0px;
  display: flex;
}

.avatarImage {
  flex: 1;
}

.avatarPlus,
.avatarCount {
  font-size: 16px;
  font-weight: bold;
}

.avatarImage img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #e6e6e6;
}

.applyContainer {
  padding: 12px 12px 0px 12px;
  display: flex;
  margin: 0px -16px;
  border-top: 1px solid #d2d2d2;
}

.roundButton {
  padding: 8px 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  border: 1px solid #000;
  background-color: #fff !important;
  line-height: normal;
  color: #000 !important;
  border-radius: 50px !important;
  line-height: 14px !important;
}

.reset :global(.btn) {
  background: white;
  color: black;
}

.request :global(.btn) {
  color: #fff !important;
  background-color: #000 !important;
}

.reset {
  flex: 1;
  text-align: left;
}

.request {
  flex: 1;
  text-align: right;
}

@media screen and (max-width: 700px) {
  .interaction-btn {
    color: var(--grey-grey-60, #666);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .interaction-btn img {
    height: 14px;
    width: 14px;
    margin-right: 2px;
  }
  .request-search-input {
    border-radius: 50px !important;
    border: 1px solid #666;
    background: #fff;
    padding: 4px 12px;
    color: #666;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 250px;
    margin-bottom: 12px;
    height: 26px;
  }
  .requestPopupContainer :global(.popover) {
    min-width: 270px;
    max-width: 300px;
    width: 250px;
    inset: 0px auto auto -30px !important;
  }

  .avatarList {
    padding: 0px 0px 8px 0px;
    display: flex;
    align-items: center;
  }

  .avatarImage img {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    position: relative;
  }

  .avatarCount {
    font-size: 12px;
    font-weight: bold;
    font-family: Roboto;
    line-height: normal;
  }
  .roundButton {
    padding: 2px 10px;
    font-style: normal;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 700;
    border: 1px solid #000;
    background-color: #fff !important;
    line-height: normal;
    color: #000 !important;
    border-radius: 50px !important;
    height: 28px !important;
    line-height: 14px !important;
  }

  .userSelect input {
    border-radius: 0.25em;
    display: flex;
    align-items: center;
    margin: 0;
    border-color: #666666;
  }
  .userContainer {
    padding: 10px 0 0;
    align-items: center;
  }
}
