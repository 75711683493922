.pdfViewerWrapper ::-webkit-scrollbar {
  display: none;
}
.pdfViewer-parent {
  display: flex;
  flex-direction: column;
}
.pdf-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.pdf-page-download-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 6px auto 0;
}
.page-download-wrapper {
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  color: #333;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}
.download-btn {
  background: #fff !important;
  color: #1a1a1a !important;
  border: none;
  font-size: 14px;
  position: absolute;
  right: 34px;
  top: 0;
  padding: 0;
}
@media screen and (max-width: 700) {
  .download-btn {
    font-size: 12px;
  }
}
