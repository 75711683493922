.signup-navbar {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  filter: drop-shadow(-2px 2px 8px rgba(153, 153, 153, 0.25));
  background: #fff;
  padding: 12px 100px;
  margin-bottom: 40px;
  background-image: url('../images/homheader_logo.svg');
  background-size: cover;
}
.membership-number-error {
  border-color: red !important;
}
.signup-navbar button {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  border: none !important;
}

.signup-navbar .home-btn img {
  margin-right: 4px;
}

/* Form Styling */

.signup-form {
  padding: 20px 100px;
}

.signup-form .form-container {
  border: 3px solid var(--grey-grey-80, #333);
  border-radius: 52px;
  padding: 43px 52px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container .brand-logo {
  margin-bottom: 26px;
}

.brand-logo img {
  width: 100%;
}

.wc-form-container {
  width: 75%;
  margin-top: 24px;
  padding-bottom: 20px;
}

.wc-form-container.w-100 {
  width: 100% !important;
}

.wc-form .form-input-container {
  margin-bottom: 24px;
  margin-left: 18px;
}

.form-input-container .form-label {
  margin-bottom: 12px;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.wc-form.not-selected .form-input-container .form-label {
  margin-left: 10px;
}

.form-input-container .form-input {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 6px 12px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 90%;
  min-width: 230px
}

.wc-form.not-selected .form-input-container .form-input {
  background-color: #e6e6e6;
}

.form-input-container .form-input::placeholder {
  color: #666;
}

.otp-container {
  display: flex;
  align-items: center;
}

.otp-container .form-label {
  margin-bottom: 0px;
  margin-right: 13px;
}

.otp-container .form-input {
  margin-right: 16px;
  width: 40%;
}

.otp-container .otp-generate-btn {
  border-radius: 12px;
  background-color: #999;
  padding: 14px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
}

.form-submit {
  padding: 6px 30px;
  border-radius: 10px;
  background-color: #333;
  box-shadow: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0 20px 0;
}

.form-input-container .input-container {
  position: relative;
}

.input-container .edit-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-weight: 700;
  font-size: 12px;
}

.input-container .edit-btn-error {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  right: 10px;
  font-weight: 700;
  font-size: 12px;
}
.wc-form .form-group .form-label {
  font-size: 18px;
}

.org-name .org-name-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #1a1a1a;
  padding: 6px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
  min-width: max-content;
}

.org-name-label input {
  margin-top: 0;
  border: 1px solid black;
}
.noneSelected input {
  margin: 0;
  border: 1px solid black;
}

.file-label {
  border: 1px solid #000;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.w-100 {
  width: 100%;
}

.error {
  color: red;
}

.font-bold-text {
  font-size: 14px;
  font-weight: bold;
}
.user-consent-container {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}
.tooltip-img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-img img {
  height: 20px;
  width: 20px;
}
.membership-checkbox-container {
  padding: 0 20px;
}
.none-active {
  background: #dbdbdb !important;
}
.org-name .noneSelected {
  border-top: 2px dotted #e1e1e1;
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  color: #1a1a1a;
  padding: 20px 0 0 0;
  align-items: center;
  gap: 3px;
}

@media screen and (max-width: 700px) {
  .wc-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form-input-container .form-label {
    margin-left: 5px;
  }
  .org-name .noneSelected {
    border-top: 1px dotted #e1e1e1;
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: left;
    width: 100%;
    font-size: 16px;
    padding: 10px 0 0 0 !important;
  }
  .user-consent-container {
    display: flex;
    gap: 10px;
    margin: 10px 0 0 0;
  }
  .user-consent-container input {
    border: 1px solid black;
  }

  .signup-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-image: none;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .signup-navbar button {
    font-size: 16px;
    color: #000 !important;
  }

  .signup-form {
    padding: 20px 20px;
    margin-bottom: 10px;
    margin-top: 52px;
  }
  .form-input-container .form-input {
    width: 90%;
  }
  .org-name .org-name-label {
    align-items: center;
    display: flex;
    gap: 10px;
    font-size: 16px;
  }
  .org-name-label input {
    margin: 0;
    border: 1px solid black;
    margin-top: 7px;
  }
  .noneSelected input {
    margin: 0;
    border: 1px solid black;
    margin-top: 7px;
  }
  .signup-form .form-container {
    border: none;
    border-radius: 52px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-container .brand-logo {
    margin-bottom: 20px;
  }

  .brand-logo img {
    width: 100%;
  }

  .wc-form-container {
    width: 100%;
    margin-top: 0;
    padding-bottom: 10px;
  }
  .form-group .label {
    font-size: 14px;
  }
  .wc-form label {
    font-size: 14px;
  }
  .form-input-container label b {
    font-size: 14px;
  }
  .form-input-container label b {
    font-size: 14px;
  }
  .wc-form input {
    font-size: 14px;
    height: 30px;
    padding: 0.375rem 0.75rem;
  }
  .form-input-container input {
    font-size: 14px;
    height: 30px;
    padding: 0.45rem 0.45rem;
  }
  .form-input-container img {
    height: 16px;
    width: 16px;
  }

  .form-submit {
    width: 90%;
    height: 40px;
    padding: 10px 0px;
    border-radius: 16px;
    background-color: #333;
    box-shadow: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .font-bold-text {
    font-size: 18px;
    font-weight: bold;
  }
  .tooltip-img img {
    height: 16px;
    width: 16px;
  }
}
