@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* React Rich Text Editor */
@import 'react-quill/dist/quill.snow.css';

@import url('./styles/navbar.css');
@import url('./styles/sidebar.css');
@import url('./styles/signup.css');
@import url('./styles/stepper.css');
@import url('./styles/SuccessAnimation.css');
@import url('./styles/message.css');
@import url('./styles/ChatList.css');
@import url('./styles/ChatWindow.css');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --grey-grey-100: #000000;
  --grey-grey-90: #1a1a1a;
  --grey-grey-80: #333333;
  --grey-grey-70: #4d4d4d;
  --grey-grey-60: #666666;
  --grey-grey-50: #808080;
  --grey-grey-40: #999999;
  --grey-grey-30: #b3b3b3;
  --grey-grey-20: #cccccc;
  --grey-grey-10: #e6e6e6;
  --grey-grey-00: #ffffff;

  --green: #4b9b5c;
  --accent-orange: #f2e1c0;
  --accent-Purple: #cdc0f2;
  --accent-Green: ##c0f2cb;
  --accent-Turquoise: #c0f2ec;
  --accent-Red: #f2c0c0;
  --accent-Blue: #c0daf2;
  --accent-Pink: #f2c0e7;
  --accent-Yellow: #ebf2c0;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
}

.body-big-b {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

li.selected {
  font-size: large;
  font-weight: bold;
}

p,
h3,
hr {
  margin: 0;
  padding: 0;
}

/* custom bootstrap CSS */
.btn.btn-secondary {
  background-color: #333333;
}

.btn.btn-secondary:disabled {
  background-color: #999999;
  color: #ffffff;
  border: none;
}

.form-control:valid {
  background-image: none !important;
}

::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: var(
    --grey-grey-10,
    #ccc
  ); /* Set the background color of the track */
  border-radius: 12px; /* Set the border-radius of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(
    --grey-grey-30,
    #ccc
  ); /* Set the background color of the handle */
  border-radius: 12px; /* Set the border-radius of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey-grey-40, #999); /* Set the background color on hover */
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}

@media screen and (max-width: 700px) {
  ::-webkit-scrollbar {
    width: 0px !important;
  }
}
