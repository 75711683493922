.create-signature-modal {
  width: auto;
  height: auto;
  flex-shrink: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 50px 30px 50px;
  align-items: center;
  border-radius: 24px;
  border: 1px solid var(--grey-grey-10, #e6e6e6);
  background: var(--grey-grey-00, #fff);
  top: 20%;
  position: fixed;
  left: 30%;
}
.modal-heading {
  color: var(--grey-grey-00, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  width: auto;
  border-radius: 23px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000);

  justify-content: center;
  margin-left: 8rem;
}

.modal-header-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  align-items: center;

  padding: 10px 16px;
}
.category-created-modal :global(.modal-content) {
  margin: 70px auto auto auto !important;
  border-radius: 24px;
  border: 1px solid var(--grey-grey-10, #e6e6e6);
  background: var(--grey-grey-00, #fff);
  max-width: 300px;
}
.category-created-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.category-created-modal-body p {
  color: var(--grey-grey-90, #1a1a1a);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.category-created-modal-body button {
  display: flex;
  padding: 4px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000) !important;
  color: white;
  font-size: 14px;
  margin-top: 0.5rem;
}
.modal-input {
  width: 450px;
  height: 2.25rem;
  flex-shrink: 0;
  border-radius: 12px;
  margin: 0 0 20px 0;
  padding: 0 0 0 10px;
  border: 1px solid black;
  font-size: 14px;
}
.modal-input-textarea {
  width: 450px;
  height: 4.25rem;
  flex-shrink: 0;
  border-radius: 12px;
  margin: 0 0 20px 0;
  padding: 0 0 0 10px;
  border: 1px solid black;
  padding: 8px 12px;
  font-size: 14px;
  line-height: normal;
  overflow-y: auto;
}
.model-submit,
.model-submit:disabled,
.model-submit:hover {
  display: flex;
  padding: 4px 12px;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000);
  color: white;
  margin-top: 10px;
  justify-content: center;
  font-size: 14px;
}

.createSignContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0;
}

.createSignModal :global(.modal-content) {
  border-radius: 20px;
  margin: 70px auto 0 auto;
}

.createSignModal :global(.modal-header) {
  padding: 12px 16px;
}
.createSignModal :global(.modal-header) button {
  font-size: 12px;
}
.createSignModal :global(.modal-title) {
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  margin-left: 1rem;
}

/* google calendar modal */

.googlePermissionModalFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #e5e5e5;
}

.acceptBtn {
  background-color: #1a1a1a;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.acceptBtn:hover {
  background-color: #0056b3;
}

.declineBtn {
  background-color: transparent;
  color: #000;
  font-size: 14px;
  border: none;
  cursor: pointer;
  /* text-decoration: underline; */
}

.declineBtn:hover {
  text-decoration: underline;
}

@media screen and (max-width: 700px) {
  .modal-input {
    width: 100%;
  }
  .modal-input-textarea {
    width: 100%;
  }
  .createSignModal :global(.modal-content) {
    border-radius: 20px;
    margin: 70px auto 0 auto;
    max-width: 90%;
  }
  .declineBtn {
    text-decoration: underline;
  }
}
