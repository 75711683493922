.meetingsModalBody {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: #333333;
}

.meetingAvailabilityParent {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10px 0;
  font-size: 14px;
}

.modalTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 10px;
}

.checkboxGroup label {
  font-size: 14px;
  color: #555;
  cursor: pointer;
}

.checkboxGroup input[type='checkbox'] {
  margin-right: 6px;
}

.timePicker {
  position: relative;
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.timePicker select {
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 14px;
  transition: border-color 0.3s;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.timePicker select:focus {
  border-color: #666;
  outline: none;
}

.saveButton {
  padding: 8px 10px;
  border: none;
  font-weight: bolder;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  background: #1a1a1a !important;
  transition: background-color 0.3s, transform 0.2s;
}

.saveButton:disabled {
  background-color: #666;
}

.saveButton:hover {
  background-color: #555;
  transform: scale(1.05);
}

.closeButton {
  background-color: #333;
}

.closeButton:hover {
  background-color: #444;
  transform: scale(1.05);
}

.meetingToggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.meetingToggleLabel {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.meetingToggleSwitch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  font-size: 14px;
  margin-right: 4px;
}

.meetingToggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.meetingToggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  width: 44px;
  height: 21px;
}

.meetingToggleSlider:before {
  position: absolute;
  content: '';
  height: 17px;
  width: 17px;
  left: 2px;
  bottom: 2.3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.meetingToggleInput:checked + .meetingToggleSlider {
  background-color: #4caf50; /* Active color */
}

.meetingToggleInput:checked + .meetingToggleSlider:before {
  transform: translateX(24px);
}
.slotDayContainer {
  display: flex;
  justify-content: space-between;
}

/* new */
.slotTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 14px;
}

.slotTableHeader,
.slotTableRow {
  display: flex;

  border-bottom: 1px solid #ddd;
  align-items: center;
}

.slotTableHeader {
  font-weight: bold;
  background-color: #f1f1f1;
  color: #333;
}

.slotTableHeader span,
.slotTableRow span {
  flex: 1;
  text-align: center;
  padding: 5px;
}

.slotTableRow:last-child {
  border-bottom: none;
}

.deleteIcon {
  color: #e32400;
  cursor: pointer;
  font-size: 14px;
  width: 50%;
}

.deleteIcon:hover {
  color: #b71c1c;
}
.slotListItem {
  display: flex;
  width: 100%;
  border: 1px solid #ccc;
  padding: 2px 10px;
}
.meetingSlotsContainer {
  margin-bottom: 16px;
}
@media screen and (max-width: 480px) {
  .meetingAvailabilityParent {
    width: 100%;
  }
  .meetingsModal :global(.modal-content) {
    width: 90% !important;
    margin: 0 auto !important;
  }

  .meetings_deleteIcon {
    padding: 0 15px 0 0 !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 1000px) {
  .meetingAvailabilityParent {
    width: 100%;
    padding: 0 6px;
  }
  .meetingsModal :global(.modal-content) {
    width: 90% !important;
    margin: 0 auto !important;
  }

  .meetings_deleteIcon {
    padding: 0 15px 0 0 !important;
  }
}
