.chat-list {
  padding: 0 8px;
  height: 75vh;
  overflow-y: auto;
  min-height: 200px;
  overflow-x: hidden;
}

.chat-list ul {
  list-style-type: none;
  padding: 0;
}

.chat-list li {
  border-bottom: 1px solid #e1e1e1;
}

.chat-list span {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease-in-out;
}
.search-bar {
  margin: 8px 0;
  padding: 3px 16px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 12px 0 8px 0;
}

.search-input {
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  font-size: 14px;
}

.chat-list span:hover {
  background-color: #f0f0f0;
}

.chat-list img {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50px;
}

.chat-details {
  flex: 1;
  margin-left: 1rem;
}

.chat-details .chat-details-own {
  display: inline;
  padding: 0;
  font-weight: 500;
}

.chat-details h3 {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chat-details p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.6em;
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chat-list::-webkit-scrollbar {
  width: 8px;
}

.chat-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.chat-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.chat-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-right: 14px;
  cursor: pointer;
  gap: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.chat-list-header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.chat-list-header-main span {
  font-size: 20px;
}
.chat-list-header span {
  font-size: 20px;
}

.chat-list-header-main h2 {
  color: var(--grey-grey-90, #1a1a1a);
  flex-grow: 1;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-left: 10px;
}

.chat-list-header-main img {
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  fill: #edd3c0;
  margin: 2%;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}

.chat-list-header button {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: black;
}
.chat-list-header h2 {
  flex-grow: 1;
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0%;
}
.chat-list-header img {
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  fill: #edd3c0;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}
.chatList-message {
  padding: 10px;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.chatList-message-content {
  font-size: 16px;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
