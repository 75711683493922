.dashboard-search {
  padding: 0 10px 20px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
}
.searchInput-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  gap: 10px;
}
.userPicture {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 1px solid #e6e6e6;
}
.dashboard-search-input {
  border-radius: 50px !important;
  border: 1px solid #666;
  background: #fff;
  padding: 16px 18px;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 450px;

  height: 48px;
}

.dashboard-search-attachment-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 12px;
}

.attachment-btn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  text-align: left;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.attachment-btn:last-child {
  margin-right: 0px;
}

.attachment-btn img {
  height: 20px;

  margin-right: 8px;
}

@media screen and (max-width: 700px) {
  .dashboard-search {
    display: none;
  }
}
