.item-avatar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 14px;
}

.item-avatar .avatar-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  border: 1px solid #e6e6e6;
}

.item-avatar .avatar-img-big {
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  cursor: pointer;
  height: 42px;
  margin-right: 10px;
  width: 42px;
}

.item-avatar .user-name {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0px 8px 0px 0px;
  cursor: pointer;
  line-height: 21.09px;
}

.item-avatar .follow-btn {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  cursor: pointer;
  border: none;
}
.request-verifie-logo {
  height: 10px !important;
  width: 10px !important;
}

.follow-btn img {
  margin-right: 4px;
  cursor: pointer;
  height: 12px;
  width: 12px;
}
.follow-btn-text {
  margin-top: 2px;
  font-size: 12px;
}

.city {
  font-size: 14px;
}

.color-ICAI {
  background-color: #f2e1c0 !important;
}

.color-ICSI {
  background-color: #cdc0f2 !important;
}

.color-ICMAI {
  background-color: #c0f2cb !important;
}

.color-BCI {
  background-color: #c0f2ec !important;
}
.follow-btn-wrapper {
  margin-bottom: 5px;
  min-height: 21.09px;
  display: flex;
  align-items: center;
}
.organizationWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.organizationBulletPoint {
  border-radius: 50%;
  background-color: #666;
  display: flex;
  height: 4px;
  width: 4px;
}

.ogranizationName {
  font-size: 10px;
  font-weight: 400;
}
.bulletPoint {
  background-color: #1a1a1a;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin: 0 6px;
  width: 4px;
  min-width: 4px;
}
@media screen and (max-width: 700px) {
  .item-avatar {
    margin: 0 0 10px 0;
    align-items: flex-start;
  }
  .item-avatar .user-name {
    color: #1a1a1a;
    font-size: 13px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    line-height: 21.09px;
    margin: 0px 8px 0px 0px;
    cursor: pointer;
  }
  .item-avatar .avatar-img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
  .follow-btn-wrapper {
    margin-bottom: 0;
    margin-bottom: 0;
    min-height: 20px;
    display: flex;
    align-items: center;
  }
  .follow-btn img {
    height: 10px;
    width: 10px;
  }
  .ogranizationName {
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-avatar .follow-btn {
    justify-content: right;
    margin: 0 0 0 auto;
  }
  .follow-btn-text {
    margin-top: 0px;
    font-size: 10px;
  }
  .city {
    font-size: 12px;
    font-weight: 500;
  }
}
