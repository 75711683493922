.chat-window {
  background-color: white;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.chat-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px;
  border-bottom: 1px solid #e1e1e1;
  height: 60px;
  background: #fcfcfc;
  position: sticky;
  top: 0;
  width: 100%;
}
.chat-attachment-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-content .user-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}
.back-btn {
  height: 24px;
  width: 24px;
}
.header-user-name {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.refresh-button {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.refresh-button img {
  height: 20px;
  width: 20px;
}

.trash-icon {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: red;
  padding: 2px;
}
.message-attachments {
  display: flex;
  width: 75%;
  padding: 0 28px;
}
.ellipsis {
  margin-right: 8px;
}

.actions {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 30px;
  justify-content: center;
}

.actions button {
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.chat-messages {
  padding: 12px;
}

.chat-messages p {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chat-footer {
  padding: 12px 24px 16px 24px;
  background-color: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e6e6e6;
}
.messagelinkWrapper p {
  color: #0d6efd !important;
}

.chat-footer textarea {
  flex: 1;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid var(--grey-grey-10, #e6e6e6);
  background: var(--grey-grey-00, #fff);
  resize: none;
  color: #1a1a1a;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  height: 104px;
  padding: 24px 16px 10px 10px;
}

.chat-footer-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.chat-footer-action label img {
  height: 24px;
  width: 24px;
}
.chat-footer-action span {
  margin-left: 5px;
  color: var(--grey-grey-90, #1a1a1a);

  /* Body/Body Small B */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chat-footer-action > * {
  cursor: pointer;
}

.chat-footer .footer-send-btn {
  border-radius: 23px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000);
  display: inline-flex;
  padding: 6px 8px 6px 16px;
  align-items: flex-start;
  gap: 4px;
  color: var(--grey-grey-00, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 0 auto;
}

.attachment-close {
  font-size: 12px;
  margin-bottom: 5px;
}

.attachment-button {
  display: flex;
  align-items: center;
  background-color: #0073b1;
  color: #fff;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}

.attachment-button span {
  margin-right: 10px;
}
.message-container {
  overflow-x: hidden;
  background-color: white;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrollbar */
  height: calc(100vh - 120px);
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}
/* Show the scrollbar on hover */
.message-container:hover {
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}

/* Hide the default scrollbar for WebKit (Chrome, Safari, etc.) */
.message-container::-webkit-scrollbar {
  display: none;
}

/* Style for WebKit (Chrome, Safari, etc.) */
.message-container:hover::-webkit-scrollbar {
  width: 8px;
}

.message-container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.message-container:hover::-webkit-scrollbar-track {
  background-color: transparent;
}

.message-row {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 10px 22px 10px 22px;
}
.receiver {
  background-color: #f5f5f5;
}

.message {
  display: flex;
  padding: 8px 24px;
  width: 75%;
}
.message p {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.message-content {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.sender {
  align-items: flex-end;
  background: none;
  border-radius: none;
}
.sender-message {
  padding: 0.5rem 0;
  width: 75%;
}
.sender-message {
  padding-left: 28px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.img-wrapper {
  display: flex;
  align-items: center;
}

.img-wrapper img {
  border-radius: 50%;
  height: 24px;
  width: 24px;
}

.sender .message {
  background-color: #e6e6e6;
  border-radius: 0.75rem 0.75rem 0 0.75rem;
}
