.chat-window {
  background-color: white;
  width: 350px;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid var(--grey-grey-20, #e6e6e6);
  cursor: pointer;
  box-shadow: -0.5px -0.5px 10px rgba(0, 0, 0, 0.1);
}
.messagelinkWrapper p {
  color: #003366 !important;
}

.chat-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.minimized {
  width: 350px;
  height: 50px;
  overflow: hidden;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-content h3:hover {
  text-decoration: underline;
}

.header-content img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.header-content h3 {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.header-content a {
  text-decoration: none;
  color: black;
}

.header-content a:hover {
  color: black;
  text-decoration: underline;
}

.cancel-button {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: black;
}

.refresh-button {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: black;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}

.trash-icon {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: red;
  padding: 2px;
}

.ellipsis {
  margin-right: 8px;
}

.actions {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-items: center;
}

.actions button {
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.chat-messages {
  padding: 12px;
}

.chat-messages p {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chat-footer {
  padding: 12px 24px;
  background-color: white;
}

.chat-footer textarea {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  font-size: 14px;
  width: 100%;
}

.chat-footer-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-footer-action > * {
  cursor: pointer;
}

.chat-footer .footer-send-btn {
  display: inline-flex;
  align-items: flex-start;
  padding: 4px 8px 4px 12px;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000);
  color: var(--grey-grey-00, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 0 auto;
}

.attachment-close {
  font-size: 12px;
  margin-bottom: 5px;
}

.attachment-button {
  display: flex;
  align-items: center;
  background-color: #0073b1;
  color: #fff;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}

.attachment-button span {
  margin-right: 10px;
}

.message-container {
  overflow-y: hidden;
  max-height: 40vh;
  min-height: 40vh;
  overflow-x: hidden;
  background-color: white;
}

/* Show the scrollbar on hover */
.message-container:hover {
  overflow-y: auto;
  scrollbar-color: transparent;
  scrollbar-width: none;
}

.message-row {
  display: flex;
  width: 75%;
  margin-bottom: 8px;
  gap: 3px;
  padding: 0 5px;
}

.message {
  display: flex;
  padding: 0.5rem 1rem;
  max-width: 250px;
}
.message p {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.message a {
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.message-content {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.sender {
  flex-direction: row-reverse;
  align-self: flex-end;
}

.img-wrapper {
  display: flex;
  align-items: flex-end;
}

.img-wrapper img {
  border-radius: 50%;
}

.sender .message {
  background-color: #e6e6e6;
  border-radius: 0.75rem 0.75rem 0 0.75rem;
}

.receiver .message {
  background-color: #f5f5f5;
  border-radius: 0.75rem 0.75rem 0.75rem 0;
}
.image-details {
  margin: 4px 0 12px;
}
.image-details p {
  font-size: 11px;
}
.file-details p {
  font-size: 12px !important;
}
.file-details {
  margin: 6px 0;
}
@media screen and (max-width: 700px) {
  .message p {
    font-size: 14px;
  }
  .message a {
    font-size: 14px;
  }
}
