.rightbar-element-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  background: #fff !important;
  border-radius: 10px;
  height: max-content;
  padding: 5px 10px;
  flex-direction: column;
}
