.wrapper {
  display: flex;
  gap: 4rem;
}

.password-input {
  position: relative;
}
.reset-password-modal :global(.modal-content) {
  margin: 70px auto 0;
}

.password-toggle {
  position: absolute;
  top: 70%;
  right: 20px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
  color: #6c757d;
}

.password-toggle-forgot {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
  color: #6c757d;
}

.red-border {
  border-color: red;
}
.otpContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.otpContainer button {
  width: 125px;
  border: 1px solid black;
  font-weight: 600;
}

.otpContainer button:hover {
  border: 1px solid black;
  font-weight: 800;
}
.loginButton {
  padding: 9px 71px;
  border: none;
  border-radius: 45px;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginButton:hover {
  background: white;
  color: black;
  border: 2px solid rgb(69, 68, 68);
  width: 222px;
}
.signinGoogle-img {
  width: 50%;
  cursor: pointer;
}
.email-pass-wrapper {
  width: 85%;
  margin: 0 auto;
}
.login-footer {
  height: 65px;
}

@media screen and (max-width: 700px) {
  .wrapper {
    display: flex;
    gap: 0.5rem;
  }
  .login-modal > div > div {
    width: 95%;
    margin: 0 auto;
  }
  .signIn-title {
    font-size: 18px;
  }
  .loginButton {
    padding: 4px 18px;
    font-size: 15px;
    width: 92px;
  }
  .loginButton:hover {
    width: 95px;
  }
  .loginButton:active {
    width: 95px;
    background: white;
    color: black;
    border: 2px solid rgb(69, 68, 68);
  }
  .signinGoogle-img {
    width: 60%;
  }
  .email-pass-wrapper {
    width: 95% !important;
  }
  .forgotpassword-btn {
    font-size: 14px !important;
  }
  .login-btn {
    font-size: 15px;
  }
  .login-footer {
    height: 60px;
  }
}

@media (min-width: 701px) and (max-width: 1120px) {
  .wrapper {
    gap: 1rem;
  }
  .btn {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 350px) {
  .btn {
    padding: 1.5px 7px;
  }
}
