.dashboard-list .dashboard-filter-pagination {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dashboard-filter-pagination .dashboard-filter,
.dashboard-filter-pagination .dashboard-pagination {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}
.dashboard-filterBy-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.dashboard-filterBy-hr {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
  margin-left: 10px;
}
.dashboard-filterBy-text {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.dashboard-filter-pagination .dashboard-filter p,
.dashboard-filter-pagination .dashboard-pagination p {
  margin: 0;
  color: #1a1a1a;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 16px;
}

.dashboard-filter-pagination .dashboard-filter .filter-btn {
  padding: 4px 12px;
  background-color: #fff;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
  margin-right: 12px;
}

.dashboard-filter-pagination .dashboard-filter .filter-btn:last-child {
  margin-right: 0px;
}

.dashboard-filter-pagination .dashboard-filter .filter-btn.selected {
  background-color: #1a1a1a;
  color: #fff;
}

.dashboard-pagination .pagination-dropdown .pagination-dropdown-btn {
  padding: 6px 16px;
  background-color: #fff;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  border-radius: 23px;
  margin-right: 12px;
}

.dashboard-list-item {
  padding: 12px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  background: #fff;
  border-radius: 10px;
}

.dashboard-list-item .item-title {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: middle;
}

.dashboard-list-item .item-title .item-type {
  display: inline-block;
  padding: 0 6px;
  font-weight: 500;
  line-height: 20px;
  font-size: 10px;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}
.dashboard-list-item .item-title .querriesTag {
  background-color: #f2c0c0 !important;
}
.dashboard-list-item .item-title .insightTag {
  background-color: #c0daf2 !important;
}
.item-title span {
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 16.41px;
  padding-left: 25px;
  position: relative;

  text-wrap: nowrap;
}

.item-title span::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #666;
}

.dashboard-list-item .item-desc {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  cursor: pointer;
  transition: max-height 0.3s ease;
}

.dashboard-list-item .expanded {
  -webkit-line-clamp: unset;
  max-height: none;
  overflow: visible;
  margin-bottom: 0 !important;
}

.dashboard-list-item .toggle-button {
  color: #4b619b;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  vertical-align: middle;
  display: flex !important;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  margin: 6px 10px 10px 0 !important;
}

.dashboard-list-item .item-desc img {
  display: none;
}

.attachment-list .attachment-item {
  width: 100px;
  height: 100px;
  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;
}

.attachment-item img {
  max-width: 80px;
  max-height: 80px;
}

.dashboard-list-item .item-interaction {
  display: flex;
  align-items: center;
  gap: 18px;
}

.item-interaction .interaction-btn {
  margin: 0;
  padding: 0;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  display: flex;
  align-items: center;
  border: none;
}

.interaction-btn img {
  margin-right: 4px;
  height: 16px;
}

.answer-btn {
  height: 30px;
  border: 1px solid #e6e6e7 !important;
  padding: 10px 8px !important;
  border-radius: 50px !important;
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  justify-content: center;
  align-items: center;
}

.fullscreenImage {
  max-width: 90%;
  max-height: 90%;
}

.wc-paginate {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.wc-paginate a,
.wc-paginate a {
  text-decoration: none;
  color: #808080;
}

.wc-paginate a li.selected {
  color: #1a1a1a;
}

.attachmentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-list-header-mobile {
  display: none;
}
.load-more {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.topScroll {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}
.loadMoreBtn {
  border: none;
  border-radius: 50%;
  padding: 4px 8px;
  background: #fff;
}
.item-windows-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.post-date {
  color: #666;
  font-size: 10px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 16.41px;
  padding-left: 25px;
  position: relative;
  text-wrap: nowrap;
}
.dashboard-post-hr {
  border-bottom: 1px solid #ccc;
  margin-bottom: 8px;
}
.post-date::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #666;
}
.add-answer-input {
  border-radius: 50px !important;
  border: 1px solid #666;
  background: #fff;
  padding: 10px 18px;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  margin: 16px auto;
}

.menuButton :global(button) {
  background: none !important;
  color: black;
  border: none;
  font-size: 14px;
}
.menuButton > :global(button) {
  background: none !important;
  color: black;
  border: none;
  font-size: 14px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.menuButton :global(button):hover {
  background: none !important;
  color: black;
  border: none;
}
@keyframes preloader-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.check-icon {
  animation: preloader-fade 1000ms linear infinite;
}

.check-icon {
  animation: preloader-fade 1000ms linear infinite;
}
.postType-date-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .dashboard-list {
    background: #e6e6e6;
  }
  .postType-date-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 2px;
  }
  .filterCategoryMobileWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 3px;
  }
  .filterCategoryMobileBtn {
    border-radius: 24px;
    background: #1a1a1a !important;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--grey-grey-00, #fff);
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: max-content;
  }
  .sort-dropdown-toggle-mobile {
    border-radius: 24px;
    background: #1a1a1a !important;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: max-content;
  }
  .filterTypeSpan {
    font-size: 9px;
    margin-left: 4px;
    padding-bottom: 2px;
  }
  .dashboard-list-item {
    background: #fff;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    margin: 0 0 6px;
    padding: 12px;
    border-top: 1px solid #ccc;
  }
  .dashboard-list-item .item-title {
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 12px;
    vertical-align: bottom;
  }

  .dashboard-list-item .item-title .item-type {
    font-size: 10px;
    line-height: 17px;
    margin-right: 5px;
  }
  .dashboard-list-item .item-desc {
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 6px 0 14px 0;
  }

  .dashboard-list-item .item-desc img {
    width: 95%;
    height: auto;
    object-fit: contain;
    margin: 5px auto;
  }
  .item-interaction .interaction-btn {
    height: 20px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--grey-grey-60, #666);
    width: max-content;
  }
  .item-interaction .interaction-btn img {
    height: 14px;
    width: 14px;
    margin-right: 2px;
  }
  .dashboard-list-item .item-interaction {
    gap: 12px;
    border-top: 1px solid #ccc;
    padding-top: 8px;
  }
  .dashboard-list .dashboard-filter-pagination {
    margin: 10px 0 27px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .item-title span {
    color: var(--grey-grey-60, #666);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .sort-dropdown-mobile div {
    padding: 0;
    width: max-content;
    font-size: 12px;
  }
  .sort-dropdown-mobile div :active {
    --bs-dropdown-link-active-bg: none;
  }
  .sort-dropdown-menu-mobile {
    min-width: 130px;
    width: 150px;
  }

  .sort-dropdown-item-mobile button {
    font-size: 14px;
  }

  .dashboard-list-header-windows {
    display: none;
  }
  .dashboard-list-header-mobile {
    display: block;
  }
  .dashboard-mobile-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 10px;
    width: 100%;
    padding: 0 12px 0;
  }

  .load-more {
    margin: 0;
    display: flex;
    justify-content: center;
    font-weight: 500;
    align-items: center;
    padding: 15px 0 100px;
  }

  .loadMoreBtnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .add-answer-input {
    border-radius: 24px;
    border: 1px solid var(--grey-grey-30, #b3b3b3);
    background: var(--grey-grey-00, #fff);
    color: var(--grey-grey-60, #666);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 10px 0 16px 0;
    height: 30px;
  }
  .menuButton div button {
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
  }
  .menuButton div {
    padding: 5px 0 0 0;
    min-width: 50px;
    width: 100px;
    max-width: 150px;
  }
  .menuButton > :global(button) {
    padding-left: 6px !important;
  }
  .post-date {
    padding-right: 4px;
  }
  .toggle-button {
    display: flex !important;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    margin: 0 10px 10px 0 !important;
  }
  .answer-btn {
    height: auto !important;
    border: none !important;
    padding: 0 !important;
    border-radius: none !important;
  }
}
