.modalHeaderQuesWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalHeaderButtonsWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.create-post-moadl :global(.modal-content)::-webkit-scrollbar {
  width: 0px;
  background: transparent !important;
}

.modalHeaderQuesbtn {
  padding: 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  background-color: #fff;
  color: #000;
  width: 100%;
  border-bottom: 1px solid #ccc !important;

  border: none;
}
.noit-professional-only {
  background-color: #fff !important;
  color: #1a1a1a !important;
  font-size: 14px;
  border-radius: 24px;
  font-weight: 500;
}
.modalHeaderQuesActive {
  border-bottom: 3px solid #1a1a1a !important;
}
.modalHeaderProfActive {
  border-bottom: 3px solid #1a1a1a !important;
}
.createSign-modal-header-img {
  border-radius: 50%;
  height: 38px;
  width: 38px;
  border: 1px solid #ccc;
}
.modal-header {
  border: none;
}

.selectedAttachment {
  display: flex;
  gap: 8px;
}

.selectedAttachment div {
  cursor: pointer;
}

.footer {
  display: flex;
}

.iconBar {
  width: 80%;
  height: 1.5rem;
  font-size: 1rem;
}

.iconWrap {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.iconWrap > i {
  margin-right: 0.75rem;
}

.postBtnWrapper {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.postBtn {
  padding: 4px 20px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}

.dashboard-search-attachment-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.attachment-btn {
  margin-right: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  text-align: left;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none !important;
}

.attachment-btn:last-child {
  margin-right: 0px;
}

.attachment-btn img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.text-editor-quill {
  margin: 1rem 0;
  margin-bottom: 40px;
  height: 150px;
  max-height: 200px;
  font-size: 14px;
}
.text-editor-quill :global(.ql-blank) {
  font-size: 14px !important;
  border-radius: 16px !important;
  min-height: 250px !important;
}
.text-editor-quill :global(.quill) {
  min-height: max-content !important;
  margin: 0 !important;
}
.text-editor-quill :first-child {
  border-radius: 16px 16px 0 0;
}
.text-editor-quill :last-child {
  border-radius: 0 0 16px 16px;
}
.tooltipOverlay div:last-child {
  width: 420px !important;
  max-width: 420px !important;
  margin-top: 10px;
}
.modal-header-mobile {
  align-items: center;
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.modal-header-mobile :global(.btn-close) {
  font-size: 14px;
  margin-right: 4px;
}
.maxMinModalBtn {
  font-size: 16px;
  cursor: pointer;
}
.error-message {
  color: #d9534f; /* A good shade of red for errors */
  font-size: 12px;
  margin-top: 6px;
}

@media screen and (max-width: 700px) {
  .create-post-moadl :global(.modal-content) {
    height: max-content;
    max-height: 100vh !important;
  }
  .modalHeaderQuesWrapper {
    font-size: 15px;
  }
  .noit-professional-only {
    background-color: #fff !important;
    color: #1a1a1a !important;
    font-size: 12px;
    border-radius: 24px;
    font-weight: 500;
  }
  .modal-header-mobile {
    padding: 10px 16px 8px 16px !important;
    align-items: center;
  }
  .modalHeaderButtonsWrapper {
    display: flex;
    gap: 4px;
    font-size: 14px;
    align-items: flex-start;
  }
  .modal-header-mobile button {
    margin: -2px 0 !important;
  }
  .maxMinModalBtn {
    font-size: 12px;
  }

  .postBtn {
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-100, #000);
    display: flex;
    padding: 4px 10px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: var(--grey-grey-00, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: max-content;
  }

  .modal-header-mobile.div {
    line-height: normal;
  }
  .attachment-btn {
    color: var(--grey-grey-90, #1a1a1a);
    /* Body/Caption B */
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 6px;
  }
  .attachment-btn img {
    margin-right: 3px;
    height: 16px;
    width: 16px;
  }
  .dashboard-search-attachment-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    flex-wrap: wrap;
    gap: 5px;
  }
  .modal-header .btn-close {
    height: 16px !important;
    width: 16px !important;
    padding: 0 !important;
  }

  .quill-validation-message {
    padding-top: 20px;
  }
  .modal-question-input {
    height: 32px;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #ccc !important;
    margin-bottom: 8px;
  }
  .quill-deitor-container {
    min-height: 100%;
    max-height: none;
    height: max-content;
  }
  .quill-deitor-wrapper {
    display: block;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
  }
  .text-editor-quill {
    height: 200px !important;
    max-height: none !important;
    min-height: 150px;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .text-editor-quill :first-child {
    border-radius: 16px 16px 0 0;
    margin-right: 2px;
  }

  .text-editor-quill :first-child::before {
    font-style: normal !important;
    font-size: 14px;
    font-weight: 400;
  }
  .text-editor-quill:first-child p {
    margin-right: 2px !important;
    border-radius: 16px 16px 0 0;
    font-size: 14px;
    font-weight: 400;
  }
  .text-editor-quill :first-child p strong {
    font-weight: 500;
  }
  .text-editor-quill :last-child {
    margin-right: 2px !important;
    border-radius: 0 0 16px 16px;
  }
  .text-editor-quill span {
    margin-right: 2px !important;
  }
  .text-editor-quill span svg {
    height: 18px;
    width: 18px;
  }
  .text-editor-quill span span {
    width: 90px !important;
    max-width: 120px !important;
    font-size: 14px;
  }
  .add-tag :global(.css-umnbt7-control) {
    height: auto !important;
  }
  .add-tag :global(.css-wsp0cs-MultiValueGeneric) {
    font-size: 13px !important;
  }
  .add-tag :global(.css-1jqq78o-placeholder) {
    font-size: 13px;
  }
  .add-tag :global(.css-pjdx1k-ValueContainer) {
    font-size: 14px !important;
    max-height: 5rem !important;
    height: auto;
    min-height: 50px;
    display: flex !important;
    flex-wrap: wrap !important;
    overflow-y: auto !important;
  }
  .add-tag :first-child input {
    font-size: 14px !important;
  }
  .add-tag div i {
    font-size: 14px !important;
  }

  .add-tag :global(.css-1n6sfyn-MenuList) {
    max-height: 250px !important;
    min-height: 50px !important;
    height: auto;
    font-size: 14px !important;
    overflow-y: auto;
  }

  .filesContainer div {
    font-size: 12px;
    padding: 0 0;
  }
  .filesContainer span img {
    width: 14px;
    height: 14px;
  }
  .filesContainer span svg {
    width: 10px;
    height: 10px;
  }
  .filesContainer {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 375px) {
  .quill-deitor-wrapper {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 282px) {
  .quill-deitor-wrapper {
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 491px) and (max-width: 792px) {
  .create-post-moadl :global(.modal-dialog) {
    min-width: 85vw !important;
    max-width: 85vw !important;
  }
}
