.filters {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  flex-wrap: wrap;
}
.jobs-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid rgb(184, 180, 180);
  padding: 12px;
  background: #fff;
  border-radius: 10px;
}
.job-details-skills-wrapper {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
.jobs-title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.job-details-skills-badge {
  padding: 4px 9px;
  font-size: 12px;
  font-weight: 500;
}
.jobs-title-wrapper img {
  height: 44px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.jobs-body-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.jobs-sub-body-wrapper {
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 0 10px;
  font-size: 14px;
}
.jobs-footer-wrapper {
  border-top: 1px solid #ccc;
  display: flex;
  gap: 30px;
  padding-top: 10px;
  margin: 10px 5px 0;
}

.jobs-footer-showMore {
  color: #4b619b;
  text-decoration: underline;
  margin-left: auto;
  cursor: pointer;
  font-size: 13px;
  margin-right: 12px;
}
.job-company-name {
  color: black;
  font-weight: 500;
  font-size: 14px;
}
.job-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.job-details img {
  max-width: 100%;
  height: auto;
}
.create-jobs-btn {
  padding: 6px 16px;
  line-height: 21px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  color: var(--grey-grey-00, #fff) !important;
  background-color: var(--grey-grey-90, #1a1a1a) !important;
  border: 1px solid #ccc !important;
  border-radius: 10px;
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-job-toggle-btn-mobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 110px;
  right: 18px;
  z-index: 99;
  color: var(--grey-grey-00, #fff);
  border-radius: 12px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000) !important;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: center;
}

.create-job-toggle-btn-mobile svg {
  height: 24px !important;
  width: 24px !important;
}

.dashboard-jobs-wrapper {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgb(204, 204, 204);
}
.dashboard-jobs-deatils-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.dashboard-jobs-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 8px;
  overflow: hidden;
}
.dashboard-jobs-loc-quali {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.job-filter-header {
  display: flex;
  justify-content: center;
  background-color: #1a1a1a;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  color: #fff;
  border: 1px solid #333;
  border-radius: 10px 10px 0 0;
  padding: 5px 0;
  margin-bottom: 5px;
}
.job-filter-body {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0 0 10px 10px;
  padding: 12px 12px 0px;
}
.dashboard-jobs-title img {
  width: 40px;
  height: auto;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.dashboard-job-value {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.dashboard-jobs-key-value-skills,
.dashboard-jobs-key-value {
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1;
  min-width: 0;
}
.job-desc-value {
  white-space: pre-wrap;
}
.dashboard-jobs-header {
  font-size: 14px !important;
  border-bottom: 1px solid rgb(204, 204, 204);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding-bottom: 10px;
}
.dashboard-jobs-parent {
  /* padding: 10px; */
  width: 100%;
  max-width: 275px;
  overflow: hidden;
}
.dashboard-job-key {
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-job-value {
  font-size: 12px;
}

.job-modal-form-group {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}
.job-modal-form-group :global(.form-label) {
  min-width: 154px;
  font-size: 14px;
  margin-bottom: 0 !important;
}
.desc-label-wrapper :global(.form-label) {
  min-width: 162px;
  font-size: 14px;
  margin-bottom: 0 !important;
}
.skills-cross-btn {
  background: transparent !important;
  border: none !important;
  margin-left: 2px !important;
  padding: 0 0 3px 0 !important;
}
.job-modal-form-group :global(.form-control) {
  font-size: 14px;
}
.jobs-footer-request {
  display: flex;
  gap: 3px;
  align-items: center;
}
.jobs-footer-request > div {
  color: #666 !important;
  font-size: 13px;
  font-weight: 500;
}
.jobs-footer-request img {
  height: 12.5px;
  width: auto;
}
.create-job-modal-header {
  padding: 14px 16px;
}
.create-job-modal-header :global(.modal-title) {
  font-size: 16px;
}
.create-job-modal-header :global(.btn-close) {
  font-size: 14px;
}
.jobs-value-radio-wrapper {
  display: flex;
  gap: 15px;
  font-size: 14px !important;
  align-items: center;
  margin-top: 6.5px;
  flex-wrap: wrap;
}
.create-job-modal-footer {
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}
.create-job-modal-footer span {
  cursor: pointer;
  font-size: 14px;
}
.create-job-modal-footer span:hover {
  text-decoration: underline;
}
.create-job-modal-footer button {
  cursor: pointer;
  padding: 4px 10px;
  font-size: 14px;
}
.create-job-modal :global(.modal-content) {
  overflow: auto;
  max-height: 85vh;
}
.create-job-modal :global(.modal-content)::-webkit-scrollbar {
  width: 0;
  background: transparent !important;
}
.create-job-modal :global(.modal-content):hover::-webkit-scrollbar {
  width: 4px;
  background: transparent !important;
  border-radius: 24px;
}
.dashboard-jobs-readMore {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0 0;
}
.dashboard-jobs-readMore span {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  width: max-content;
  color: #4b619b;
}
.job-details-modal :global(.btn-close) {
  font-size: 14px !important;
}
.job-details-modal :global(.modal-content) {
  max-height: 85vh;
  overflow: auto;
}
.job-details-modal :global(.modal-header) {
  padding: 12px 16px !important;
}

.job-details-modal :global(.modal-content)::-webkit-scrollbar {
  width: 0;
  background: transparent !important;
}
.job-details-modal :global(.modal-content):hover::-webkit-scrollbar {
  width: 4px;
  background: transparent !important;
  border-radius: 24px;
}
.job-details-modal-header-wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}
.job-details-modal-header-wrapper img {
  height: 54px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.job-details-modal-header-wrapper span {
  font-size: 16px;
  font-weight: 600;
}
.job-details-modal-key-value-wrapper {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
}
.job-details-modal-key {
  min-width: 130px;
  max-width: 130px;
  width: 130px;
  font-size: 14px;
}
.job-details-modal-value {
  font-size: 14px;
}
.jobs-value-wrapper {
  overflow: auto;
}
.jobs-value-wrapper div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.edit-image-modal :first-child {
  width: 100% !important;
  object-fit: cover;
  height: auto !important;
  border: 1px solid #666;
  aspect-ratio: 16 / 9;
}
.image-modal-header-title {
  font-size: 16px !important;
}
.image-modal-header {
  padding: 12px 16px;
}
.image-modal-header button {
  font-size: 12px;
}
.image-modal-save {
  color: #fff !important;
  background-color: #1a1a1a !important;
  border: 1px solid #1a1a1a !important;
}
.image-modal-cancel {
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;

  color: #1a1a1a !important;
}

.edit-image-footer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}
.zoom-rotate-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.zoom-labe-input-wrapper {
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
}
.zoom-rotate-label {
  width: 60px;
  font-weight: 500;
  font-size: 14px;
}
.zoom-rotate-input {
  height: 35px;
  accent-color: #454545;
}
.edit-img-savecancel-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}
.edit-img-savecancel-wrapper button {
  font-size: 14px;
}
.load-more {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.topScroll {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}

.loadMoreBtn {
  border: none;
  border-radius: 50%;
  padding: 4px 8px;
  background: #fff;
}

.job-desc-textArea {
  min-height: 100px !important;
  margin-top: 5px;
  margin-bottom: 8px;
}
.jobs-footer-postedBy {
  font-size: 14px;
}
.job-post-userName:hover {
  cursor: pointer;
  text-decoration: underline;
}

.jobs-filters-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jobs-filters-body {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  min-height: max-content;
  height: auto;
  margin-bottom: 10px;
  font-size: 14px;
}
.jobs-filters-body :global(.form-check-input):checked {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.jobs-filters-body :global(.dropdown-toggle) {
  background-color: transparent;
  border-color: #ccc;
  color: #1a1a1a;
  width: 100%;
  font-size: 14px;
}
.jobs-filters-body :global(.dropdown-menu) {
  padding-bottom: 0 !important;
}
.jobs-filters-body :global(.dropdown-menu.show) {
  color: #1a1a1a;
  width: 100%;
  font-size: 13px;
}
.jobs-filters-body :global(.dropdown-menu.show .dropdown-item) {
  border-bottom: 1px dotted #ccc;
}

.jobs-filters-experience-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-right: 8px;
  padding-left: 12px;
}
.radio-btn {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 8px;
}
.radio-btn input {
  border: 1px solid #1a1a1a;
}
.createJob-selectedImage {
  object-fit: cover;
  border: 1px solid #666;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
.createJob-changeImageWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  cursor: pointer;
  align-items: baseline;
  color: #003366;
}
.contractTypeWrapper {
  padding-left: 20px;
  font-weight: 600 !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 20px;
}
.tenureModal :global(.modal-content) {
  max-width: 350px !important;
  width: 350px !important;
}
.tenureModal :global(.modal-dialog) {
  justify-content: center !important;
}

/* filter */
.filter-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding-right: 10px;
  margin-bottom: 24px;
}

.hiring-jobs-filter-hr {
  border-bottom: 1px solid #ccc;
  width: calc(100% - 285px);
  padding: 0 5px;
}
.hiring-jobs-filter-label {
  display: flex;
  min-width: max-content;
  font-size: 12px;
}
.hiring-jobs-filter-btns {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: max-content;
}
.hiring-jobs-filter-btns button {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}

.hiring-jobs-filter-btns button.active {
  background-color: #1a1a1a !important;
  color: #fff !important;
}
.react-select-container {
  width: 100% !important;
  min-width: 100% !important;
}
.react-select-container :global(.css-13cymwt-option--is-selected:hover),
.react-select-container :global(.css-13cymwt-control):hover,
.react-select-container :global(.css-13cymwt-control):hover,
.react-select-container :global(.css-13cymwt-control:active),
.react-select-container :global(.css-13cymwt-control--is-focused) {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg) !important;
  border-color: #86b7fe !important;
  width: 100% !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}
.react-select-container :global(.css-13cymwt-control):active {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg) !important;
  border-color: #86b7fe !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}
.react-select-container :global(.css-13cymwt-control) {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg) !important;
  outline: 0 !important;
  border-radius: 4px;
  padding: 0 2px;
  height: 38px; /* Standard height */
  font-size: 14px; /* Consistent font size */
  transition: border-color 0.2s, box-shadow 0.2s;
  border-radius: 0.375rem;
}

.react-select-container :global(.css-13cymwt-control):hover,
.react-select-container :global(.css-13cymwt-control:active),
.react-select-container :global(.css-13cymwt-control--is-focused) {
  border-color: #86b7fe !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

/* Dropdown Menu */
.react-select-container :global(.css-13cymwt-menu) {
  border-radius: 4px;
  border: 1px solid #ced4da;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1050;
}

/* Option Styles */
.react-select-container :global(.css-13cymwt-option) {
  padding: 8px 12px;
  font-size: 14px;
  color: #495057;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.react-select-container :global(.css-13cymwt-option:hover) {
  background-color: #f1f3f5;
}

.react-select-container :global(.css-13cymwt-option--is-selected) {
  border-color: #86b7fe !important;

  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

@media screen and (max-width: 700px) {
  .jobs-parent {
    margin: 12px 0 95px;
  }
  .jobs-wrapper {
    border-radius: 0;
    border: none;
  }
  .filter-wrapper {
    padding: 0 10px;
  }
  .job-filter-header {
    border-radius: 0 10px 0 0;
    background: #1a1a1a;
    margin-bottom: 0px;
  }
  .job-filter-body {
    border-radius: 0 0 10px 0;
    padding: 6px 12px 0 16px;
  }
  .create-job-mobile-btn {
    position: fixed;
    right: 20px;
    bottom: 170px;
    position: fixed;
    z-index: 99;
    color: var(--grey-grey-00, #fff);
    border-radius: 12px;
    border: 3px solid var(--grey-grey-100, #000);
    background: #fff !important;
    display: flex;
    align-items: center;
    width: 42px;
    height: 42px;
    justify-content: center;
  }
  .jobs-filters-experience-wrapper {
    padding-right: 8px;
    padding-left: 0;
  }
  .radio-btn {
    padding-left: 0;
    padding-right: 6px;
  }
  .jobFilterModal :global(.modal-dialog) {
    margin: 0 !important;
  }

  .jobFilterModalOpen :global(.modal-dialog),
  .jobFilterModalClose :global(.modal-dialog) {
    transition: none !important;
  }

  .jobFilterModal :global(.modal-content) {
    position: fixed;
    left: -240px;
    top: 70px;
    width: 240px;
    height: max-content !important;
    background: transparent;
    border-radius: 0 24px 24px 0;
    transition: left 0.3s linear !important;
  }

  .jobFilterModalOpen :global(.modal-content) {
    position: fixed;
    left: -2px;
    top: 70px;
    width: 240px;
    height: max-content !important;
    background: transparent;
    border-radius: 0 24px 24px 0;
    transition: left 0.3s linear !important;
  }

  .jobFilterModalClose :global(.modal-content) {
    left: -240px;
    top: 70px;
    width: 240px;
    height: max-content !important;
    background: transparent;
    border-radius: 0 24px 24px 0;
    transition: left 0.3s linear !important;
  }

  .filters {
    justify-content: space-between;
    padding: 0 10px;
    gap: 10px !important;
  }
  .job-company-name {
    font-size: 16px;
  }
  .jobs-sub-body-wrapper {
    grid-template-columns: 20px 1fr;
    padding: 0 5px;
    font-size: 14px;
  }
  .jobs-footer-request {
    font-size: 14px;
  }
  .jobs-footer-showMore {
    margin-right: 6px;
  }
  .job-details-modal-header-wrapper {
    font-size: 14px;
  }
  .job-modal-form-group :global(.form-label) {
    min-width: 124px;
    font-size: 14px;
    margin-bottom: 0 !important;
  }
  .desc-label-wrapper :global(.form-label) {
    min-width: 128px;
    font-size: 14px;
    margin-bottom: 0 !important;
  }

  .job-details-modal-key {
    min-width: 111px;
    max-width: 110px;
    width: 110px;
    font-size: 14px;
  }
  .load-more {
    margin-top: 0;
    padding: 15px;
    display: flex;
    justify-content: center;
    font-weight: 500;
  }
  .jobs-footer-postedBy {
    font-size: 13px;
  }

  .radio-btn input {
    border: 1px solid #1a1a1a;
  }
  .react-select-container {
    width: 100%; /* Full width for small screens */
  }

  .react-select__control {
    height: 36px; /* Slightly smaller height for mobile */
    font-size: 13px; /* Adjust font size */
  }

  .react-select__option {
    font-size: 13px; /* Adjust font size */
  }
}
@media (max-width: 480px) {
  .react-select__menu {
    max-width: 95%; /* Ensure dropdown fits smaller screens */
  }
}
