.membership-list {
  list-style-type: none;
  padding: 0;
}

.membership-list label {
  display: flex;
  align-items: center;
}

.membership-list input {
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.admin-active {
  grid-template-areas:
    'nav nav nav'
    'sidebar content content' !important;
  grid-template-columns: 225px 1fr;
}

.wc-layout {
  display: grid;
  grid-template-areas:
    'nav nav nav'
    'sidebar content content' !important;
  grid-template-columns: 225px 1fr;
  grid-template-rows: 110px 1fr;
  min-height: 100vh;
  overflow: hidden;
}
.wc-content-container {
  grid-area: content;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.date-inputs {
  display: flex;
  align-items: center;
  gap: 40px;
}
.dateLabelWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}
.dateLabelWrapper label {
  font-weight: 500;
}
.date-picker {
  border-radius: 6px;
  border: 1px solid #000;
  padding: 4px 8px;
}
.userActiveDropdown {
  display: flex;
  align-items: center;
}
.userActiveDropdownToggle {
  padding: 6px 8px;
}
.userlistDownloadPrintWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0 10px;
}

/* new */
/* General table styling */
.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
  color: #333;
}

.admin-table-header,
.admin-table-cell {
  border: 1px solid #ddd;
  padding: 10px;
}

.admin-table-header {
  background-color: #f4f4f4;
  font-weight: 600;
}

.admin-table-row:nth-child(even) {
  background-color: #f9f9f9;
}

.admin-table-row:hover {
  background-color: #f1f1f1;
}

.profile-pic {
  border-radius: 8px;
  object-fit: cover;
}

/* Membership list styling */
.membership-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.membership-list-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #555;
}

.membership-list-checkbox {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

/* Membership number styling */
.membership-number-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.membership-number-item {
  background-color: #eef5ff;
  color: #0056b3;
  font-size: 14px;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: inline-block;
}

.membership-number-item:not(:last-child) {
  margin-right: 8px;
}

.date-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.time {
  font-size: 13px;
  color: #777;
}

/* Button styling */
.status-button {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.status-button-success {
  background-color: #28a745;
  color: #fff;
}

.status-button-secondary {
  background-color: #6c757d;
  color: #fff;
}

.status-button:hover {
  opacity: 0.9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .admin-table {
    font-size: 12px;
  }

  .admin-table-header,
  .admin-table-cell {
    padding: 8px;
  }

  .profile-pic {
    width: 80px;
    height: 80px;
  }

  .membership-number-item {
    font-size: 12px;
    padding: 4px 8px;
  }

  .status-button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .date {
    font-size: 12px;
  }

  .time {
    font-size: 11px;
  }
}
