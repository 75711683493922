.app-popup-parent {
  position: fixed;
  bottom: 60px;
  left: 0;
  background-color: #333;
  width: 100%;
}
.app-popup-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px 10px;
}
.app-popup-image-container {
  padding: 2px;
  background-color: #ffffff;
  width: max-content;
  padding: 0 5px;
}
.app-popup-image-container img {
  width: 25px;
  height: auto;
}
.app-popup-text-container {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  width: 100%;
}
.app-popup-text-first {
  color: white;
  font-size: 12px;
}
.app-popup-text-second {
  color: #c0f2ec;
  font-size: 16px;
  font-weight: 500;
  width: max-content;
}
.app-popup-closebtn-warpper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.downloadApp-modal {
  border: 2px solid #000;
  background-color: #e6e6e6;
}
.logo-img-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-img-wrapper img {
  width: 90%;
}

.logo-subtitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 20px auto 0 auto;
  font-family: ' Roboto';
}

.downloadNow-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: 10px auto 25px auto;
  font-family: ' Roboto';
  font-weight: 700;
}

.appstore-icon-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
}
.closeButton-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 20px auto;
}
.closeButton-modal button {
  padding: 5px 20px;
  border: 2px solid #000;
  font-size: 18px;
  font-family: 'Roboto';
  border-radius: 10px;
  margin: 0 auto;
  background-color: #ffffff !important;
  color: #000;
  font-weight: 600;
}
