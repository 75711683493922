/* Membership */

.container-heading {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 12px;
  padding-left: 10px;
}
.detail-date {
  font-size: 12px;
}
.aboutme-heading {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 12px;
  padding-left: 10px;
}
.aboutme-heading span {
  font-size: 14px;
}
.user-aboutme-input {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 16.41px;
  min-height: 200px !important;
  padding: 12px;
}

.membership-item .item-content {
  position: relative;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 16px;
}

.membership-item .item-content p {
  margin: 0;
  color: #000;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
}

.membership-item .item-content .institute-name {
  margin-bottom: 6px;
}

.membership-container .new-item-add-btn {
  min-height: 72px;
  cursor: pointer;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 16px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
}

.as-container {
  margin-bottom: 24px;
}

.container-textarea {
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
  border: 1px solid #ccc;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 16.41px;
  resize: none;
  padding: 12px;
}

.container-textarea::placeholder {
  color: #b3b3b3;
}

.jo-item-container .jo-item {
  position: relative;
  margin-bottom: 20px;
}

.item-interaction-right {
  float: right;
}

.menuButton :global(button) {
  background: none !important;
  color: black;
  border: none;
}

.menuButton :global(button):hover {
  background: none !important;
  color: black;
  border: none;
}

.jo-item .jo-item-detail {
  border-radius: 12px;
  border: 1px solid #666;
  padding: 12px;
  box-shadow: 4px 4px 0px #000;
}

.jo-item .jo-item-detail.new-add-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 80px;
  font-size: 14px;
}

.jo-item-detail .detail-text {
  margin: 0px 0px 8px 0px;
  color: #333;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 16.41px;
}

.jo-item-detail .detail-text.heading {
  color: #333;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
}

.jo-item .jo-item-header {
  padding: 2px 8px;
  color: #fff;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
  background-color: #4d4d4d;
  width: max-content;
  border-radius: 12px 12px 0px 0px;

  margin-left: 10px;
}

.expertise-container .select-container {
  margin-bottom: 24px;
  position: relative;
}

.expertise-container .select-container .help-icon {
  position: absolute;
  top: 10px;
  right: 8px;
  height: 16px;
  width: 16px;
}

/* Profile Sidebar */

.profile-sidebar {
  position: relative;
  height: 100%;
}

.profile-sidebar .profile-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar .avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}

.profile-avatar .edit-btn {
  position: absolute;
  background-color: #000;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 8px 6px 16px;
  border-radius: 23px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar .edit-btn img {
  margin-left: 4px;
}

.profile-sidebar .user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #e6e6e6;
}

.profile-sidebar .user-details h3 {
  color: #333;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 2px;
  text-align: center;
}

.profile-sidebar .user-details p {
  margin: 0;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  color: #1a1a1a;
  text-align: center;

  display: flex;
  align-items: center;
}

.profile-sidebar .user-details p .edit-location-btn {
  padding: 0;
  margin-left: 8px;
}

.user-contact {
  padding: 10px 0px;
  border-bottom: 1px dotted #e6e6e6;
}

.user-contact .contact-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
}

.profile-sidebar .profile-options {
  display: flex;
  flex-direction: column;
}

.profile-sidebar .profile-options .option-item {
  padding: 15px 0px 15px 20px;
  border-bottom: 1px solid #e6e6e6;
  text-align: left;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
}

.profile-sidebar .profile-options.account-options {
  position: absolute;
  bottom: 0;
}

.profile-sidebar .profile-options.account-options .account-item {
  border: none;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 18.75px;
  padding-left: 35px;
}

.membershipAttachment {
  position: absolute;
  top: 8px;
  right: 8px;
}

.tooltipOverlay div:last-child {
  width: 420px !important;
  max-width: 420px !important;
  margin-top: 10px;
}

.job {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: var(--grey-grey-00, #fff);
  line-height: normal;
  text-align: center;
  padding: 8px 32px;
  cursor: pointer;
}
.job-active {
  color: var(--grey-grey-80, #333);
  background: var(--grey-grey-00, #fff);
  border-radius: 8px 8px 0px 0px;
}
.jobs-mobile-content-wrapper {
  display: flex;
  justify-content: space-between;
  background: var(--grey-grey-80, #333);
  align-items: center;
  padding: 8px 4px 0 4px;
  margin: 0 -12px 12px;
}
.jo-item-detail .detail-text.heading span {
  cursor: pointer;
}
.exclamationIcon {
  height: 18px;
  margin: 0 0 4px 6px;
}

@media screen and (max-width: 700px) {
  .jobs-mobile-content-wrapper {
    margin: 0 -24px 20px -24px;
  }
}
@media screen and (max-width: 900px) {
  .container-heading {
    color: var(--grey-grey-100, #000);
    /* Body/Body Big B */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .membership-container .container-heading {
    display: none;
  }
  .expertise-container {
    margin: 24px 0;
    font-size: 14px;
  }
  .membership-container .new-item-add-btn {
    font-size: 16px;
    min-height: 70px;
    margin: 10px 0 20px 0;
  }
  .tooltipOverlay div:last-child {
    width: 250px !important;
    max-width: 300px !important;
    margin-top: 10px;
    font-size: 12px;
  }
  .container-textarea {
    margin-bottom: 20px;
  }
  .membership-item .item-content {
    margin-bottom: 10px;
  }
  .membership-item .item-content p {
    font-size: 16px;
  }
  .aboutme-heading {
    color: var(--grey-grey-100, #000);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 18px;
    margin-top: 24px;
  }
  .aboutme-heading span {
    font-size: 15px;
    margin-bottom: 12px;
    margin-top: 15px;
  }
  .user-aboutme-input {
    color: #1a1a1a !important;
    font-size: 14px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16.41px !important;
    min-height: 180px !important;
    border: 1px solid #ccc !important;
  }
  .user-aboutme-input :disabled {
    color: #1a1a1a;
    font-size: 14px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 16.41px;
  }
  .jo-item-detail .detail-text.heading {
    color: #333;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 21.09px;
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 981px) {
  .jobs-mobile-content-wrapper {
    margin: 0 -12px 20px;
  }
}
