.create-post-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 110px;
  right: 0;
  z-index: 999;
}
.dashboard-serach-wrapper {
  margin: 10px auto;
  border: 1px solid #e6e6e6;
}
.create-post-btn {
  color: var(--grey-grey-00, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 24px 0 0 24px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000) !important;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 6px;
  height: 36px;
}
.create-post-toggle-btn {
  color: var(--grey-grey-00, #fff);
  border-radius: 12px;
  border: 1px solid var(--grey-grey-100, #000);
  background: var(--grey-grey-100, #000) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 110px;
  right: 18px;
  z-index: 99;
  padding: 10px 10px;
}
.create-post-toggle-btn svg {
  height: 24px !important;
  width: 24px !important;
}
