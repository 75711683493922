.new-member-modal {
  max-width: 50vw;
}
.new-member-modal :global(.modal-content) {
  margin: 70px auto;
}

.addMebership-title {
  font-size: 16px;
  margin-left: 10px;
}

@media screen and (max-width: 700px) {
  .new-member-modal {
    max-width: 100vw;
    width: 85%;
    margin: 0 auto;
  }

  .addMebership-title {
    font-size: 18px;
  }
}
