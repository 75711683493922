.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.description {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.description a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.btn {
  padding: 9px 71px;
  border: 1.5px solid white;
  border-radius: 45px;
  background-color: transparent;
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
}

.description p {
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.code {
  font-weight: 700;
  font-family: var(--font-mono);
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  width: var(--max-width);
  max-width: 100%;
}

.card {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h2 {
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.card p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 30ch;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
}

.center::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 360px;
  margin-left: -400px;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

.logo,
.thirteen {
  position: relative;
}

.thirteen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  padding: 25px 10px;
  margin-left: 16px;
  transform: translateZ(0);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0px 2px 8px -1px #0000001a;
}

.thirteen::before,
.thirteen::after {
  content: '';
  position: absolute;
  z-index: -1;
}

/* Conic Gradient Animation */
.thirteen::before {
  animation: 6s rotate linear infinite;
  width: 200%;
  height: 200%;
  background: var(--tile-border);
}

/* Inner Square */
.thirteen::after {
  inset: 0;
  padding: 1px;
  border-radius: var(--border-radius);
  background: linear-gradient(
    to bottom right,
    rgba(var(--tile-start-rgb), 1),
    rgba(var(--tile-end-rgb), 1)
  );
  background-clip: content-box;
}
.parentContainer {
  zoom: 1;
}

.homeHeader {
  background-image: url('../../../public/images/homheader_logo.svg');
  background-size: cover;
  object-fit: cover;
  max-height: 61px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
}
.navbar-wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 300ms ease-in-out;
  z-index: 100;
  margin: 0 auto;
}
.home-nav-btn-wrapper {
  display: flex;
  align-items: center;
  gap: 18px;
}
.home-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-nav-btn button a svg {
  height: 24px;
  width: auto;
}
.home-nav-btn button svg {
  height: 24px;
  width: auto;
}
.home-nav-btn button img {
  height: 24px;
  width: auto;
  filter: invert(1);
}
.home-nav-btn button {
  padding: 0 !important;
}

.loginbtn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}

.password-input {
  display: flex;
  align-items: center;
  position: relative;
}

.password-input input[type='password'] {
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Style the eye icon button */
.password-input .toggle-password-button {
  border: none;
  background: transparent;
  padding: 5px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.bodyontainer {
  position: sticky;
  margin-top: 80px;
}

.container {
  /* width: 90%; */
  height: 100%;
  margin: auto;
}

.titleText {
  justify-content: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 54.688% */
}

.knowMoreBtn {
  display: flex;
  margin-top: 3rem;
  padding: 1rem 2rem;
  align-items: center;
  border-radius: 2.25rem;
  background: var(--grey-grey-00, #fff);

  justify-content: center;
}

.whatweDo {
  margin-top: 72px;
  margin-bottom: 8px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  height: 36px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-weight: 700;
  font-family: Roboto;
  font-style: normal;
  line-height: 35px; /* 109.375% */
  font-variant: all-small-caps;
}

.connectProfessionals {
  display: flex;
  height: 61px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
}
.home-login-img-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 70px 20px 80px;
}
.login-wrapper {
  width: 80%;
}
.home-login-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 20px;
}
.home-text-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}
.home-text-first {
  font-size: 42px;
  font-weight: 500;
  line-height: 25px;
}
.home-text-second {
  font-size: 42px;
  font-weight: 500;
  margin-left: 244px;
}
.home-text-third {
  font-size: 18px;
  display: flex;
  margin: 25px 0 0;
}
.connectProfImgConatiner {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectProfImg {
  width: 100%;
  height: inherit;
}
.carouselDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);
  color: white;
  height: 100%;
  margin-bottom: 2rem;
  gap: 64px;
}

.footer {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  color: black;
  background-color: white;
  padding: 2rem;
  margin: 0 auto 0 30px;
}
.whiteCollarLogoDiv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  color: white;
  padding-top: 50px;
  flex: 1;
}

.whiteCollarLogoDiv img {
  width: 166px;
  height: 245px;
}

.aboutUsWrapper {
  flex: 3;
}

.aboutUsTitle {
  font-weight: 700;
  font-family: Roboto;
  font-size: 24px;
  white-space: nowrap;
}

.aboutUsDesc {
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto;
  margin-top: 16px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-wrap: wrap;
}
.help-follow-footer {
  flex: 2;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.helpLegal {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}
.helpLegal a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
.helpGuidelines {
  display: flex;
  flex-direction: column;
}
.helpGuidelinesTitle {
  font-weight: 700;
  font-family: Roboto;
  font-size: 20px;
  display: flex;
  justify-content: center;
  width: max-content;
}
.follow-title {
  font-size: 20px;
}
.helpGuidlinesWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 31px;
  gap: 1rem;
  font-size: 16px;
  text-decoration: underline;
}
.policy {
  display: flex;
  flex-direction: column;
}
.legalPolicyTittle {
  font-weight: 700;
  font-family: Roboto;
  font-size: 20px;
  display: flex;
  justify-content: center;
  width: max-content;
}
.legalPolicyWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: max-content;
  margin-top: 31px;
  gap: 1rem;
  font-size: 16px;
  text-decoration: underline;
}
.legal-policy-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}
.followContainer {
  display: flex;
  font-size: medium;
  justify-content: space-evenly;
  align-items: flex-end;
  font-weight: 700;
  margin: 1rem 0 0 0;
  background-color: var(--grey-grey-00);
  color: black;
}

.followContainer a {
  color: black;
  text-decoration: none;
}
.footerCopyright {
  height: 63px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);
}
.aboutUsSocial {
  font-weight: 700;
  font-size: 20px;
  font-family: Roboto;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);
  color: var(--grey-grey-00);
}

.hiddenHeader {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.modal-content {
  width: 80%;
}
.iframe-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 70px auto;
}
.youtube-Iframe {
  /* width: 700px;
  height: 395px; */
  width: 800px;
  height: 450px;
  margin: auto;
  border: 2px solid #000;
  box-shadow: -5px 5px 4px #00000036;
}
.aboutUsDesc hr {
  margin: 10px 0;
}
.images-logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding-left: 25px;
  position: absolute;
  bottom: 0;
  left: 0%;
}
.images-logo-wrapper img {
  height: 45px;
  /* width: 100px; */
}
.textimage-desc-ca {
  color: black;
  font-weight: 700;
  font-size: 20px;
}
.textimage-desc-cs {
  color: black;
  font-weight: 700;
  font-size: 20px;
}
.textimage-desc-cwa {
  color: black;
  font-weight: 700;
  font-size: 20px;
}
.textimage-desc-adv {
  color: black;
  font-weight: 700;
  font-size: 20px;
}
.textimage-desc-bold {
  font-weight: 600;
  font-size: 20px;
}

.profAuthWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px 0 80px 50px;
  gap: 180px;
}
.profAuthText {
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Roboto';
}
.profAuthWrapper img {
  height: 130px;
  width: auto;
}
.loginSignupWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 60px 0 0 0;
}

.loginSignupButton {
  padding: 9px 71px;
  border: none;
  border-radius: 45px;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginSignupButton:hover {
  background: white;
  color: black;
  border: 2px solid rgb(69, 68, 68);
  width: 222px;
  /* border-image: linear-gradient(180deg, #393d48 0%, #282b32 100%); */
}
.getStartedBtn {
  border: none;
  border-radius: 45px;
  padding: 4px 24px;
  background: white;
  color: black;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.getStartedBtn:hover {
  border: 4px solid #000;
}

/* appdownload */
.appDownloadcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.appDownloadTitle {
  font-style: italic;
  font-size: 28px;
}

.appDownloadIconContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.playStoreIcon {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 200px;
  cursor: pointer;
}
.appStoreIcon {
  width: 203px;
  height: 63px;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .thirteen::before {
    animation: none;
  }

  .card:hover span {
    transform: none;
  }
}

/* Mobile */
@media (max-width: 1023px) {
  .iframe-wrapper {
    margin: 30px auto;
    padding: 0 20px;
  }
  .navbar-wrapper {
    padding: 0 10px;
    height: 44px;
  }
  .home-nav-btn-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .home-nav-btn button a svg {
    height: 18px;
    width: auto;
  }
  .youtube-Iframe {
    /* width: 500px;
    height: 281px; */
    width: auto;
    height: auto;
    margin: none;
  }
  .login-wrapper {
    width: 100%;
  }
  .home-login-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0;
    margin: 44px 0 15px;
  }
  .home-text-wrapper {
    padding: 10px 0 0;
  }
  .home-text-first {
    font-size: 25px;
    font-weight: 500;
    line-height: 25px;
    padding-left: 5%;
    margin-left: 0;
  }
  .home-text-second {
    font-size: 25px;
    font-weight: 500;
    padding-left: 46%;
    margin-left: 0;
  }
  .home-text-third {
    font-size: 12px;
    display: flex;
    margin: 10px auto 0;
  }
  .connectProfImg {
    width: 100%;
    height: auto;
  }
  .images-logo-wrapper {
    justify-content: center;
    gap: 5px;
    bottom: 28px;
    padding-left: 24px;
  }
  .home-login-img-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 0;
    flex-direction: column;
  }
  .images-logo-wrapper img {
    height: 24px;
  }
  .textimage-desc-ca {
    color: black;
    font-weight: 600;
    font-size: 14px;
  }
  .textimage-desc-cs {
    color: black;
    font-weight: 600;
    font-size: 14px;
  }
  .textimage-desc-cwa {
    color: black;
    font-weight: 600;
    font-size: 14px;
  }
  .textimage-desc-adv {
    color: black;
    font-weight: 600;
    font-size: 14px;
  }
  .textimage-desc-bold {
    font-weight: 550;
    font-size: 14px;
  }

  .content {
    padding: 4rem;
  }
  .btn {
    width: max-content;
    font-size: 14px;
    padding: 1.5px 9px;
  }
  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 320px;
    text-align: center;
  }

  .card {
    padding: 1rem 2.5rem;
  }

  .card h2 {
    margin-bottom: 0.5rem;
  }

  .center {
    padding: 8rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 0.8rem;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(
      to bottom,
      rgba(var(--background-start-rgb), 1),
      rgba(var(--callout-rgb), 0.5)
    );
    background-clip: padding-box;
    backdrop-filter: blur(24px);
  }

  .description div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(var(--background-end-rgb)) 40%
    );
    z-index: 1;
  }
  .homeHeader {
    height: 44px;
    padding: 0;
    position: fixed;
    width: 100%;
    top: 0;
    justify-content: space-around;
  }

  .loginbtn_wrapper {
    gap: 6px;
    margin: 0 0 0 2px;
  }
  .bodyContainer {
    position: sticky;
    margin-top: 0;
  }
  .container {
    width: 100%;
    margin: 0;
  }
  .titleText {
    font-size: 20px;
  }
  .knowMoreBtn {
    padding: 4px 8px;
    border-radius: 24px;
    border: 1px;
    margin: 12px 0 24px 0;
  }
  .whatweDo {
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 18.41px;
  }
  .connectProfessionals {
    font-size: 18px;
    height: 22px;
  }
  .connectProfImgConatiner {
    height: max-content;
    margin: 30px 10px;
  }
  .carouselDiv {
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 32px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 1rem 16.5px;
    margin-left: 0;
  }
  .whiteCollarLogoDiv {
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: flex-start;
    color: white;
    padding-top: 5px;
    /* margin-bottom: 10px; */
  }

  .whiteCollarLogoDiv img {
    width: 150px;
    height: auto;
  }
  .aboutUsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: justify;
  }

  .aboutUsTitle {
    font-size: 18px;
  }

  .aboutUsDesc {
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto;
    margin-top: 1rem;
  }
  .help-follow-footer {
    flex: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.5rem;
  }
  .helpLegal {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 0 0;
    width: 100%;
  }
  .helpGuidelinesTitle {
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
  }
  .helpGuidlinesWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
    font-size: 12px;
    text-decoration: underline;
    width: max-content;
  }
  .policy {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
  }
  .legalPolicyTittle {
    font-size: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .legalPolicyWrapper {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-start;
    text-align: left;
    gap: 1rem;
    font-size: 12px;
    text-decoration: underline;
    height: 95px;
    flex-wrap: wrap;
    width: 100%;
  }
  .legal-policy-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .followContainer {
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    width: 100%;
    font-weight: 700;
    margin: 16px 0 0 0;
    background-color: var(--grey-grey-00);
    color: black;
  }
  .footerCopyright {
    height: 30px;
  }
  .aboutUsSocial {
    font-size: 14px;
  }
  .profAuthWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 30px 0 40px 0;
    gap: 0;
  }
  .profAuthText {
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    font-family: Roboto;
  }
  .profAuthWrapper img {
    height: 50px;
    width: auto;
  }
  .getStartedBtn {
    padding: 2px 6px;
    font-size: 12px;
  }
  .loginSignupWrapper {
    margin: 30px 0 0 -15px;
    gap: 20px;
  }
  .loginSignupButton {
    padding: 4px 18px;
    font-size: 15px;
    width: 92px;
    min-width: 92px;
  }
  .loginSignupButton:hover {
    width: 95px;
  }
  .loginSignupButton:active {
    width: 95px;
    background: white;
    color: black;
    border: 2px solid rgb(69, 68, 68);
  }

  /* app Downlaod */
  .appDownloadcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }

  .appDownloadTitle {
    font-style: italic;
    font-size: 18px;
  }

  .appDownloadIconContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .playStoreIcon {
    border: 1px solid #fff;
    border-radius: 8px;
    width: 132px;
    cursor: pointer;
    /* height: 46px; */
  }
  .appStoreIcon {
    border: 1px solid #fff;
    width: 133px;
    height: 42.78px;
    border-radius: 8px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 999px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
  .titleText {
    font-size: 44px;
  }
  .footer {
    flex-direction: column;
    padding-right: 3rem;
    gap: 1rem;
  }
  .helpLegal {
    margin-bottom: 15px;
  }
  .helpGuidlinesWrapper {
    flex-direction: row;
    text-decoration: underline;
  }
  .legalPolicyWrapper {
    flex-direction: row;
    width: auto;
  }
  .btn {
    padding: 10px 30px;
  }
  .loginbtn_wrapper {
    gap: 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .vercelLogo {
    filter: invert(1);
  }

  .logo,
  .thirteen img {
    filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 350px) {
  .btn {
    padding: 1.5px 7px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .bodyContainer {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .home-login-img-wrapper {
    max-width: 1000px;
    margin: 0 auto;
  }
  .navbar-wrapper {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .bodyContainer {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .home-login-img-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  .navbar-wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .bodyContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .home-login-img-wrapper {
    max-width: 1400px;
    margin: 0 auto;
  }
  .navbar-wrapper {
    max-width: 1400px;
  }
}
