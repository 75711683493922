.user-edit-image-wrapper {
  width: 100%;
  height: 100%;
}
.user-edit-image-wrapper :first-child {
  width: 100% !important;
  height: 100% !important;
}
.crop-image-modal :global(.modal-header) button {
  font-size: 12px;
}
.crop-image-modal :global(.modal-header) {
  padding: 8px 12px;
}
.crop-image-modal :global(.modal-header .modal-title) {
  font-size: 16px;
}
.crop-img-scroll {
  accent-color: #1a1a1a !important;
  border: none;
}
.crop-img-scroll::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  background: #1a1a1a;
  cursor: pointer;
}
.crop-img-scroll::-moz-range-thumb {
  background: #1a1a1a;
  cursor: pointer;
}
.crop-img-submit-btn {
  font-size: 14px;
}
