.header-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding-right: 10px;
  margin-bottom: 15px;
}
.recent-event-carousel-img {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border: 1px solid #e6e6e6 !important;
  border-radius: 4px;
}
.event-date-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-date-item span {
  width: max-content;
}

.event-date-item input {
  width: 135px;
  height: 30px;
  border: 1px solid var(--grey-grey-80, #333);
  padding: 8px;
  border-radius: 6px;
}
.events-filter {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  width: max-content;
}
.event-header-label {
  display: flex;
  min-width: max-content;
  font-size: 12px;
}
.event-filter-btn {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
  margin-right: 12px;
}
.create-event-modal > div > div {
  max-height: 85vh !important;
  overflow: auto !important;
  /* margin: auto 0 !important; */
}
.create-event-modal-header button {
  font-size: 12px;
}
.create-event-modal-header {
  padding: 12px 16px;
}
/* Styling the scrollbar itself (width, height) */
.create-event-modal > div > div::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  display: none;
}
.create-event-modal-label {
  font-size: 14px;
}
.create-event-modal-submit {
  font-size: 16px;
}
.event-header-hr {
  border-bottom: 1px solid #ccc;
  width: calc(100% - 285px);
  padding: 0 5px;
}
.event-filter-wrapper {
  display: flex;
  align-items: center;
}
.event-filter-active {
  background-color: #1a1a1a !important;
  color: #fff !important;
}

.event-filter-label {
  font-size: 14px;
}
.create-event-btn button {
  padding: 6px 16px;
  line-height: 21px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  color: var(--grey-grey-00, #fff);
  background-color: var(--grey-grey-90, #1a1a1a) !important;
  border: 1px solid #ccc !important;
  border-radius: 10px;
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-event-carousel:global(.carousel-root) {
  max-width: 245px !important;
  margin: 0 auto !important;
  cursor: pointer;
}
.event-container {
  border: 1px solid rgb(184, 180, 180);
  padding: 12px;
  background: #fff;
  border-radius: 10px;
}
.event-details-container {
  display: flex;
  margin-bottom: 20px;
}

.event-image {
  margin-right: 20px;
  width: 40%;
  max-height: 100%;
  border-radius: 5px;
}
.event-image img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #666;
  aspect-ratio: 16/9;
}

.event-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 20px 0 0;
  color: dimgrey;
  width: 60%;
  gap: 5px;
}
.event-details-name {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}

.event-details-dialog {
  display: flex;
  align-items: center;
  max-height: 100px;
  line-height: normal;
  max-width: 400px;
}

.event-details-key {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 75px;
  min-width: 75px;
}

.event-details-value {
  display: flex;
  justify-content: left;
  padding-left: 5px;
  color: var(--grey-grey-90, #1a1a1a);
  overflow-y: auto;
  max-height: 100px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.event-details-value::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  display: none;
}
.event-details-value:hover::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  display: flex;
}
.events-details-value-url {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.event-details-value-url {
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.event-details-value-desc {
  overflow: auto;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  margin-bottom: 12px;

  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 0 5px;
}

.event-details-value-desc::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  display: none;
}
.event-details-value-desc:hover::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  display: flex;
}

/* Style the scrollbar thumb */
.event-details-value-desc::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the thumb */
  border-radius: 6px; /* Round the corners of the thumb */
}
.recent-event-readMore {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0 0;
}
.recent-event-readMore span {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  width: max-content;
  color: #4b619b;
}
.recent-event-readMore span:hover {
  text-decoration: underline;
}

/* Style the scrollbar track when the user hovers over it */
.event-details-value-desc::-webkit-scrollbar-track:hover {
  background-color: #ddd;
}
.item-interaction {
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
  margin-right: 5px;
  gap: 25px;
}
.interaction-btn {
  margin: 0;
  padding: 0;
  color: #666 !important;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  display: flex;
  align-items: center;
  background-color: transparent !important;
  border: none;
}

.interaction-btn img {
  margin-right: 4px;
}
.event-bottombar {
  display: none;
}
.events-hr {
  width: 100%;
  margin: 20px 0;
}

.event-image-display {
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
}
.create-event-image {
  object-fit: cover;
  border: 1px solid #666;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
.all-events-container {
  display: flex;
  flex-direction: column;
}
.createEvent-changeImageWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  cursor: pointer;
  align-items: baseline;
  color: #003366;
  margin: 0 0 16px 0;
}
.edit-image-modal :first-child {
  width: 100% !important;
  object-fit: cover;
  height: auto !important;
  border: 1px solid #666;
  aspect-ratio: 16 / 9;
}
.image-modal-header-title {
  font-size: 16px !important;
}
.image-modal-header {
  padding: 12px 16px;
}
.image-modal-header button {
  font-size: 12px;
}
.image-modal-save {
  color: #fff !important;
  background-color: #1a1a1a !important;
  border: 1px solid #1a1a1a !important;
}
.image-modal-cancel {
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;

  color: #1a1a1a !important;
}

.edit-image-footer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}
.zoom-rotate-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.zoom-labe-input-wrapper {
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
}
.zoom-rotate-label {
  width: 60px;
  font-weight: 500;
  font-size: 14px;
}
.zoom-rotate-input {
  height: 35px;
  accent-color: #454545;
}
.edit-img-savecancel-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}
.edit-img-savecancel-wrapper button {
  font-size: 14px;
}
.event-details-interaction-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.load-more {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.loadMoreBtn {
  border: none;
  border-radius: 50%;
  padding: 4px 8px;
  background: #fff;
}
.topScroll {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}
.create-event-toggle-btn-mobile {
  display: none;
}

.job-name-input {
  font-size: 14px;
  border: none;
  outline: none;
  background: transparent;
  padding-left: 10px;
  width: 100%;
}
.name-search-wrapper {
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}
.job-name-search-icon {
  color: #1a1a1a;
  cursor: pointer;
  margin-left: 5px;
}
.job-name-search-glass {
  height: 19px;
}
.inviteMailError {
  border: 1px solid #e57373 !important;
}
@media screen and (max-width: 700px) {
  .header-wrapper {
    margin: 12px 0;
    padding: 0 10px;
  }
  .event-container {
    border-radius: 0;
    border: none;
  }
  .name-search-parent {
    position: relative;
    margin: 4px 0 0 0;
    padding: 0 0;
    height: 34px;
  }
  .name-search-wrapper {
    border-radius: 1.625rem;
    border: 1px solid #333;
  }
  .job-name-search-glass {
    height: 19px;
    width: 16px !important;
  }
  .job-name-input {
    padding: 0px 5px;
    height: auto;
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    border: none;
  }
  .job-name-search-icon {
    right: 10px;
  }

  .event-bottombar {
    display: flex;
  }
  .edit-image-modal {
    height: auto;
  }
  .edit-image-modal canvas {
    width: 100% !important;
    height: auto !important;
  }
  .date-wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-bottom: 0;
    height: 30px;
    margin: 15px 0;
    width: 100%;
    gap: 0;
  }
  .event-date-filters {
    display: flex;
    gap: 0;
    width: 100%;
    justify-content: space-between;
  }
  .events-filter {
    flex-wrap: wrap;
    width: auto;
    padding: 0;
  }
  .events-filter div {
    font-size: 12px;
  }
  .events-filter-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 5px 10px 0 10px;
  }
  .event-date-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
  .event-date-item span {
    color: var(--grey-grey-60, #666);
    text-align: right;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .event-date-item input {
    padding: 2px 4px;
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-00, #fff);
    color: var(--grey-grey-100, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100px;
    height: auto;
  }
  .events-hr {
    display: none;
  }
  .event-details-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 5px;
  }

  .event-image {
    margin-right: 0;
    width: 100%;
    max-height: 100%;
    border-radius: 0;

    /* max-width: 90%; */
  }
  .event-image img {
    object-fit: cover;
    border-radius: 10px;
    height: auto;
    width: 100%;
  }
  .event-details {
    padding: 0 0;
    width: 100%;
    gap: 5px;
  }
  .event-details-dialog {
    align-items: center;
    justify-content: left;
  }
  .event-details-name {
    font-size: 16px;
    line-height: normal;
    text-align: center;
    margin: 5px 0;
  }
  .event-details-key {
    font-size: 14px;
    line-height: normal;
    width: 70px;
  }
  .event-details-value {
    font-size: 14px;
    line-height: normal;
    line-height: 25px;
    margin-left: 10px;
  }
  .event-details-value-url {
    padding-right: 0;
    font-size: 14px;
  }
  .event-details-value-desc {
    max-height: 85px;
    overflow-x: auto;
    width: 100%;
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 0 5px 0 0;
    margin: 6px 0;
  }
  .create-event-btn-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 110px;
    right: 0;
    z-index: 999;
  }
  .create-event-btn-mobile button {
    color: var(--grey-grey-00, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 24px 0 0 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-100, #000) !important;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 6px;
    height: 36px;
  }
  .create-event-toggle-btn-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 110px;
    right: 18px;
    z-index: 99;
  }
  .zoom-rotate-label {
    font-size: 16px;
  }
  .zoom-rotate-input {
    height: 30px;
  }
  .edit-image-footer {
    gap: 15px;
  }

  .create-event-toggle-btn-mobile button {
    color: var(--grey-grey-00, #fff);
    border-radius: 12px;
    border: 1px solid var(--grey-grey-100, #000) !important;
    background: var(--grey-grey-100, #000) !important;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    justify-content: center;
  }
  .create-event-toggle-btn-mobile button svg {
    height: 24px !important;
    width: 24px !important;
  }
  .interaction-btn {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: auto;
  }
  .interaction-btn img {
    height: 12px;
    width: 12px;
    margin-right: 2px;
  }
  .item-interaction {
    gap: 12px;
  }
  .event-image-display {
    width: 100%;
    font-weight: 600;
  }

  .event-image-display img {
    width: 100%;
  }
  .load-more {
    margin: 16px 0 40px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    padding: 0;
    align-items: center;
  }
}
