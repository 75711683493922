.wc-stepper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wc-stepper .stepper-progress {
  min-width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1rem;
}

.stepper-progress .stepper-indicator {
  height: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15) 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 20px 1px;
  border: none;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.stepper-progress .stepper-indicator-completed {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-0%, -50%);
  z-index: 2;
  height: 2px;
  background-color: #4b9b5c;
}

.stepper-progress .step-btn {
  z-index: 3;
  background-color: #fff;
  box-shadow: none;
  padding: 0;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.stepper-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 24px; */
}

@media screen and (max-width: 700px) {
  .wc-stepper .stepper-progress {
    min-width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0;
  }
  .stepper-progress .step-btn img {
    height: 25px !important;
    width: 25px !important;
  }
  .stepper-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
}
