.dashboard-pagination .pagination-dropdown .pagination-dropdown-btn {
  padding: 6px 16px;
  background-color: #fff;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  border-radius: 23px;
  margin-right: 12px;
}
.searchCompWrap {
  padding-left: 2.25rem;
}

.wc-paginate {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.wc-paginate .previous a,
.wc-paginate .next a {
  text-decoration: none;
  color: #1a1a1a;
}
.workforceWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dashboard-list .dashboard-filter-pagination {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.dashboard-filter-pagination .dashboard-filter,
.dashboard-filter-pagination .dashboard-pagination {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.dashboard-filter-pagination .dashboard-filter p,
.dashboard-filter-pagination .dashboard-pagination p {
  margin: 0;
  color: #1a1a1a;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 16px;
}

.dashboard-filter-pagination .dashboard-filter .filter-btn {
  padding: 6px 16px;
  background-color: #fff;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  border-radius: 23px;
  margin-right: 12px;
}

.dashboard-filter-pagination .dashboard-filter .filter-btn:last-child {
  margin-right: 0px;
}

.dashboard-filter-pagination .dashboard-filter .filter-btn.selected {
  background-color: #1a1a1a;
  color: #fff;
}

.dashboard-pagination .pagination-dropdown .pagination-dropdown-btn {
  padding: 6px 16px;
  background-color: #fff;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  border-radius: 23px;
  margin-right: 12px;
}

.profile-pic {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}

.dashboard-pagination input {
  height: 1rem;
}

.prof-bottombar {
  display: none;
}

.load-more {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.topScroll {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}

.loadMoreBtn {
  border: none;
  border-radius: 50%;
  padding: 4px 8px;
  background: #fff;
}
.list-item-parent {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 8px 12px;
}
.list-item {
  display: grid;
  grid-template-columns: 50px 1fr 140px;
  grid-column-gap: 10px;
}
.user-dp {
  display: flex;
  justify-content: center;
}
.user-dp img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e6e6e6;
}
.user-details-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
}
.user-name-location {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-name-location-nonProf {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  height: 100%;
}
.user-name {
  font-size: 14px;
  font-weight: 700;
  color: #1a1a1a;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  line-height: 21.09px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-name img {
  height: 16px;
  width: 16px;
}
.user-location {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-location span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-location p {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.user-location p span {
  background-color: #1a1a1a;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin: 0 6px;
  width: 4px;
  min-width: 4px;
}

.membership-expertise {
  margin-left: 60px;
}

.membership-wrapper {
  margin: 6px 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.membership-badge {
  margin: 0 4px 0 0px;
  color: black;
  font-size: 12px;
}
.color-ICAI {
  background-color: #f2e1c0 !important;
}

.color-ICSI {
  background-color: #cdc0f2 !important;
}

.color-ICMAI {
  background-color: #c0f2cb !important;
}

.color-BCI {
  background-color: #c0f2ec !important;
}
.user-expertise {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 5px;
  max-width: 328px;
  line-height: normal;
  margin: 2px 0 0 1px;
}
.user-expertise div {
  align-items: center;
  display: flex;
  gap: 4px;
  overflow: hidden;
  white-space: nowrap;
}
.item-action {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.item-action .item-action-btn {
  padding: 4px 8px;
  color: #1a1a1a;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  border: 1.5px solid #1a1a1a;
  border-radius: 23px;
  width: 4.3rem;
}

.item-action .item-action-btn.selected {
  background-color: #1a1a1a;
  color: #fff;
}

.filter-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding-right: 10px;
  margin-bottom: 15px;
}

.trends-filter-hr {
  border-bottom: 1px solid #ccc;
  width: calc(100% - 285px);
  padding: 0 5px;
}
.trends-filter-label {
  display: flex;
  min-width: max-content;
  font-size: 12px;
}
.trends-filter-btns {
  display: flex;
  gap: 12px;
  min-width: max-content;
}
.trends-filter-btns button {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}
.workforceHeading {
  font-size: 14px;
}
.workforceTitle {
  margin: 6px 0 8px;
}
.workforceTitle span {
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  font-style: italic;
}
.trends-filter-btns button.active {
  background-color: #1a1a1a !important;
  color: #fff !important;
}

.userFilterModal :global(.modal-dialog) {
  margin: 0 !important;
}

.userFilterModalOpen :global(.modal-dialog),
.userFilterModalClose :global(.modal-dialog) {
  transition: none !important;
}

.userFilterModal :global(.modal-content) {
  position: fixed;
  left: -300px;
  top: 70px;
  width: 85vw;
  height: auto !important;
  max-height: calc(90vh - 70px) !important;
  overflow-y: auto;
  background: transparent;
  border-radius: 0 24px 24px 0;
  transition: left 0.3s linear !important;
}

.userFilterModalOpen :global(.modal-content) {
  position: fixed;
  left: -2px;
  top: 70px;
  width: 85vw;
  height: auto !important;
  max-height: calc(90vh - 70px) !important;
  overflow-y: auto;
  background: transparent;
  border-radius: 0px 10px 10px 0;
  transition: left 0.3s linear !important;
}

.userFilterModalClose :global(.modal-content) {
  left: -85vw;
  top: 70px;
  width: 85vw;
  height: auto !important;
  max-height: calc(90vh - 70px) !important;
  overflow-y: auto;
  background: transparent;
  border-radius: 0 24px 24px 0;
  transition: left 0.3s linear !important;
}
@media screen and (max-width: 700px) {
  .prof-bottombar {
    display: flex;
  }

  .dashboard-list .dashboard-filter-pagination {
    margin: 12px 0 16px 0;
    display: block;
  }

  .dashboard-filter-pagination .dashboard-filter,
  .dashboard-filter-pagination .dashboard-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
  .list-item-parent {
    background: #fff;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    margin-bottom: 0;
    margin-bottom: 8px;
    padding-bottom: 12px;
    padding: 6px;
  }

  .list-item {
    grid-template-columns: 36px 1fr 128px;
    grid-column-gap: 4px;
    margin-bottom: 4px;
  }
  .user-dp {
    margin-top: 4px;
  }
  .user-dp img {
    width: 36px;
    height: 36px;
  }
  .user-name-location {
    flex-direction: column;
    gap: 1px;
  }
  .user-name-location-nonProf {
    align-items: flex-start;
    flex-direction: column;
    gap: 1px;
  }
  .user-name {
    margin: 0 14px 0 4px;
  }
  .user-name img {
    height: 12px;
    width: 12px;
  }
  .user-location {
    margin-left: -2px;
  }
  .membership-expertise {
    margin-left: 40px;
    margin-top: -6px;
  }
  .user-expertise {
    margin: 4px 0 0;
  }
  .item-action {
    gap: 6px;
  }
  .item-action .item-action-btn {
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-00, #fff);
    color: var(--grey-grey-100, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 66px;
    min-width: 66px;
  }
  .load-more {
    margin-top: 26px;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
    font-weight: 500;
  }

  .dashboard-filter-pagination .dashboard-filter,
  .dashboard-filter-pagination .dashboard-pagination {
    justify-content: space-between;
    padding: 0 12px;
  }
  .user-location p {
    font-size: 10px;
  }
  .trends-filter-btns {
    display: flex;
    gap: 10px;
    min-width: max-content;
  }
  .filter-wrapper {
    gap: 8px;
    padding: 0 12px;
    margin-bottom: 12px;
  }
  .workforceWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .userFilterButton {
    right: 18px;
    bottom: 110px;
    position: fixed;
    z-index: 99;
    color: var(--grey-grey-00, #fff);
    border-radius: 12px;
    border: 3px solid var(--grey-grey-100, #000);
    background: rgb(255, 255, 255) !important;
    display: flex;
    align-items: center;
    padding: 4px;
    height: 45.33px;
    width: 45.33px;
    justify-content: center;
  }
  .membership-badge {
    font-size: 10px;
  }
}
@media screen and (max-width: 380px) {
  .dashboard-filter-pagination .dashboard-filter,
  .dashboard-filter-pagination .dashboard-pagination {
    padding: 0 6px !important;
    gap: 6px;
  }
}
@media screen and (max-width: 340px) {
  .dashboard-filter-pagination .dashboard-filter,
  .dashboard-filter-pagination .dashboard-pagination {
    padding: 0 0px !important;
    gap: 2px;
  }
}
