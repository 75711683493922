@keyframes preloader-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.preloader-plus .preloader-custom-img {
  animation: preloader-fade 3000ms linear infinite;
  width: 300px;
}

.preloader-plus .preloader-custom-img {
  animation: preloader-fade 3000ms linear infinite;
  width: 300px;
}
.preloader-plus img {
  display: block;
  max-width: 100%;
}

.preloader-plus * {
  color: #000;
  display: flex;
  flex: 1;
  margin: auto auto;
  height: 100vh;
}

@media screen and (max-width: 700px) {
  .preloader-plus .preloader-custom-img {
    width: 150px;
  }
}
