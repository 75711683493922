.interaction-btn {
  padding: 0px !important;
  height: 30px;
  border-radius: 50px !important;
  size: 16px;
  margin: 0;
  color: #666 !important;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  display: flex;
  align-items: center;
  border: none !important;
}
.interaction-btn img {
  height: 14px;
  width: 14px;
  margin-right: 4px;
}
@media screen and (max-width: 700px) {
  .interaction-btn {
    /* padding: 0px !important; */
    height: 20px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: max-content;
  }
  .interaction-btn img {
    height: 14px;
    width: 14px;
    margin-right: 2px;
  }
}
