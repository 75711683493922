.bottombar-wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  padding: 0;
  left: 0;
  filter: drop-shadow(-2px -2px 8px rgba(153, 153, 153, 0.25));
  z-index: 101;
}

.bottombar-wrapper p {
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: black;
}
.bottomBar-container-start,
.bottomBar-container-center,
.bottomBar-container-middleLeft,
.bottomBar-container-middleRight,
.bottomBar-container-end {
  height: 54px;
  width: 77.75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  gap: 4px;
}

.dotted-line {
  border-left: 1px dotted black;
  height: 70%;
  margin: 0 0;
}
.invisible-line {
  margin: 0 0;
}
.bottomBar-container-center {
  border-radius: 50%;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 2px 8px #333;
  border: 1.5px solid #333;
  height: 70px;
  width: 70px;
  background: #fff;
}

.bottomBar-container-middleLeft {
  border-radius: 0 30px 0 0;
}
.bottomBar-container-middleRight {
  border-radius: 0 30px 0 0;
}
.jobs-active {
  background: var(--grey-grey-100, #000);
  color: var(--grey-grey-10, #e6e6e6) !important;
}
.dashboard-active {
  background: var(--grey-grey-100, #000);
  color: var(--grey-grey-10, #e6e6e6) !important;
}
.dashboard-active p {
  color: var(--grey-grey-10, #e6e6e6);
  border: none;
}
.jobs-active p {
  color: var(--grey-grey-10, #e6e6e6);
  border-radius: 0 0 0 0;
}
.professional-active {
  border-radius: 0 16px 0 0;
  background: var(--grey-grey-100, #000);
}
.bottomBar-container-start svg {
  height: 22px;
  width: 22px;
}
.professional-active p {
  color: var(--grey-grey-10, #e6e6e6);
  border: none;
}
.events-active {
  border-radius: 30px 0 0 0;
  background: var(--grey-grey-100, #000);
  color: var(--grey-grey-10, #e6e6e6);
}
.events-active p {
  color: var(--grey-grey-10, #e6e6e6);
  border: none;
}

.notification-active {
  border-radius: 16px 0 0 0;
  background: var(--grey-grey-100, #000);
  color: var(--grey-grey-10, #e6e6e6);
}
.notification-active p {
  color: var(--grey-grey-10, #e6e6e6);
}
.message-active {
  border-radius: 16px 0 0 0;
  background: var(--grey-grey-100, #000);
  color: var(--grey-grey-10, #e6e6e6);
}
.message-active p {
  color: var(--grey-grey-10, #e6e6e6);
}
