.parent-basesignup {
  zoom: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 100vh;
}
.basesignup-navbar-wrapper {
  filter: drop-shadow(-2px 2px 6px rgba(153, 153, 153, 0.25));
  background-color: #fff;
  margin-bottom: 40px;
  background-image: url('../../../public/images/homheader_logo.svg');
  background-size: cover;
}
.basesignup-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 100px;
}

.basesignup-navbar button,
.basesignup-navbar button:focus,
.basesignup-navbar button:hover,
.basesignup-navbar button:active {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
}

.form-container {
  border: 3px solid var(--grey-grey-80, #333);
  margin: 30px 100px;
  border-radius: 52px;
  padding: 40px 52px 65px 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-wrapper {
  text-decoration: none;
}
.basesignup-form {
  padding: 0 0;
  flex-grow: 1;
}

.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title img {
  height: 120px;
}

.header-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 60px 0;
  text-decoration: none;
  color: black;
}
.signinGoogle-img {
  width: 50%;
  cursor: pointer;
}
.btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 60px; */
  align-items: center;
}

.btn-wrapper button {
  font-size: 24px;
  font-weight: 700;
  width: 300px;
  border-radius: 20px;
  border-color: black;
  border: solid black;
  color: black;
}

.btn-wrapper .profuser-btn,
.profuser-btn:active,
.profuser-btn:focus {
  background-color: #cdc0f2 !important;
  color: black !important;
  border-color: black !important;
}

.btn-wrapper .non-profuser-btn,
.non-profuser-btn:active,
.non-profuser-btn:focus {
  background-color: #f2e1c0 !important;
  color: black !important;
  border-color: black !important;
}
.basesignup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  min-height: 52px;
  font-size: 18px;
  align-items: center;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);

  color: #fff;
}
.alreadylogin-wrapper {
  display: flex;
  gap: 5px;
  margin-top: 80px;
  font-size: 18px;
}
.signup-later {
  display: none;
}

@media screen and (max-width: 700px) {
  .basesignup-form {
    flex-grow: 0;
    margin-top: 52px;
    position: fixed;
    width: 100%;
  }
  .basesignup-navbar-wrapper {
    margin-bottom: 0;
    background-image: none;
  }
  .basesignup-navbar {
    padding: 10px;
    position: fixed;
    width: 100%;
    filter: drop-shadow(-2px 2px 6px rgba(153, 153, 153, 0.25));
    background-color: #fff;
  }
  .signinGoogle-img {
    width: 60%;
    cursor: pointer;
  }
  .basesignup-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    min-height: 40px;
    font-size: 14px;
    filter: drop-shadow(-2px -2px 6px rgba(153, 153, 153, 0.25));
    background: #fff;
    align-items: center;
    color: #000;
    position: sticky;
    bottom: 0;
  }

  .basesignup-navbar button,
  .basesignup-navbar button:focus,
  .basesignup-navbar button:hover,
  .basesignup-navbar button:active {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000 !important;
    background-color: transparent !important;
    border: none !important;
  }

  .header-title {
    margin-bottom: 0;
  }

  .header-title img {
    width: 300px;
    height: auto;
  }

  .header-subtitle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    text-align: center;
    margin: 16px 0 45px 0;
  }

  .form-container {
    padding: 0 20px;
    border: none;
    margin: 30px 18px;
  }

  .btn-wrapper {
    flex-direction: column;
    gap: 20px;
    margin: 0 0 0 0;
    justify-content: center;
    align-items: center;
  }

  .btn-wrapper button {
    font-size: 18px;
    font-weight: 700;
    width: 250px;
    height: 100px;
    border-radius: 20px;
    border-color: black;
    border: solid black;
    text-align: center;
    --bs-btn-padding-x: 0;
  }
  .alreadylogin-wrapper {
    margin-top: 50px;
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .signup-later {
    margin-top: 50px;
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'Roboto';
    align-items: center;
    font-weight: 500;
  }
}

@media (min-width: 701px) and (max-width: 1120px) {
  .header-title img {
    width: 100%;
    height: auto;
  }
  .header-subtitle {
    margin: 20px 30px;
    text-align: center;
  }
  .btn-wrapper {
    flex-direction: column;
    width: 100%;
  }
  .btn-wrapper button {
    width: 350px;
    height: 120px;
  }
}
