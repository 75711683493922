/* // KEEP */
.changePasswordModal :global(.modal-content) {
  margin: 70px auto 0;
  max-width: 500px;
}
.postBtnWrapper {
  padding-top: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.postBtn {
  padding: 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}

.passwordContainer {
  position: relative;
}

.showPasswordButton {
  position: absolute;
  right: 0px;
  top: 0px;
}
