.trend-view-container {
  padding: 15px 10px 20px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
}

.trend-view-header {
  display: flex;
  justify-content: space-between;
}

.trend-view-timestamp {
  width: max-content;
  font-size: 10px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  height: max-content;
}
.time-dot {
  content: '';
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #666;
}

.trend-view-attachment {
  width: 100%;
  height: auto;
  max-height: 476px;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
}

.trend-view-interactions {
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  padding: 12px 0 0;
  align-items: center;
  font-size: 14px;
  color: #666;
  font-weight: 700;
}

.trend-view-btn {
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  font-weight: 700;
  background: transparent;
}
.trend-comment-btn {
  border: 1px solid #e6e6e7 !important;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
  color: #666;
  font-weight: 700;
  background: transparent;
  border-radius: 50px;
}

.trend-view-copy-icon {
  cursor: pointer;
  font-size: 18px;
  color: #666;
}

.trend-view-comment-input {
  border-radius: 50px;
  border: 1px solid #666;
  background: #fff;
  padding: 10px 18px;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  margin-top: 14px;
}

.trend-view-comment-box {
  border-radius: 1.5rem;
  border: 1px solid var(--grey-grey-30, #b3b3b3);
  padding: 1.25rem;
  margin: 12px 4px 12px 12px;
}

.trend-view-comment-textarea {
  border: 1px solid var(--grey-grey-30, #b3b3b3);
  height: 7.5rem;
  font-size: 14px;
  width: 100%;
}

.charCount {
  text-align: right;
  font-size: 12px;
  color: #666;
  margin-top: 8px;
}

.trend-view-comment-submit {
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.postBtn {
  padding: 4px 20px;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}
.postBtn:disabled {
  background-color: #ccc !important;
  border: 1px solid #ccc;
  color: #666;
  cursor: not-allowed;
}
.trend-view-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  position: relative;
}

.trend-view-content-text {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: pre-wrap;
}

.read-more {
  display: flex;
  color: #4b619b;
  cursor: pointer;
  justify-content: flex-end;
  margin-top: 4px;
  align-items: center;
  font-size: 12px;
  padding-right: 12px;
}

.clamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contentPointer {
  cursor: pointer;
}

.commentContainer {
  padding: 10px 10px 12px;

  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  margin: 10px 0 8px;
}

.commentHeader {
  display: flex;
  justify-content: space-between;
}

.commentSubmit {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.char-count {
  text-align: right;
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  padding: 0 12px;
}
.commentInput {
  border-radius: 50px !important;
  border: 1px solid #666;
  background: #fff;
  padding: 10px 18px;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  margin-top: 12px;
}
.time-dot {
  color: #1a1a1a;
  border-radius: 50%;
  width: 4px;
}
.attachmentIcon {
  width: 80px;
  height: auto;
  cursor: pointer;
}
.attachment-subtitle {
  text-align: center;
  font-size: 12px;
  margin-top: 3px;
}
.pdfAttachmentWrapper {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px 8px;
  gap: 10px;
  align-items: center;
}
.pdfDownloadBtn {
  background: #1a1a1a;
  color: #fff;
  border: 1px solid #1a1a1a !important;
  font-size: 12px;
  padding: 4px 8px;
}
.pdfDownloadBtn :hover {
  background: #fff;
  color: #1a1a1a;
  border: 1px solid #1a1a1a;
}
@media screen and (max-width: 700px) {
  .trend-view-container {
    padding: 10px;
    border-radius: 0;
    margin-bottom: 8px;
  }
  .trend-view-btn {
    font-size: 12px;
  }
  .trend-comment-btn {
    font-size: 12px;
  }
  .trend-view-interactions {
    padding: 8px 0 0;
  }
  .trend-comment-btn {
    border: none !important;
    padding: 0;
    border-radius: 0;
  }
}
