.main-container {
  background: var(--grey-grey-00, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  zoom: 1;
  border: 3px solid var(--grey-grey-80, #333);
  border-radius: 52px;
  padding: 20px 0px;
  margin: 40px 400px;
  width: auto;
}
.border-container {
  width: 60%;
}
.tech-parent-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heading-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 50px;
  justify-content: space-between;
}

.wcLogoHeading {
  display: flex;
  justify-content: center;
  width: 100%;
}
.heading-container img {
  width: 70%;
  height: auto;
}

.back-home-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.back-home img {
  width: 90%;
  height: auto;
  align-items: flex-start;
}
.subtitle-container {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  margin: 20px auto 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech-input-container {
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
}
.tech-label {
  font-size: 16px;
  margin-bottom: 10px;
}

.tech-input-field {
  margin-bottom: 12px;
  width: 100%;
}

.tech-input-field input {
  width: 100%;
  height: 40px;
  border: 1px solid var(--grey-grey-80, #333);
  padding: 5px;
  border-radius: 6px;
}

.submit-button {
  border-radius: 12px;
  display: flex;
  font-size: 16px;
  padding: 12px 60px;
  align-items: center;
  margin: 0 auto 20px;
  color: white;
  font-weight: bold;
  justify-content: center;
  background: var(--grey-grey-80, #333);
  cursor: pointer;
}
.tech-input-textarea {
  height: 130px;
  width: 100%;
  border-color: black;
  border-radius: 6px;
  max-height: 150px;
}
.hedaer-logo {
  width: auto;
  height: 45px;
  cursor: pointer;
}
.copyright-footer {
  display: flex;
  position: relative;
  bottom: 0;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);
  min-height: 52px;
  align-items: center;
  font-size: 20px;
  color: #fff;
  width: 100%;
  justify-content: center;
  font-family: 'Roboto';
  font-weight: bold;
}
.copyright-mobile {
  display: none;
}
.tech-support-navbar {
  filter: drop-shadow(-2px 2px 6px rgba(153, 153, 153, 0.25));
  background-image: url('../../images/homheader_logo.svg');
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 100px;
  background-size: cover;
}
.tech-support-navbar Button {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
}
.success-message {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .hedaer-logo {
    filter: invert();
    height: 34px;
  }
  .tech-support-navbar {
    filter: drop-shadow(-2px 2px 6px rgba(153, 153, 153, 0.25));
    background-image: none;
    background: #fff;
    padding: 6px 20px;
    position: fixed;
    width: 100%;
    top: 0;
  }
  .tech-support-navbar Button {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black !important;
    background-color: transparent !important;
    border: none !important;
  }
  .tech-support-navbar Button p {
    font-size: 16px !important;
  }
  .copyright-footer {
    display: flex;
    position: fixed;
    bottom: 0;
    background: #fff;
    font-weight: 700;
    height: 40px;
    align-items: center;
    font-size: 16px;
    color: #000;
    width: 100%;
    justify-content: center;
    font-family: Roboto;
    filter: drop-shadow(-2px -2px 6px rgba(153, 153, 153, 0.25));
  }
  .copyright-window {
    display: none;
  }
  .copyright-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .main-container {
    padding: 1rem 1rem;
    border: none;
    margin: 52px 40px 20px;
    justify-content: flex-start;
    display: flex;
    height: 100%;
    min-height: max-content;
  }
  .border-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heading-container {
    margin-bottom: 30px;
  }

  .wcLogoHeading {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .heading-container img {
    width: 300px;
    margin: 1rem 0 0 0;
    height: auto;
  }

  .subtitle-container {
    font-size: 16px;
    margin-bottom: 2rem;
  }

  .tech-input-container {
    margin-bottom: 20px;
  }

  .tech-input-field {
    margin-bottom: 15px;
    width: 100%;
  }

  .tech-label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
  }

  .tech-input-field input {
    height: 30px;
    padding: 5px;
    font-size: 14px;
  }

  .submit-button {
    font-size: 14px;
    font-weight: 700;
    height: 38px;
    margin-bottom: 40px;
  }
  .tech-input-textarea {
    height: 100px;
    font-size: 14px;
  }
}

@media (min-width: 701px) and (max-width: 1120px) {
  .main-container {
    margin: 50px 100px;
  }
  .copyright-mobile {
    display: none;
  }
}
