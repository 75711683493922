.chat-list-container {
  margin-right: 24px;
  border-radius: 12px 12px 0px 0px;
  width: 300px;
  flex-shrink: 0;
  min-height: 200px;
  border: 1px solid #e1e1e1;
  box-shadow: -0.5px -0.5px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.message-list-container {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  flex-shrink: 0;
}

.message-list-container-close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
  height: auto;
}

.message-ui-container {
  display: flex;
  border-radius: 12px 12px 0 0;
  border: 1px solid var(--grey-grey-10, #e6e6e6);
  margin-right: 24px;
  padding-left: 8px;
  padding-right: 14px;
  width: 300px;
  height: 50px;
  align-items: center;
  background-color: white;
  box-shadow: -0.5px -0.5px 10px rgba(0, 0, 0, 0.1);
}

.messasge-ui {
  position: fixed;
  bottom: 0;
  right: 0;
  gap: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  z-index: 1000;
}

@media screen and (max-width: 700px) {
  .messasge-ui {
    display: none;
  }
}
