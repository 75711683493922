/* job.module.css */

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
}
.jobs-logo {
  height: 22px;
  margin-bottom: 2px;
}
.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.closeIcon {
  cursor: pointer;
  padding-right: 8px;
}
.comingSoon-lottie {
  margin-top: -68px;
  transform: scaleX(1.3);
}
.modal-body-text {
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  margin-bottom: 10px;
}

@media screen and (max-width: 700px) {
  .modalHeader {
    font-size: 18px;
  }
  .jobs-logo {
    height: 20px;
    margin-bottom: 2px;
  }
  .modal-body-text {
    font-size: 14px;
  }
  .jobModalParent {
    min-width: 250px;
    width: 320px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}
