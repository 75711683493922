.modalBody-wrapper {
  background: #323234;
  padding: 15px 15px 20px 15px;
}
.modalBody-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 20px 10px;
}
.aadhaar-image-container img {
  width: 140px;
  height: auto;
}
.signzy-uidai-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  background: white;
}
.signzy-image-container {
  margin-bottom: -25px;
}
.signzy-image-container img {
  width: 30px;
  height: 30px;
}
.arrow-image-container img {
  width: 140px;
  height: 80px;
}
.uidai-container {
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  margin-top: -25px;
}
.wcpLogo-image-container img {
  width: 110px;
}
.text-desc-container {
  margin-top: 40px;
  color: white;
  padding: 5px 5px 5px 10px;
  text-align: left;
}
.submit-button-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.submit-button {
  background: white;
  border-radius: 24px;
  color: black;
  border: none;
  padding: 5px 50px;
  font-family: Roboto;
  font-weight: 700;
}

@media screen and (max-width: 700px) {
  .secured-aadhaar-modalBody {
    padding: 10px 10px;
  }
  .modalBody-image-container {
    padding: 30px 10px;
  }
  .aadhaar-image-container img {
    width: 90px;
    height: auto;
  }
  .arrow-image-container img {
    width: 120px;
    height: 80px;
  }
  .uidai-container {
    font-size: 8px;
  }
  .wcpLogo-image-container img {
    width: 70px;
  }
  .submit-button {
    padding: 5px 45px;
  }
}
