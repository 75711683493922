.wc-nav {
  height: 60px;
  background-color: #fff;
  display: grid;
  /* grid-template-columns: 250px 1fr 360px; */
  grid-template-rows: 60px;
  grid-template-columns: 80px 1.5fr 2fr;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  border: 1px solid #dee2e6;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0.5px 10px rgba(0, 0, 0, 0.1);
  grid-column-gap: 10px;
}

.wc-navSearch {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 54px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  border: 1px solid #dee2e6;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0.5px 10px rgba(0, 0, 0, 0.1);
  padding: 0 12px;
  transition: height 0.3s ease, padding 0.3s ease, background-color 0.3s ease;
}

.input-active {
  border-color: #000 !important;
}

.nav-noti-btn :hover {
  transform: scale(1.2) !important;
}

.wc-content-container {
  margin: 0 auto;
}

.wc-nav .logo-container {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wc-search-container {
  position: relative;
  width: 75%;
}
.wc-search-container-active {
  width: 100%;
  transition: left 0.9s linear;
}
.wc-search-active {
  border-radius: 16px 16px 0 0 !important;
}

.wc-search {
  height: 40px;
  border: 1px solid #b3b3b3;
  border-radius: 16px;
  padding: 6px;
  display: flex;
  align-items: center;
}
.wc-navSearch .wc-search {
  height: auto;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 5px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 1.625rem;
}
.wc-search .wc-search-input {
  padding: 0px 12px 2px 10px;
  border: none;
  outline: none;
  height: 1.75rem;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  flex: 1;
  width: 80px;
}
.search-filters-windows {
  width: max-content;
}

.wc-search .search-filter-btn {
  padding: 6px 1rem;
  height: 1.9rem;
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  border: 1px solid #000;
  background-color: #fff !important;
  line-height: normal;
  color: #000 !important;
  border-radius: 50px !important;
  height: 28px;
  line-height: 14px !important;
}

.wc-search .search-filter-btn:first-child {
  margin-right: 12px;
}

.wc-search .search-filter-btn.selected {
  color: #fff !important;
  background-color: #000 !important;
}

.nav-options {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.wc-nav .nav-options button {
  padding: 0px;
  border: none !important;
  font-size: 14px;
}

.wc-nav .nav-options .profile-btn img {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: 1px solid #e6e6e6;
}

.searchWrap {
  margin: 0 1.5rem;
  flex-grow: 1;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown input {
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-options {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  background-color: white;
  width: inherit;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
  /* width: 100%; */
}

.dropdown-option {
  padding: 6px;
  cursor: pointer;
  line-height: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.sort-dropdown-mobile :global(button) {
  background: none !important;
  color: black;
  border: none;
  font-size: 14px;
  padding: 4px 12px 4px 8px;
}
.sort-dropdown-mobile :global(.show) {
  overflow: auto !important;
  max-height: 85vh;
  margin: 1px -15px 0 0;
  border-radius: 24px 0 0 24px;
}
.navbarMobileDropdownSetting {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 8px;
  text-overflow: ellipsis;
  margin-right: 20px;
  margin-top: 4px;
}

.sort-dropdown-mobile :global(.show)::-webkit-scrollbar {
  width: 5px;
  display: none;
}

.profileContextMenu.notificationIcon :global(.dropdown-menu.show) {
  width: 350px !important;
  padding: 0px;
  padding-left: 12px;
}

.profileContextMenu :global(.dropdown-menu.show) {
  margin-top: 16px;
  width: 250px;
  padding: 12px 16px;
}

.profileContextMenu :global(button) {
  background: none !important;
  color: black;
  border: none;
}

.profileContextMenu :global(button):hover {
  background: none !important;
  color: black;
  border: none;
}
.profileContextMenu :global(.dropdown-menu) {
  left: 0 !important;
}
.profileContextMenu :global(button.dropdown-item) {
  text-wrap: pretty;
  padding: 5px 0;
  margin: 7px 0;
  border-bottom: 1px solid var(--grey-grey-10, #e6e6e6) !important;
}

.profileContextMenu :global(button.dropdown-item):hover {
  background-color: #e7e7e7 !important;
}

.profileContextMenuNoti.notificationIcon :global(.dropdown-menu.show) {
  width: 350px !important;
  padding: 0px;
  padding-left: 12px;
}

.profileContextMenuNoti :global(.dropdown-menu.show) {
  margin-top: 16px;
  width: 350px;
  padding: 12px 16px;
}

.profileContextMenuNoti :global(button) {
  background: none !important;
  color: black;
  border: none;
}

.profileContextMenuNoti :global(button):hover {
  background: none !important;
  color: black;
  border: none;
}

.profileContextMenuNoti :global(button.dropdown-item) {
  text-wrap: pretty;
  padding: 5px 0;
  margin: 7px 0;
  border-bottom: 1px solid var(--grey-grey-10, #e6e6e6) !important;
}

.profileContextMenuNoti :global(button.dropdown-item):hover {
  background-color: #e7e7e7 !important;
}

.notificationIcon button.recent-updates {
  padding-bottom: 5px !important;
  border-bottom: none !important;
}

.updates-row {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.noti-row {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.avatar-img-mid {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  border: 1px solid #e6e6e6;
}

.updateText:hover {
  text-decoration: underline;
}

.actionButton {
  min-width: 120px;
  padding: 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;

  background-color: white !important;
  color: black !important;
}

.actionButtonPrimary {
  background-color: #000 !important;
  color: #fff !important;
}

.actionButtonContainer {
  text-align: center;
}

.actionButtonGap {
  margin: 0px 32px;
}

.setting-dropdown :global(.dropdown-menu.show) {
  width: 300px;
}

.setting-dropdown :global(span) {
  padding: 12px 0 !important;
}

.setting-menu-header {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 0 !important;
}

.setting-menu-item {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px !important;
}

.noti-searchbar {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 12px;
}

.search-user-img-windows {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.search-filters-mobile {
  display: none;
}
.search-user-img-mobile {
  display: none;
}
.navbar-tooltip div:last-child {
  background: transparent;
  color: #000;
  margin-top: -5px;
  z-index: 0;
}
.navbar-tooltip div:first-child {
  display: none;
}
.dropdown-name-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sort-dropdown-mobile-container {
  display: none;
}
.dropdown-name {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 14px;
  text-overflow: ellipsis;
  margin-right: 20px;
}
.dropdown-image-wrapper {
  display: flex;
  justify-content: flex-end;
}
.deleteAccountModal :global(.modal-content) {
  margin: 70px auto 0;
  max-width: 500px;
}

.navbarButtonTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbarButtonTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notiButtonTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}
.notiDropdownBtnImg {
  height: 22px;
}

.navbarButtonTextWrapper img {
  height: 18px;
  width: 18px;
}
.wc-nav .nav-options .navbarButtonTextWrapper button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbarButtonTextWrapper span {
  font-size: 12px;
  font-family: Roboto;
}
.selectedButton {
  border-bottom: 2px solid #1a1a1a !important;
}

.selectedButtonTextWrapper {
  border-bottom: 2px solid #1a1a1a !important;
  color: #1a1a1a !important;
  font-weight: 500;
}

.selectedButtonImg {
  filter: invert(14%) sepia(40%) saturate(100%) hue-rotate(0deg) brightness(30%)
    contrast(90%);
}

.navbarButtonText {
  margin-top: 5px;
  font-size: 10px;
  color: #212529;
}
.notiButtonText {
  margin-top: -2px;
  font-size: 10px;
  color: #212529;
  filter: none !important;
}
.notiDropdownBtnImg:hover {
  transform: scale(1.25);
}

.trendsBilnkerWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.trendsBilnker {
  width: 6px;
  height: 6px;
  background: #fb9b72;
  border-radius: 50%;
  position: absolute;
  bottom: 14px;
  left: 14px;
}
.trendsBilnkerMobile {
  position: relative;
  width: 6px;
  height: 6px;
  background: #fb9b72;
  top: -30px;
  border-radius: 50%;
  left: 20px;
}

.notiDropDownOpen {
  border-bottom: 2px solid #1a1a1a !important;

  font-size: 10px;
  font-weight: 500;

  color: #1a1a1a;
}
.notiDropDownOpenImg {
  filter: invert(14%) sepia(40%) saturate(100%) hue-rotate(0deg) brightness(30%)
    contrast(90%);
}
.navClearBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}
.navbarSeacrhDropdown {
  font-size: 12px;
  max-height: 260px;
  overflow-y: auto;
  border-radius: 0 0 16px 16px;
  padding-bottom: 0px;
  min-height: 44px;
  padding-top: 0 !important;
}
.messageProfileDropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
@media screen and (max-width: 700px) {
  .wc-nav {
    background-color: #fff;
    display: grid;
    grid-template-columns: 30px 1fr 78px;
    grid-template-rows: 54px;
    align-content: center;
    border: none;
    padding: 0 8px;
    height: 54px;
    filter: drop-shadow(-2px 2px 6px rgba(153, 153, 153, 0.25));
    gap: 5px;
    transition: height 0.3s ease, padding 0.3s ease, background-color 0.3s ease;
  }

  .noti-searchbar {
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0;
  }
  .wc-search-active {
    border-radius: 12px 12px 0 0 !important;
  }
  .navbarSeacrhDropdown {
    border-radius: 0 0 12px 12px !important;
  }
  .wc-search-paddingActive {
    padding: 8px 10px !important;
  }
  .wc-search-container-active {
    margin: 0 !important;
  }
  .sort-dropdown-mobile-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    min-width: 42px;
    margin-right: 12px;
  }
  .logo-container {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wc-search-container {
    position: relative;
    margin: 4px 0 0 0;
    padding: 0 0;
    height: 34px;
    width: 100%;
  }
  .wc-nav .wc-search {
    height: auto;
    border: 1px solid #333;
    border-radius: 1.625rem;
    padding: 5px 9px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
  }
  .wc-search .wc-search-input {
    padding: 0px 5px;
    height: auto;
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }
  .wc-search button {
    border-radius: 50px;
    color: var(--grey-grey-00, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-100, #000);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .wc-search .search-filter-btn {
    margin-bottom: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 24px;
    padding: 4px 8px;
    height: auto;
    width: 110px;
  }
  .search-filter-drop-down :first-child:hover {
    color: var(--grey-grey-00, #fff);
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-100, #000);
  }
  .search-filter-drop-down :first-child:focus {
    color: var(--grey-grey-00, #fff);
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-100, #000);
  }
  .nav-options {
    padding: 0;
  }
  .nav-options img {
    height: 35px !important;
    width: 35px !important;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
  }
  .btn-group {
    border: none;
  }
  .dropdown-options {
    max-height: 110px;
    top: 41px;
    font-size: 12px;
    width: 92%;
  }

  .dropdown-option {
    padding: 6px;
    line-height: normal;
  }

  .search-filter-drop-down div {
    border: none;
    --bs-dropdown-min-width: auto !important;
    background: transparent;
    border: none;
    width: 100%;
  }

  .search-user-img-windows {
    display: none;
  }
  .search-user-img-mobile {
    display: flex;
  }
  .search-filters-mobile {
    display: flex;
  }
  .search-filters-windows {
    display: none;
  }
  .navbar-tooltip {
    display: none;
  }
  .trendsImageLogoWrapper svg {
    height: 28px !important;
    width: auto !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .wc-nav {
    grid-template-columns: 250px 360px 380px;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .wc-nav {
    grid-template-columns: 50px 550px 480px;
    justify-content: center;
    grid-column-gap: 20px;
  }
  .profileContextMenu :global(.dropdown-menu.show) {
    transform: translate3d(-202px, 40px, 0px) !important;
  }
}
@media screen and (max-width: 355px) {
  .wc-nav {
    padding: 0 2px;
  }
}
@media screen and (min-width: 481px) and (max-width: 781px) {
  .profileContextMenu :global(.dropdown-menu) {
    right: 0 !important;
    left: auto !important;
  }
  .navbarButtonText {
    display: none;
  }
  .notiButtonText {
    display: none;
  }
  .selectedButtonTextWrapper {
    padding-bottom: 4px;
  }
  .wc-search-container {
    position: relative;
    width: 85%;
  }
}
