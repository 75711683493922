.new-comment-container {
  border-radius: 1.5rem;
  border: 1px solid var(--grey-grey-30, #b3b3b3);
  padding: 1.25rem;
  margin: 12px 4px 12px 12px;
}

.new-comment-text-area {
  border: none;
  height: 7.5rem;
  font-size: 14px;
}

.selectedAttachment {
  margin-top: 1rem;
  display: flex;
  gap: 8px;
}

.selectedAttachment div {
  cursor: pointer;
}

.postBtnWrapper {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.postBtn {
  padding: 4px 24px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}

.dashboard-search-attachment-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.attachment-btn {
  margin-right: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  text-align: left;
  color: #1a1a1a;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  border: none !important;
  line-height: normal;
}

.attachment-btn:last-child {
  margin-right: 0px;
}

.attachment-btn img {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

@media screen and (max-width: 700px) {
  .postBtn {
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-100, #000);
    display: flex;
    padding: 4px 10px;
    align-items: center;
    gap: 4px;
    color: var(--grey-grey-00, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .attachment-btn img {
    height: 12px;
    width: 12px;
    margin-right: 2px;
  }
  .attachment-btn {
    margin-right: 20px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #1a1a1a;
    width: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 12px;
  }
  .new-comment-text-area {
    border: none;
    height: 5.5rem;
    font-size: 14px;
  }
  .selectedAttachment {
    padding: 12px 0 0 0;
    display: flex;
    gap: 8px;
    height: auto;
    font-size: 12px;
    margin: 0;
  }

  .selectedAttachment img {
    width: 10px;
    height: 10px;
  }
  .selectedAttachment svg {
    width: 8px;
    height: 8px;
  }
  .selectedAttachment div {
    padding: 0;
  }
  .dashboard-search-attachment-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
  }
}
