.wc-layout {
  display: grid;
  grid-template-areas:
    'nav nav nav'
    'sidebar content rightbar';
  grid-template-columns: 225px 1fr 225px;
  grid-template-rows: 91px 1fr;
  min-height: 100vh;
  overflow: hidden;
}

.dashboard-parent {
  background: #e6e6e6;
}

.rightbar {
  grid-area: rightbar;
  grid-template-rows: max-content;
  display: grid;
  grid-row-gap: 20px;
  height: max-content;
}

.rightbar-content {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
  height: max-content;
  padding: 10px;
  flex-direction: column;
}

.rightbar-update-readMore {
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #4b619b;
  padding-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.rightbar-update-readMore:hover {
  text-decoration: underline;
}
.info-image-wrapper :global(.tooltip-inner) {
  font-size: 12px;
  background-color: #1a1a1a;
}
.wc-content-wrapper {
  display: grid;
  margin-top: 4px;
  /* grid-template-columns: 275px 600px 275px; */
  grid-template-columns: 1.5fr 3fr 1.5fr;
  grid-template-areas: 'sidebar content rightbar';
  justify-content: center;
  padding: 5rem 20px;
  grid-column-gap: 20px;
  min-height: 100vh;
}
.wc-layout-post {
  display: grid;
  grid-template-areas: 'content content content';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 100vh;
  overflow: hidden;
}

.wc-layout .wc-content-container {
  overflow-y: auto;
  height: 100%;
  grid-area: content;
}

.wc-content {
  height: 100%;
}

.dashboard-noti-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 14px;
  width: 100%;
  max-height: 375px;
  overflow: auto;
  /* margin: 2px 0 12px; */
}
.dashboard-noti-wrapper::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  display: none;
}
.dashboard-noti-wrapper:hover::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
  display: flex;
  height: 70%;
  margin: 40px 0 12px;
}

.hypertextLinkContainer {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  gap: 6px;
}
.hypertext-top-hr {
  border-top: 2px solid #ccc;
  width: 95%;
  margin: 0 auto;
  flex-grow: 1;
}
.hypertext-link-wrapper {
  display: flex;
  justify-content: space-between;
}
.hypertext-link {
  color: #000 !important;
  cursor: pointer;
  font-size: 12px;
}
.hypertext-link a {
  color: #000 !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
}
.hypertext-link:hover {
  text-decoration: underline;
}
.hypertext-link a:hover {
  text-decoration: underline;
}
.hypertext-copyRight {
  margin: 5px auto 0 auto;
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
}

.sticky {
  position: fixed;
  top: 78px;
  width: 275px;
  padding: 0 30px;
}
.rightbar-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.backgroundFaded {
  pointer-events: none;
}

@media screen and (max-width: 480px) {
  .wc-layout {
    grid-template-areas:
      'nav nav nav'
      'content content content';
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    padding: 0 0;
    margin-bottom: 52px;
  }
  .wc-content {
    height: auto;
    background-color: #e6e6e6;
    max-width: 100vw;
    width: auto;
  }
  .wc-content-wrapper {
    display: grid;
    padding: 3.5rem 0px;
    grid-template-columns: 1fr;
    grid-template-areas: 'content';
    justify-content: center;
    min-height: 100vh;
    grid-column-gap: 0;
  }
  .wc-content.with-border {
    border-right: none;
    background: #e6e6e6;
  }
  .backgroundFaded {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    filter: blur(2px);
    pointer-events: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .wc-layout {
    grid-template-areas:
      'nav nav nav'
      'content content content';
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    padding: 0 0;
    margin-bottom: 52px;
  }
  .wc-content {
    height: auto;
    background-color: #e6e6e6;
    max-width: 100vw;
    width: auto;
  }
  .wc-content-wrapper {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    grid-template-areas: 'sidebar content ';
    justify-content: center;
    min-height: 100vh;
  }
  .wc-content.with-border {
    border-right: none;
    background: #e6e6e6;
  }
  .backgroundFaded {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    filter: blur(2px);
    pointer-events: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .admin-active {
    grid-template-areas:
      'nav nav nav'
      'sidebar content content' !important;
    grid-template-columns: 225px 1fr !important;
  }
  .wc-layout {
    grid-template-columns: 250px 410px 300px;
  }
  .wc-content-wrapper {
    grid-template-columns: 250px 425px 250px;
    padding-top: 5rem;
    grid-column-gap: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .admin-active {
    grid-template-areas:
      'nav nav nav'
      'sidebar content content' !important;
    grid-template-columns: 225px 1fr !important;
  }
  .wc-layout {
    grid-template-columns: 250px 600px 300px;
  }
  .wc-content-wrapper {
    grid-template-columns: 275px 580px 275px;
    padding-top: 5rem;
    grid-column-gap: 1.5rem;
  }
}
