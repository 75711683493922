.submitBottom {
  padding: 6px 1rem;
  height: 1.9rem;
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  border: 1px solid #000;
  background-color: #000;
  line-height: normal;
  color: white;
  border-radius: 50px !important;
}
.report-user-modal :global(.modal-header) {
  font-size: 14px;
  padding: 8px 12px;
}
.report-user-modal :global(.modal-content) {
  margin: 70px auto;
}
.report-user-modal :global(.modal-header) button {
  font-size: 12px;
}
.report-user-modal :global(.modal-header .modal-title) {
  font-size: 16px;
}
.report-modal-desc {
  font-size: 14px;
}
