.noti-filters {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 0 5px;
}
.noti-desc-modal {
  color: #1a1a1a;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  margin-bottom: 12px;
  overflow: auto;
  max-height: 50vh;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.noti-desc-modal::-webkit-scrollbar {
  display: none !important;
}
.noti-desc-modal:hover::-webkit-scrollbar {
  display: block !important;
}
.dashboard-noti-modal :global(.carousel-slider) {
  max-width: 200px !important;
  width: 100%;
  margin: 0 auto;
}
.noti-filters div {
  display: flex;
  align-items: center;
}
.noti-filters span {
  width: max-content;
  min-width: max-content;
  margin-right: 5px;
  font-size: 14px;
}

.noti-date {
  color: var(--grey-grey-60, #666);

  /* Body/Body Big R */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.noti-content ul {
  margin-bottom: 0 !important;
  padding-left: 1.5rem;
}

.noti-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
}

.noti-title {
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7.5px 0;
}

.noti-content-row {
  display: flex;
  justify-content: space-between;
}
.noti-read-more {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  width: max-content;
  color: #4b619b !important;
}

.noti-title-span {
  text-decoration: none;
  color: var(--grey-grey-90, #1a1a1a);
  cursor: pointer;
}
.noti-title-span:hover {
  text-decoration: underline;
  /* color: #2f80ed; */
}
.date-wrapper input {
  font-size: 12px;
  padding: 4px 6px;
}

.noti-content {
  background: #fff;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 5px;
}

.noti-modal > div > div {
  max-height: 85vh;
  /* min-height: 80vh; */
}
.noti-modal-header {
  padding: 12px 16px;
  font-size: 16px;
}
.noti-modal-header-title {
  font-size: 18px;
}
.noti-modal-header button {
  font-size: 12px;
}
.noti-modal-footer {
  padding: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.bottombar {
  display: none;
}
.noti-category {
  display: none;
}
.load-more {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.topScroll {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}

.rightbar-update-readMore {
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #4b619b;
  padding-right: 10px;
  cursor: pointer;
  margin-top: 20px;
}
.rightbar-update-readMore:hover {
  text-decoration: underline;
}
.dashboard-noti-li {
  padding-left: 10px !important;
}
.dashboard-noti-li::marker {
  margin-left: 0 !important;
}
.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1050;
  background-color: #000;
  color: #fff;
  padding: 12px 18px;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.prev-btn:hover,
.next-btn:hover {
  background-color: #444;
  transform: translateY(-50%) scale(1.1);
}

.prev-btn:disabled,
.next-btn:disabled {
  background-color: #a0a0a0;
  color: #fff;
  cursor: not-allowed;
  opacity: 0.6;
}

.prev-btn {
  left: -60px;
}

.next-btn {
  right: -60px;
}

@media screen and (max-width: 700px) {
  .bottombar {
    display: flex;
  }

  .noti-category {
    display: flex;
    align-items: center;
    margin: 12px 0 16px;
    padding: 0 12px;
    justify-content: space-between;
  }
  .noti-content {
    padding: 8px 4px;
    margin-bottom: 8px;
    border-radius: 0;
  }
  .notiFilterMobileWrapper {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .noti-wrapper {
    padding-top: 8px;
  }
  .noti-category-btn {
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-100, #000) !important;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--grey-grey-00, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .pins-btn {
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: #fff !important;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 0;
    color: #000 !important;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .pins-active {
    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000) !important;
    background: #000 !important;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .noti-filters {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    gap: 0;
  }
  .noti-filters div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .noti-modal-header-title {
    font-size: 14px;
  }
  .date-wrapper {
    color: var(--grey-grey-60, #666);
    text-align: right;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .date-wrapper input {
    padding: 4px 5px;

    border-radius: 24px;
    border: 1px solid var(--grey-grey-100, #000);
    background: var(--grey-grey-00, #fff);
    color: var(--grey-grey-100, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100px;
    height: auto;
  }
  .noti-desc-modal {
    font-size: 14px;
  }
  .noti-filters span {
    width: max-content;
    font-size: 12px !important;
  }
  .noti-date-ul {
    padding-left: 1rem;
  }

  .noti-content ul {
    padding-left: 0.85rem !important;
  }
  .noti-date {
    color: var(--grey-grey-60, #666);
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
  }
  .noti-title {
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding-top: 0;
    padding-bottom: 0px;
    align-items: center;
    margin-bottom: 8px;
  }
  .noti-content-row {
    display: flex;
    justify-content: space-between;
  }
  .load-more {
    margin-top: 20px;
    margin-bottom: 110px;
    padding: 15px;
    display: flex;
    justify-content: center;
    font-weight: 500;
  }
  .prev-btn-mobile {
    background: #fff !important;
    color: #000 !important;
    border: none !important;
    font-size: 16px;
    width: 100px;
  }
  .prev-btn-mobile:disabled {
    color: #a0a0a0 !important;
    border: none !important;
    font-size: 16px;
    cursor: not-allowed;
    opacity: 0.6;
  }
  .prevBtnWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    bottom: -44px;
  }
  .noti-modal :global(.modal-content) {
    margin-top: -40px !important;
  }
}
