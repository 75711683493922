.profile-sidebar {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  padding: 12px;
  height: max-content;
}
.dashboard-edit-profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-edit-profile button {
  border: 1px solid #ccc;
  font-size: 12px !important;
  width: 100%;
  color: #000;
  background: #fff !important;
  padding: 5px 12px;
}
.dashboard-edit-profile button:hover,
.dashboard-edit-profile button:active {
  background-color: #ccc !important;
  color: #000 !important;
  font-weight: 500;
}
.profile-sidebar .profile-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profile-avatar-report {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.profile-avatar .avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}

.profile-avatar .dashboard-avatar-img {
  width: 75px !important;
  height: 75px !important;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}
.profile-avatar .edit-btn {
  position: absolute;
  background-color: #000;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 8px 6px 16px;
  border-radius: 23px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar .edit-btn img {
  margin-left: 4px;
}

.reportContainer {
  position: absolute;
  right: 0;
  top: 0;
}

.reportButton {
  color: #000;
  background-color: transparent !important;
  border: none;
}

.reportButton:hover {
  color: #000;
  background-color: transparent !important;
  border: none;
}

.profile-sidebar .user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 30px 0;
}

.profile-sidebar .user-details h3 {
  color: #333;
  font-size: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 2px;
  text-align: center;
}

.profile-sidebar .user-details p {
  margin: 0;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  color: #1a1a1a;
  text-align: center;

  display: flex;
  align-items: center;
}
.profile-sidebar .dashboard-user-details {
  margin-top: 10px !important;
  margin-bottom: 0;
}
.profile-sidebar .dashboard-user-details h3 {
  font-size: 16px !important;
  line-height: 16px !important;
}

.profile-sidebar .dashboard-user-details p {
  font-size: 12px !important;
}
.profile-sidebar .user-details p .edit-location-btn {
  padding: 0;
  margin-left: 8px;
}

.user-contact {
  padding: 10px 0px;
  border-bottom: 1px dotted #e6e6e6;
}
.dashboard-user-contact {
  border-bottom: none;
}

/* .user-contact  */
.contact-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
}
/* .user-contact  */
.dashboard-contact-info > span {
  font-size: 12px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard-contact-info img {
  height: 14px;
  width: 14px;
  margin-right: 4px;
}
.dashboard-info-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.dashboard-info-btn img {
  height: 12px !important;
  width: 12px !important;
}
.profile-sidebar .profile-options {
  display: flex;
  flex-direction: column;
}

.profile-sidebar .profile-options .option-item {
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #e6e6e6;
  text-align: left;
  color: #1a1a1a;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
}

.profile-sidebar .profile-options.account-options {
  position: absolute;
  bottom: 0;
}

.profile-sidebar .profile-options.account-options .account-item {
  border: none;
  color: #1a1a1a;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 18.75px;
  padding-left: 35px;
}

.metaRow {
  display: flex;
  align-items: center;
  margin-bottom: 1.75rem;
  cursor: pointer;
}

.menuSelected {
  font-weight: bold;
}

.metaIcon {
  height: 1rem;
  width: 1rem;
  margin: 0 0.75rem;
}

.actionButton,
.actionButton:hover,
.actionButton:active,
.actionButton:focus {
  min-width: 120px;
  padding: 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000 !important;
  background-color: #fff !important;
  color: #000 !important;
}

.actionButtonPrimary,
.actionButtonPrimary:hover {
  color: white !important;
  background-color: black !important;
}

.actionButtonContainer {
  padding-top: 16px;
  text-align: center;
  display: flex;
  gap: 8px;
}

.floatingIcon {
  position: absolute;
  right: 0;
}
.sort-dropdown-menu-mobile {
  border-radius: 8px;
  background: #fcfcfc;
  margin-right: -12px;
}

.sort-dropdown-menu-mobile :global(button) {
  background: none !important;
  color: black;
  border: none;
  font-size: 14px;
  padding: 4px 12px 4px 8px;
}
.sort-dropdown-mobile div :active {
  --bs-dropdown-link-active-bg: none;
}
.membership-navbar {
  display: flex;
  align-items: center;
  color: var(--grey-grey-90, #1a1a1a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.membership-navbar img {
  height: 16px !important;
  width: 16px !important;
}

@media screen and (max-width: 700px) {
  .profile-sidebar {
    border: none;
    padding: 12px 0px;
  }
  .user-contact .contact-info {
    font-size: 16px !important;
  }
  .user-contact .contact-info span {
    font-size: 16px !important;
  }
  .user-contact .contact-info span img {
    width: 18px !important;
    height: 18px !important;
    margin-right: 4px;
  }
  .user-contact .contact-info button img {
    width: 18px !important;
    height: 18px !important;
  }
}
