.wc-sidebar {
  grid-area: sidebar;
  min-height: calc(100% - 61px);
  padding: 25px 32px;
  border-right: 1px solid #e6e6e6;
  overflow-y: auto;
}

.wc-sidebar .sidebar-option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 14px;
  width: 100%;
  border-bottom: 2px dotted #e6e6e6;
}

.sidebar-option .create-sign-btn,
.sidebar-option .create-sign-btn:active,
.sidebar-option .create-sign-btn:focus {
  padding: 6px 16px;
  line-height: 21px;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 700;
  color: #1a1a1a;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 23px;
  display: flex;
  align-items: center;
}

.wc-sidebar .sidebar-list {
  overflow-y: auto;
}

.wc-sidebar .sidebar-list .list-item,
.wc-accordion .wc-accordion-header .accordion-button {
  display: block;
  width: 100%;
  padding: 14px 0px;
  background-color: #fff;
  line-height: 21px;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 700;
  color: #1a1a1a;
  text-align: left;
  border: none;
  outline: none;
  border-bottom: 2px dotted #e6e6e6;
}

.wc-sidebar .sidebar-list .list-item:focus,
.wc-accordion .wc-accordion-header .accordion-button:focus {
  border: none;
  outline: none;
  border-bottom: 2px dotted #e6e6e6;
  box-shadow: none;
}

.list-item .list-icon,
.accordion-button .list-icon {
  margin-right: 10px;
}

.wc-accordion .accordion-item {
  border: none;
}

.wc-accordion .wc-accordion-header .accordion-button::after {
  display: none;
}

.wc-accordion .wc-accordion-body .accordion-body {
  padding: 14px 0px;
}

.wc-accordion .wc-accordion-body .accordion-body .wc-accordion-body-btn {
  padding-bottom: 14px;
  font-size: 16px;
  font-family: Roboto;
  border: none;
  outline: none;
  font-weight: 400;
  line-height: 18.75px;
}

/* Profile Sidebar */

.profile-sidebar {
  position: relative;
  height: 100%;
}

.profile-sidebar .profile-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar .avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}

.profile-avatar .edit-btn {
  position: absolute;
  background-color: #000;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 8px 6px 16px;
  border-radius: 23px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar .edit-btn img {
  margin-left: 4px;
}

.profile-sidebar .user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #e6e6e6;
}

.profile-sidebar .user-details h3 {
  color: #333;
  font-size: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 2px;
  text-align: center;
}

.profile-sidebar .user-details p {
  margin: 0;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  color: #1a1a1a;
  text-align: center;

  display: flex;
  align-items: center;
}

.profile-sidebar .user-details p .edit-location-btn {
  padding: 0;
  margin-left: 8px;
}

.user-contact {
  padding: 10px 0px;
  border-bottom: 1px dotted #e6e6e6;
}

.user-contact .contact-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
}

.profile-sidebar .profile-options {
  display: flex;
  flex-direction: column;
}

.profile-sidebar .profile-options .option-item {
  padding: 15px 0px 15px 20px;
  border-bottom: 1px solid #e6e6e6;
  text-align: left;
  color: #1a1a1a;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
}

.profile-sidebar .profile-options.account-options {
  position: absolute;
  bottom: 0;
}

.profile-sidebar .profile-options.account-options .account-item {
  border: none;
  color: #1a1a1a;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 18.75px;
  padding-left: 35px;
}
