/* Add this CSS in your component's CSS file or a global stylesheet */
.larger-checkbox input[type='checkbox'] {
  transform: scale(1.5);
}

.new-signup-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 100px 20px 100px;
  zoom: 1;
  min-height: 100vh;
}

.new-signup-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  filter: drop-shadow(-2px 2px 8px rgba(153, 153, 153, 0.25));
  background: #fff;
  padding: 12px 100px;
  margin-bottom: 40px;
  background-image: url('../../../../public/images/homheader_logo.svg');
  background-size: cover;
  z-index: 1;
}
.new-signup-navbar button,
.new-signup-navbar button:hover,
.new-signup-navbar button:active {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
}

.new-signup-container {
  border: 3px solid var(--grey-grey-80, #333);
  border-radius: 52px;
  padding: 43px 52px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand-logo img {
  width: 100%;
  margin-bottom: 20px;
}

.new-signup-form-container {
  width: 95%;
}

.new-signup-form {
  margin-top: 24px;
  padding: 0 10px;
  margin: 20px 0;
}
.form-label {
  margin-bottom: 12px;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}

.form-input {
  box-shadow: none;
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  padding: 8px 5px 10px 16px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-password-container .form-label {
  margin-bottom: 12px;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}

.form-password-container .form-input {
  box-shadow: none;
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  padding: 8px 5px 10px 16px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* color: #b3b3b3; */
}

.form-password-container .password-input-container {
  position: relative;
}
.password-input-container .pass-eyebtn {
  position: absolute;
  top: 10%;
  right: 10px;
  font-weight: 700;
  font-size: 12px;
  background: none;
  border: none;
}
.password-input-container .pass-eyebtn:hover {
  background: none;
  border: none;
}

.password-input-container .pass-eyebtn-error {
  position: absolute;
  top: 10%;
  right: 10px;
  font-weight: 700;
  font-size: 12px;
  background: none;
  border: none;
}
.new-signup-form.not-selected .form-password-container .form-input {
  background-color: #e6e6e6;
}

.form-password-container .form-input::placeholder {
  color: #666;
}
.tnc-container {
  display: flex;
  gap: 20px;
  margin: 40px 0;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 12px;
  text-align: left;
  margin-left: 4px;
}
.google-recaptcha {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.form-submit {
  align-items: center;
  background-color: #333;
  border-radius: 16px;
  box-shadow: none;
  color: #fff;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-top: 20px;
  padding: 21px 0;
  width: 90%;
}
.font-bold-text {
  font-size: 24px;
  font-weight: 700;
}
.otpButton {
  border-radius: 5px;

  padding: 0 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  border: none;
  height: 37.33px;
  min-width: max-content;
}
.otpWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-top: 1.5rem;
}
.signupGoogle-image {
  width: 50%;
  cursor: pointer;
}
.new-signup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  min-height: 52px;
  font-size: 18px;
  align-items: center;
  background: linear-gradient(180deg, #393d48 0%, #282b32 100%);

  color: #fff;
}
.verifiedOtpTickLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  height: 18px;
}

@keyframes preloader-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.preloader-plus .preloader-custom-img {
  animation: preloader-fade 3000ms linear infinite;
  width: 300px;
}

.preloader-plus .preloader-custom-img {
  animation: preloader-fade 3000ms linear infinite;
  width: 300px;
}
.preloader-plus img {
  display: block;
  max-width: 100%;
}

.preloader-plus * {
  color: #000;
  display: flex;
  flex: 1;
  margin: auto auto;
  height: 100vh;
}

@media screen and (max-width: 700px) {
  .preloader-plus .preloader-custom-img {
    width: 150px;
  }
  .new-signup-parent {
    padding: 0 10px;
    margin-top: 60px;
    justify-content: flex-start;
  }
  .otp-input {
    width: 75%;
  }
  .signupGoogle-image {
    width: 60%;
    margin-top: 10px;
  }
  .new-signup-navbar {
    padding: 10px;
    margin-bottom: 0;
    background-image: none;
    height: 51.34px !important;
    position: fixed;
    top: 0;
  }
  .new-signup-footer {
    display: flex;
    justify-content: center;
    font-weight: 700;
    min-height: 40px;
    font-size: 14px;
    filter: drop-shadow(-2px -2px 6px rgba(153, 153, 153, 0.25));
    background: #fff;
    align-items: center;
    color: #000;
    position: sticky;
    bottom: 0;
    overflow: hidden;
  }
  .new-signup-navbar button {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000 !important;
    background-color: transparent !important;
    border: none !important;
  }
  .new-signup-container {
    border: none;

    padding: 0;
    margin: 15px 0;
  }

  .new-signup-form-container {
    width: 100%;
  }

  .new-signup-form {
    margin-top: 0;
    padding: 0 10px;
    margin: 0 0 20px 0;
  }
  .brand-logo {
    display: flex;
    justify-content: center;
  }
  .brand-logo img {
    width: 300px;
    margin-bottom: 0;
  }
  .state-dist select {
    height: 30px;
    padding: 0 0.3rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  .password-input-container .pass-eyebtn {
    position: absolute;
    top: 1px;
    right: 10px;
    font-weight: 700;
    font-size: 12px;
  }
  .password-input-container .pass-eyebtn img {
    height: 14px;
    width: 14px;
  }
  .password-input-container .pass-eyebtn-error img {
    height: 14px;
    width: 14px;
  }
  .password-input-container button img {
    height: 14px;
    width: 14px;
  }

  .password-input-container .pass-eyebtn-error {
    position: absolute;
    top: 1px;
    right: 10px;
    font-weight: 700;
    font-size: 12px;
  }
  .tnc-container {
    display: flex;
    gap: 10px;
    margin: 25px 0 30px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .google-recaptcha {
    margin-top: 20px;
    margin-bottom: 35px;
  }
  .form-submit {
    align-items: center;
    background-color: #333;
    border-radius: 16px;
    box-shadow: none;
    color: #fff;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-top: 0;
    padding: 10px 0;
    width: 45%;
  }
  .font-bold-text {
    font-size: 14px;
    font-weight: 700;
  }
  .tnc-container p {
    font-size: 12px;
  }
  .larger-checkbox input[type='checkbox'] {
    transform: scale(1.3);
  }
  .otpButton {
    font-size: 12px;
    height: 33.33px;
  }
  .otpWrapper {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    margin-top: 0.75rem;
  }
}
