.attachmentImage {
  width: auto;
  height: 80px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px #00000036;
  cursor: pointer;
}
.firstAndAllAttachments {
  display: flex;
}

.attachmentIcon {
  width: 50px;
  height: auto;
  cursor: pointer;
}

.attachmentImageSmall {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  box-shadow: 1px 1px 4px #00000036;
  cursor: pointer;
}

.attachmentIconSmall {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.attachmentContainer {
  display: flex;
  gap: 12px;
}
.attachment-subtitle {
  text-align: center;
  font-size: 10px;
  margin-top: 6px;
}

.attachmentColumn {
  flex: 1 0 100%;
}

.attachmentColumnOther {
  flex: 0 0 auto;
}

.attachmentImagePost {
  width: 95%;
  height: auto;
  margin: 0 auto 10px;
  object-fit: scale-down;
  border-radius: 12px;
  box-shadow: 1px 1px 4px #00000036;
  cursor: pointer;
  border: 1px solid #c2c2c2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachmentIconPost {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.attachment-subtitlepost {
  text-align: center;
  font-size: 12px;
  margin-top: 6px;
}
.image-video-carousel {
  padding: 0 12px;
}
.image-video-carousel :global(.carousel.carousel-slider .control-arrow:hover) {
  background: #b3b3b3 !important;
  z-index: 0 !important;
  border-radius: 5px;
}
.zoomable-image-container {
  cursor: pointer;
}
.carousel-image-container {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 575px;
  max-width: 100%;
  cursor: pointer;
}
.attachmentElseImagePost {
  display: flex;
  gap: 12px;
  margin: 12px 0 16px;
}
.attachmanetElseImageDashboard {
  display: flex;
  gap: 12px;
  margin: 16px 0 0;
}

@media screen and (max-width: 700px) {
  .firstAndAllAttachments {
    display: flex;
    flex-direction: column;
  }
  .image-video-carousel {
    padding: 0 0;
  }

  .carousel-video-container {
    width: auto;
    height: auto;
    object-fit: contain;
    min-width: 100%;
    max-width: 100%;
    max-height: 475px;
  }
  .carousel-image-container {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 475px;
    max-width: 100%;
  }
  .attachmentIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .attachmentImage {
    width: auto;
    height: 40px;
    cursor: pointer;
  }
  .attachmentContainer {
    display: flex;
    gap: 12px;
    margin: 10px 0 0 0;
  }
  .firstImageAttachment img {
    height: auto !important;
    width: 95% !important;
    margin: 0 auto;
    display: flex;
    border-radius: 12px;
  }
  .attachmentElseImagePost {
    display: flex;
    gap: 12px;
    margin: 6px 0 12px 0;
  }
  .attachmanetElseImageDashboard {
    display: flex;
    gap: 12px;
    margin: 12px 0 0;
  }
}
