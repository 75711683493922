.wc-nav {
  grid-area: nav;
  padding: 25px 30px 14px 32px;
  background-color: #fff;
  border-bottom: 0.5px solid #cccccc;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  z-index: 1;
}

.wc-nav .logo-container {
  width: 323px;
  cursor: pointer;
}

.wc-nav .wc-search {
  flex: 1;
  margin-right: 33px;
  border: 2px solid #e6e6e6;
  border-radius: 50px;
  padding: 12px 14px;
  display: flex;
  align-items: center;
  width: 100%;
  align-items: center;
}

.wc-search .wc-search-input {
  flex: 1;
  padding: 0px 12px;
  border: none;
  outline: none;
  font-size: 22px;
  font-family: Roboto;
  font-weight: 700;
}

.search-filter-btn {
  padding: 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.search-filter-btn:first-child {
  margin-right: 12px;
}

.search-filter-btn.selected {
  color: #fff;
  background-color: #000;
}

.wc-nav .nav-options .profile-btn {
  margin-left: 33px;
}

.searchWrap {
  margin: 0 1.5rem;
  flex-grow: 1;
}
