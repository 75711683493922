.wrapper {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  gap: 70px;
}

.textWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textTitle {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-family: Roboto;
  font-style: normal;
  line-height: 35px;
  color: var(--grey-grey-90, #1a1a1a);
}
.textDesc {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}

.imageWrapper {
  /* width: 300px;
  height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.imageWrapper img {
  width: 300px;
  height: 300px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.wrapperMobile {
  display: none;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    display: none;
  }
  .wrapperMobile {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    margin: 15px 15px 15px 15px;
    padding: 20px 20px 20px 20px;
  }
  .textWrapper {
    width: 100%;
  }

  .textTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
    line-height: normal;
    font-weight: 800;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: auto; */
    margin: 10px 0;
  }
  .imageWrapper img {
    width: 150px;
    height: auto;
  }

  .textDesc {
    font-size: 14px;
    margin-top: 0.75rem;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.2;

    text-align: justify;
    width: 100%;
  }
}

@media (min-width: 701px) and (max-width: 1120px) {
  .textTitle {
    line-height: normal;
  }
  .wrapper {
    margin-right: 1rem;
    gap: 10px;
  }
}
