.meetingItemContainer {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 12px;
  transition: box-shadow 0.3s ease;
}

.meetingItemContainer:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.meetingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meetingIconInfoWrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
}

.meetingTitle {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  margin-bottom: 4px;
}

.professionalInfo {
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 14px;
  flex-direction: column;
  gap: 4px;
}

.professionalName,
.meetingTime {
  display: flex;
  align-items: center;
  width: 100%;
}
.actionsContainerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.actionsContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}

.joinButton span {
  display: flex;
  align-items: center;
}
.joinButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: max-content;
}
.meetingPendingBtn:hover {
  background-color: #d3d3d3 !important;
}
.meetingPendingBtn {
  color: #6c757d !important;
  cursor: default !important;
}
.joinButton:disabled {
  background-color: #d3d3d3;
}

.joinButton:hover {
  background-color: #0069d9;
}

.clientActions {
  display: flex;
  gap: 12px;
}

.meetingDescription {
  color: #555555;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0 2px;
}

.acceptButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.acceptButton:hover {
  background-color: #218838;
}

.declineButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.declineButton:hover {
  background-color: #c82333;
}

.meetingActionsDropdown {
  font-size: 14px;
}

.meetingActionsDropdown :global(.dropdown-menu.show) {
  width: 80px !important;
  min-width: max-content !important;
  padding: 2px 4px !important;
}

.meetingActionsDropdown :global(.dropdown-item) {
  font-size: 14px;
  padding: 4px 6px !important;
}

@media screen and (max-width: 700px) {
  .meetingParent {
    margin: 4px 0 36px;
  }

  .meetingItemContainer {
    padding: 8px 12px;
    margin-bottom: 8px;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .meetingDetails {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .meetingTitle {
    margin-bottom: 8px;
  }
  .professionalInfo {
    font-size: 14px;
    color: #555555;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    margin-bottom: 8px;
    flex-direction: row;
  }

  .professionalName,
  .meetingTime {
    margin-right: 0;
    font-size: 13px;
    color: #666666;
    width: auto;
  }

  .actionsContainer {
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding-top: 6px;
    margin-top: 4px;
    width: 100%;
  }

  .clientActions,
  .meetingActionsDropdown {
    gap: 6px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .meetingDescription {
    font-size: 14px;
    color: #555555;
    line-height: 1.4;
    margin: 0 0 6px;
  }

  .acceptButton,
  .declineButton,
  .joinButton {
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 4px;
    width: 40%;
    font-weight: bold;
  }

  .iconContainer {
    display: none; /* Hide the large icon on mobile to save space */
  }

  .meetingActionsDropdown :global(.dropdown-menu.show) {
    width: auto !important;
    padding: 4px 8px !important;
  }
}
