.success-animation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .success-icon {
    width: 500px;
    height: 500px;
    border: 3px solid #33cc33;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #33cc33;
    font-size: 2em;
    animation: success-fade 5s ease-in-out forwards;
  }
  
  @keyframes success-fade {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  