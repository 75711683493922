.wc-result {
  margin-bottom: 35px;
}
.wc-result-question {
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.question-header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  padding: 0 10px;
}
.question-header-date {
  font-size: 12px;
  width: max-content;
  min-width: max-content;
}
.result-avatar {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}
.reply-header-date {
  font-size: 12px;
}
.result-avatar .avatar-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid #e6e6e6;
}
.comment-date {
  font-size: 12px;
}
.result-avatar p {
  margin: 0;
}

.result-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.result-avatar .follow-btn {
  padding: 0;
  margin-left: 8px;
  display: flex;
  align-items: center;
  color: var(--grey-grey-60, #666);
  /* Body/Body Small B */
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
}

.result-avatar .follow-btn img {
  width: 16px;
  height: 16px;
}

.qa-container {
  border-radius: 24px;
  border: 1px solid #e6e6e6;
  padding: 14px;
}

.qa-container h3 {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 16px;
}

.qa-container h3 span {
  color: #666;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 16.41px;
  padding-left: 25px;
  position: relative;
}

.qa-container h3 span::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #666;
}

.qa-container p {
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  margin-bottom: 14px;
}

.qa-container b {
  font-size: 18px;
}

.add-answer-input {
  border-radius: 50px !important;
  border: 1px solid #666;
  background: #fff;
  padding: 10px 18px;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  margin-top: 16px;
}

.interaction-btn {
  align-items: center;
  border: none;
  color: #666;
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.41px;
  margin: 0;
  padding: 0;
}

.answer-btn {
  height: 30px;
  border: 1px solid #e6e6e7 !important;
  padding: 1rem 0.5rem !important;
  border-radius: 50px !important;
  size: 14px;
  margin: 0;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 16.41px;
  display: flex;
  align-items: center;
  min-width: max-content;
}

.qa-container .qa-answer-editor {
  margin-bottom: 18px;
}

.qa-container .qa-attachments {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 16px;
}

.qa-container .qa-attachments img {
  max-width: 80px;
  max-height: 80px;
  width: 80px;
  height: 80px;
  border-radius: 14px;
  overflow: hidden;
}

.item-interaction {
  align-items: center;
  display: flex;
  gap: 18px;
  margin-top: 12px;
  height: 24px;
  color: var(--grey-grey-60, #666);
  justify-content: space-between;
}
.item-interaction-reply {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey-grey-60, #666);
  margin-top: 10px;
}

.item-interaction-left {
  align-items: center;
  display: flex;
  gap: 32px;
}

.item-interaction-right {
  flex: 0.5 1;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.replies-container {
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 24px;
  margin: 12px 4px 12px 12px;
}

.new-comment-input {
  height: 3rem;
  border-radius: 1.5rem;
  border: 1px solid var(--grey-grey-30, #b3b3b3);
}

.menuButton :global(button) {
  background: none !important;
  color: black;
  border: none;
  font-size: 14px;
}

.menuButton :global(button):hover {
  background: none !important;
  color: black;
  border: none;
}

.questionContent {
  white-space: pre-wrap;
  padding: 0 0;
  font-size: 15px;
  margin-bottom: 16px;
}

.questionContent img {
  width: 95%;
  height: auto;
  border-radius: 12px;
  object-fit: contain;
  border: 1px solid #c2c2c2;
  margin: 0 auto 0 auto;
  display: flex;
}
.question-navbar {
  display: none;
}
.commentWrapper {
  padding: 10px 10px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  margin: 10px 0 8px;
}
.commentUserDateWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}
.commentDate {
  font-size: 12px;
}
.commentContent {
  font-size: 14px;
}
.horizontal-ruler-reply {
  margin-bottom: 12px;
}
.comment-hr {
  margin: 12px 0 0 0;
}
@media screen and (max-width: 700px) {
  .questionResultParent {
    margin: 12px;
  }
  .wc-result-question {
    padding: 0;
  }
  .question-navbar {
    display: flex;
    justify-content: left;
    align-items: center;
    background: #fff;
    margin: 10px 0 6px;
    height: 35px;
  }
  .question-navbar img {
    height: 16px;
    width: 16px;
  }
  .question-navbar Button {
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    padding: 0;
  }
  .wc-result .qa-container {
    border-radius: 24px;
    border: 1px solid #e6e6e6;
    padding: 14px 12px;
    margin-bottom: 18px;
  }
  .wc-result .quesConationer {
    border: none !important;
    margin: 12px 0 24px !important;
    padding: 0 0 !important;
  }
  .qa-container h3 {
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 17.5px */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  .qa-container h3 span {
    display: none;
  }
  .answer-btn {
    height: 20px;
    border: 1px solid #e6e6e7 !important;
    padding: 0rem 0.5rem !important;
    border-radius: 50px !important;
    font-size: 12px;
  }
  .answer-btn img {
    height: 14px;
    width: 14px;
  }
  .commentUserDateWrapper {
    margin-bottom: 10px;
  }
  .interaction-btn {
    height: 20px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--grey-grey-60, #666);
    width: max-content;
  }
  .item-interaction {
    padding-top: 0;
    margin-top: 0;
  }
  .item-interaction-left {
    gap: 12px;
  }
  .wc-result .result-avatar {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    justify-content: space-between;
    flex-direction: column;
  }
  .wc-result .result-avatar span {
    color: var(--grey-grey-60, #666);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .add-answer-input {
    border-radius: 24px;
    border: 1px solid var(--grey-grey-30, #b3b3b3);
    background: var(--grey-grey-00, #fff);
    color: var(--grey-grey-60, #666);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 22px 0 18px 0;
    height: 30px;
  }
  .qa-container .questionContent {
    white-space: pre-wrap;
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow-x: hidden;
    margin-bottom: 0;
  }

  .qa-container .questionContent p {
    color: var(--grey-grey-90, #1a1a1a);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .questionContent img {
    object-fit: contain;
    margin-bottom: 20px;
  }
  .menuButton div button {
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
  }
  .menuButton div {
    padding: 5px 0 0 0;
    min-width: 50px;
    width: 100px;
    max-width: 150px;
  }
  .comment-hr {
    margin: 12px 0 8px 0;
  }
}
