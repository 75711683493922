.wc-sidebar {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  grid-area: sidebar;
  margin-bottom: 76px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: max-content;
}

.profile-dropdown-item-img {
  height: 14px;
  width: 14px;
}
.dashboard-sidebar-wrapper {
  background: #fff;
  border-radius: 0 0 10px 10px;
  padding: 4px 12px 12px;
  margin-top: -16px;
}
.filter-btn-wrapper {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #e6e6e6;
}
.profile-sidebar-parent {
  background-color: #fff;
  padding: 12px;
  border-radius: 10px;
}
.filter-btn-wrapper > button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: #1a1a1a !important;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  color: #fff !important;
  border: 1px solid #333;
  border-radius: 10px 10px 0 0;
}
.wc-sidebar .sidebar-option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 2px dotted #e6e6e6;
}
.eye-btn-img {
  height: 10px;
  width: 10px;
  padding: 0;
}
.sidebar-option .create-sign-btn,
.sidebar-option .create-sign-btn:active,
.sidebar-option .create-sign-btn:focus {
  padding: 6px 16px;
  line-height: 21px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  color: #1a1a1a;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 10px;
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-option .create-sign-btn:hover {
  background-color: #1a1a1a !important;
  color: #fff;
}
.sidebar-option .create-sign-btn:hover img {
  filter: invert(1) !important;
}
.sidebar-option .create-sign-btn:hover svg {
  fill: #fff !important;
}
.wc-sidebar .sidebar-list {
  overflow-y: auto;
}

.wc-sidebar .sidebar-list .list-item,
.wc-accordion .wc-accordion-header .accordion-button {
  display: block;
  width: 100%;
  padding: 14px 0px;
  background-color: #fff;
  line-height: 21px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  color: #1a1a1a;
  text-align: left;
  border: none;
  outline: none;
  border-bottom: 2px dotted #e6e6e6;
}

.wc-sidebar .sidebar-list .list-item:focus,
.wc-accordion .wc-accordion-header .accordion-button:focus {
  border: none;
  outline: none;
  border-bottom: 2px dotted #e6e6e6;
  box-shadow: none;
}

.list-icon {
  width: 16px;
  height: 16px;
  margin: 0px 10px;
  margin-left: 0px;
}

.list-item .list-icon,
.accordion-button .list-icon {
  margin-right: 10px;
}

.wc-accordion :global(.accordion-item) {
  border: none;
}

.wc-accordion :global(button.accordion-button::after) {
  content: none;
}

.wc-accordion :global(button.accordion-button) {
  background: none;
  box-shadow: none;
  border-bottom: 2px dotted #e6e6e6;
  font-weight: 500;
  color: black;
  padding: 8px 0;
  font-size: 14px;
}

.wc-accordion :global(.accordion-body) {
  padding: 0px;
  border-bottom: 2px dotted #e6e6e6;
}

.relativeDiv {
  position: relative;
}

.wc-accordion-body-btn {
  display: block !important;
}

.floatingIcon {
  position: absolute;
  right: 0;
}

.wc-accordion .wc-accordion-body .accordion-body .wc-accordion-body-btn {
  padding-bottom: 14px;
  font-size: 14px;
  font-family: Roboto;
  border: none;
  outline: none;
  font-weight: 400;
  line-height: 18.75px;
}

/* Profile Sidebar */

.profile-sidebar {
  position: relative;
  height: 100%;
}

.profile-sidebar .profile-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar-report {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.profile-avatar .avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}

.profile-avatar .edit-btn {
  position: absolute;
  background-color: #000;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 8px 6px 16px;
  border-radius: 23px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 21.09px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar .edit-btn img {
  margin-left: 4px;
}

.reportContainer {
  position: absolute;
  right: 0;
  top: 0;
}

.reportButton {
  color: #000;
  background-color: transparent !important;
  border: none;
}

.reportButton:hover {
  color: #000;
  background-color: transparent !important;
  border: none;
}

.profile-sidebar .user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0 10px;
  border-bottom: 1px dotted #e6e6e6;
}
.profile-sidebar .self-user-details {
  padding: 20px 0 10px !important;
}

.profile-sidebar .user-details h3 {
  color: #333;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: inherit;
  margin-bottom: 2px;
  text-align: center;
  margin-top: 5px;
}

.profile-sidebar .user-details p {
  margin: 0;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  color: #1a1a1a;
  text-align: center;

  display: flex;
  align-items: center;
}

.profile-sidebar .user-details p .edit-location-btn {
  padding: 0;
  margin-left: 8px;
}

.user-contact {
  padding: 5px 0 10px 0px;
  border-bottom: 1px dotted #e6e6e6;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 6px;
}
.contact-info span {
  font-size: 12px;
}
.user-contact .contact-info {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 21.09px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  gap: 5px;
}

.profile-sidebar .profile-options {
  display: flex;
  flex-direction: column;
}
.info-btn {
  padding: 0;
}
.phoneWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}
.phone-contact-img {
  height: 14px !important;
  width: 14px !important;
  margin-right: 4px;
}
.contact-info .phone-contact-img {
  height: 14px !important;
  width: 14px !important;
  max-width: 14px !important;
  max-width: 14px !important;
}

.info-btn img {
  height: 14px;
  width: 14px;
}
.profile-sidebar .profile-options .option-item {
  padding: 12px 0px;
  border-bottom: 1px solid #e6e6e6;
  text-align: left;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 21.09px;
}

.profile-sidebar .profile-options.account-options {
  position: absolute;
  bottom: 0;
}

.profile-sidebar .profile-options.account-options .account-item {
  border: none;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 18.75px;
  padding-left: 35px;
}

.metaRow {
  display: flex;
  align-items: center;
  margin-bottom: 1.75rem;
  cursor: pointer;
}

.menuSelected {
  font-weight: bold;
}

.metaIcon {
  height: 1rem;
  width: 1rem;
  margin: 0 0.75rem;
}

.actionButton,
.actionButton:hover,
.actionButton:active,
.actionButton:focus {
  min-width: 87px;
  padding: 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000 !important;
  background-color: #fff !important;
  color: #000 !important;
}

.actionButtonPrimary,
.actionButtonPrimary:hover {
  color: white !important;
  background-color: black !important;
}

.actionButtonContainer {
  display: flex;
  padding-top: 16px;
  text-align: center;
  gap: 6px;
}

.floatingIcon {
  position: absolute;
}
.create-signature-tooltip div:first-child {
  top: 3px;
}
.create-signature-tooltip div:last-child {
  background: #e6e6e6;
  min-width: 300px;
  margin-top: 10px;
  border: 1px solid #000;
  color: #000;
  margin-left: 1px;
  box-shadow: -2px -2px 4px #00000036;
  font-weight: 400;
}
.location-dropdown-wrapper {
  display: flex;
  gap: 30px;
}
.create-sign-review {
  background: #fff;
  border-radius: 0 0 10px 10px;
  padding: 4px 12px 12px;
  margin-top: 4px;
  font-size: 14px;
}
.userExpertiseFilertParent {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.expertisecheckboxContainer {
  display: flex;
  flex-direction: column;
  gap: '4px';
}
.expertisecheckboxWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 2px dotted #e6e6e6;
}
.expertisecheckboxWrapper span {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
  color: black;

  font-size: 14px;
}
.expertisecheckboxWrapper div {
  display: flex;
  gap: 4px;
}

.dashboardCategoryModal :global(.modal-dialog) {
  margin: 0 !important;
}

.dashboardCategoryModalOpen :global(.modal-dialog),
.dashboardCategoryModalClose :global(.modal-dialog) {
  transition: none !important;
}

.dashboardCategoryModal :global(.modal-content) {
  position: fixed;
  left: -300px;
  top: 70px;
  width: 85vw;
  height: auto;
  max-height: calc(90vh - 70px) !important;
  overflow-y: auto;
  background: transparent;
  border-radius: 0 10px 10px 0;
  transition: left 0.3s linear !important;
  background: #fff !important;
}

.dashboardCategoryModalOpen :global(.modal-content) {
  position: fixed;
  left: -2px;
  top: 70px;
  width: 85vw;
  height: auto;
  max-height: calc(90vh - 70px) !important;
  overflow-y: auto;
  background: transparent;
  border-radius: 0 10px 10px 0;
  transition: left 0.3s linear !important;
  background: #fff !important;
}

.dashboardCategoryModalClose :global(.modal-content) {
  left: -300px; /* Slide the modal back to the left */
  top: 70px;
  width: 85vw;
  height: auto;
  max-height: calc(90vh - 70px) !important;
  overflow-y: auto;
  background: transparent;
  border-radius: 0 24px 24px 0;
  background: #fff !important;
  transition: left 0.3s linear !important;
}
/* new */
.hypertextLinkContainer {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  gap: 6px;
}
.hypertext-top-hr {
  border-top: 2px solid #ccc;
  width: 95%;
  margin: 0 auto;
  flex-grow: 1;
}
.hypertext-link-wrapper {
  display: flex;
  justify-content: space-between;
}
.hypertext-link {
  color: #000 !important;
  cursor: pointer;
  font-size: 12px;
}
.hypertext-link a {
  color: #000 !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
}
.hypertext-link:hover {
  text-decoration: underline;
}
.hypertext-link a:hover {
  text-decoration: underline;
}
.hypertext-copyRight {
  margin: 5px auto 0 auto;
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
}

.sticky {
  position: fixed;
  top: 78px;
  width: 234px;
  padding: 0 20px;
}
@media screen and (max-width: 700px) {
  .wc-sidebar {
    display: none;
  }
  .wc-accordion :global(button.accordion-button) {
    background: none;
    box-shadow: none;
    border-bottom: 2px dotted #e6e6e6;
    font-weight: bold;
    font-size: 14px;
    color: black;
    padding-left: 0px;
    padding: 8px 0;
    align-items: center;
  }
  .sidebar-option {
    display: flex;
    justify-content: center;
    width: 80%;
  }
  .expertisecheckboxWrapper {
    gap: 8px;
  }
  .sidebar-option .create-sign-btn {
    padding: 6px 16px;
    line-height: normal;
    font-size: 14px !important;
    font-family: Roboto;
    font-weight: 700;
    background: #1a1a1a !important;
    color: #fff !important;
    border: 2px solid #000 !important;
    border-radius: 23px;
    margin-left: 1.75rem;
    margin-top: -15px !important;
  }
  .sidebar-option .create-sign-btn:hover,
  .sidebar-option .create-sign-btn:active,
  .sidebar-option .create-sign-btn:focus {
    padding: 6px 16px;
    line-height: normal;
    font-size: 14px !important;
    font-family: Roboto !important;
    font-weight: 700 !important;
    background: #1a1a1a !important;
    color: #fff !important;
    border: 2px solid #000 !important;
    border-radius: 23px !important;
    margin-left: 1.75rem !important;
    margin-top: -15px !important;
  }
  .userExpertiseFilertParent {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .filter-btn-wrapper > button {
    border-radius: 0 10px 0 0;
  }
  .dashboard-sidebar-wrapper {
    border-radius: 0 0 10px 0;
  }
}
