.membership-header-wrapper {
  display: none;
}
.wc-profile {
  background-color: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 35px;
}
@media screen and (max-width: 700px) {
  .membership-header-wrapper {
    display: block;
  }
  .wc-profile-mobile {
    padding: 20px 24px;
  }
  .viewPostPage {
    margin-top: 12px;
  }
}
