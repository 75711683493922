.menuButton :global(button) {
  background: none !important;
  color: black;
  border: none;
}

.menuButton :global(button):hover {
  background: none !important;
  color: black;
  border: none;
}
