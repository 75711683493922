.no-data-img {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}

.no-data-img img {
  width: 35%;
}

@media screen and (max-width: 700px) {
  .no-data-img {
    display: flex;
    justify-content: center;
  }

  .no-data-img img {
    width: 228px;
    height: auto;
  }
}
