.fullscreen-modal :global(.modal-content) {
  max-width: none !important;
  border-radius: 6px 6px 0 6px !important;
}
.fullscreen-modal :global(.modal-dialog) {
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
}
.fullscreen-modal :global(.modal-content) {
  width: max-content;
  height: max-content;
}

.fullscreen-modal :global(.modal-body) {
  width: max-content;
  height: max-content;
  padding: 0 !important;
}
.fullscreen-image {
  width: auto;
  height: auto;
  max-width: 700px;
  padding: 4px;
  min-width: 700px;
}

@media screen and (max-width: 480px) {
  .fullscreen-image {
    max-width: 95vw;
    min-width: 95vw;
  }
}
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .fullscreen-image {
    max-width: 470px;
    min-width: 470px;
  }
}
