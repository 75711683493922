.wrapper {
  display: flex;
  align-items: center;
}

.logo {
  width: auto;
  height: 45px;
  top: 22px;
  left: 32px;
}

.logoMain {
  font-weight: 600;
  font-size: 32px;
}

.logoSub {
  font-size: 20px;
  margin-left: 5px;
}

@media screen and (max-width: 700px) {
  .logo {
    width: 175px;
    height: auto;
  }
  .logo img {
    width: 150px;
    height: 45px;
  }
}

@media screen and (max-width: 350px) {
  .logo {
    width: 185px;
    height: auto;
    top: 36px;
    left: 12px;
  }
}
