.wrapper {
  padding: 20px;
  border-radius: 16px;
  overflow: auto;
  max-height: inherit;
}

/* Heading Section */
.heading {
  text-align: center;
  margin-bottom: 40px;
}

.headingTitle {
  color: #1a1a1a;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  margin: 0;
}

/* Input Container */
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.inputField {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.urlInputField {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}
.inputName {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.ulrInputName {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  width: max-content;
  min-width: max-content;
}
.inputBox {
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  transition: all 0.3s;
}

.inputBox:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.2);
}

/* Radio Buttons */
.radioContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 10px;
}

.radioOption {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radioContainer span {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
}

/* Multi-Select and Dropdown */
.selectContainer {
  width: 100%;
}

.selectContainer .Select__control {
  border-radius: 8px;
  border-color: #d1d1d1;
}

.selectContainer .Select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.2);
}

/* Description Section */
.description {
  margin-top: 40px;
}

.reactQuill {
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  padding: 8px;
}
.url-date-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
/* Buttons */
.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.fileInput {
  flex: 1;
}

.uploadButton,
.submitButton {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.uploadButton:hover,
.submitButton:hover {
  background-color: #0056b3;
}

.submitButton {
  width: 100%;
  text-align: center;
}
.buttonContainer {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .wrapper {
    padding: 16px;
  }

  .headingTitle {
    font-size: 24px;
  }

  .inputBox {
    font-size: 12px;
  }

  .uploadButton,
  .submitButton {
    font-size: 14px;
    padding: 10px 16px;
  }
}
