/* // KEEP */
.postBtnWrapper {
  padding-top: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}
.add-work-exp-modal :global(.modal-header) {
  padding: 12px 12px !important;
}
.add-work-exp-modal :global(.modal-content) {
  margin: 70px auto;
}
.add-work-exp-modal :global(.modal-header) button {
  font-size: 12px;
}
.add-work-exp-modal :global(.modal-header .modal-title) {
  font-size: 16px !important;
}
.exp-form-input {
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  font-size: 14px;
}
.postBtn {
  padding: 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}

.add-job-date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.add-job-date-input {
  font-size: 14px;
}

.add-job-date-label {
  padding-left: 5px;
  font-size: 14px;
}
@media screen and (max-width: 700px) {
  .add-work-exp-modal :global(.modal-header) {
    padding: 8px 12px !important;
  }
  .ended-on-label {
    margin-top: 10px;
  }
  .add-job-date-input {
    width: 95%;
  }
}
@media screen and (max-width: 360px) {
  .add-job-date-wrapper {
    flex-direction: column;
  }
  .add-job-date-input {
    width: 100%;
  }
}
