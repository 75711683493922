.user-location-submit {
  margin: 0 auto;
  font-size: 14px;
}
.location-update-modal > div {
  width: 80%;
  margin: 70px auto 0;
}
.user-contact-submit {
  margin: 10px auto;
  font-size: 14px;
}
.update-user-input {
  font-size: 14px;
}
.location-modal-header {
  font-size: 14px !important;
}
.location-modal-header h5 {
  font-size: 16px !important;
}
.location-modal-header button {
  font-size: 12px !important;
}
.location-modal-header button {
  font-size: 12px !important;
}
@media screen and (max-width: 700px) {
  .location-update-modal > div {
    width: 80%;
    margin: 0 auto;
  }
  .user-location-submit {
    font-size: 14px;
    padding: 2px 8px;
  }
  .user-contact-submit {
    font-size: 14px;
  }
  .location-modal-header {
    font-size: 14px !important;
    padding: 10px 16px !important;
  }
  .location-modal-header h5 {
    font-size: 16px !important;
  }
}
