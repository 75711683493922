/* Trend.module.css */

.dashboard-search {
  padding: 0 10px 20px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
}

.searchInput-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  gap: 10px;
}

.userPicture {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 1px solid #e6e6e6;
  object-fit: contain;
}

.dashboard-search-input {
  border-radius: 50px !important;
  border: 1px solid #666;
  background: #fff;
  padding: 16px 18px;
  color: #666;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 450px;
  height: 48px;
}

.dashboard-search-attachment-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  padding: 0px 4px 0 12px;
  margin-top: 16px;
}

.attachment-btn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  text-align: left;
  color: #1a1a1a;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.attachment-btn:last-child {
  margin-right: 0px;
}

.attachment-btn img {
  height: 20px;
  margin-right: 8px;
}

.text-editor-quill {
  margin: 1rem 0;
  margin-bottom: 40px;
  height: 150px;
  max-height: 150px;
  min-height: auto;
  font-size: 14px;
}

.text-editor-quill :global(.ql-blank) {
  font-size: 14px !important;
  border-radius: 16px !important;
  min-height: auto;
}

.text-editor-quill :global(.quill) {
  min-height: max-content !important;
  margin: 0 !important;
}

.text-editor-quill :first-child {
  border-radius: 16px 16px 0 0;
}

.text-editor-quill :last-child {
  border-radius: 0 0 16px 16px;
}

.postBtn {
  padding: 4px 20px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  border-radius: 23px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}

.noit-professional-only {
  background-color: #fff !important;
  color: #1a1a1a !important;
  font-size: 14px;
  border-radius: 24px;
  font-weight: 500;
}

.char-count {
  font-size: 12px;
  color: #666;
  margin-bottom: -4px;
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
}

.char-count-exceed {
  color: red;
}

.postBtn:disabled {
  background-color: #ccc !important;
  border: 1px solid #ccc;
  color: #666;
  cursor: not-allowed;
}

.filter-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding-right: 10px;
  margin-bottom: 24px;
}

.trends-filter-hr {
  border-bottom: 1px solid #ccc;
  width: calc(100% - 285px);
  padding: 0 5px;
}
.trends-filter-label {
  display: flex;
  min-width: max-content;
  font-size: 12px;
}
.trends-filter-btns {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: max-content;
}
.trends-filter-btns button {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}

.trends-filter-btns button.active {
  background-color: #1a1a1a !important;
  color: #fff !important;
}

.createTrendModal :global(.modal-dialog) {
  max-width: none;
}
.createTrendModal :global(.modal-content) {
  width: 600px;
  max-width: 800px;
  margin: 0 auto;
}
.create-trend-btn {
  padding: 6px 16px;
  line-height: 21px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  color: var(--grey-grey-00, #fff);
  background-color: var(--grey-grey-90, #1a1a1a) !important;
  border: 1px solid #ccc !important;
  border-radius: 10px;
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-trend-btn-mobile {
  color: var(--grey-grey-00, #fff);
  border-radius: 12px;
  border: 1px solid var(--grey-grey-100, #000) !important;
  background: var(--grey-grey-100, #000) !important;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: center;
}
.create-trend-btn-mobile svg {
  height: 24px !important;
  width: 24px !important;
}
.menuButton :global(button) {
  background: none !important;
  color: black;
  border: none;
}
.attachmentWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}
.menuButton :global(button):hover {
  background: none !important;
  color: black;
  border: none;
}
.loadMoreBtWrapper {
  margin: 22px 0px 35px;
  text-align: center;
}
.topScroll {
  padding: 4px 12px;
  background-color: #fff !important;
  border: 1px solid #1a1a1a !important;
  color: #1a1a1a !important;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 23px;
}

.loadMoreBtn {
  border: none;
  border-radius: 50%;
  padding: 4px 8px;
  background: #fff;
}
@media screen and (max-width: 700px) {
  .create-trend-mobile-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 110px;
    right: 18px;
    z-index: 99;
  }
  .loadMoreBtnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
  }
  .trend-main-wrapper {
    margin-top: 12px;
    margin-bottom: 110px;
  }
  .trends-filter-btns {
    display: flex;
    gap: 10px;

    min-width: max-content;
  }
  .trends-filter-btns button {
    margin-right: 0;
  }
  .filter-wrapper {
    gap: 8px;
    padding: 0 12px;
    margin-bottom: 18px;
  }
  .userPicture {
    height: 35px;
    width: 35px;
  }
  .attachment-btn img {
    margin-right: 4px;
    height: 16px;
  }
  .attachment-btn {
    color: #1a1a1a;
    font-size: 12px;
  }
  .attachmentWrapper {
    display: flex;
    gap: 12px;
  }
  .postBtn {
    padding: 4px 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 420px) {
  .filter-wrapper {
    gap: 10px;
    padding: 0 12px;
  }
  .trends-filter-btns {
    gap: 10px;
  }
}
@media screen and (max-width: 385px) {
  .trends-filter-hr {
    display: none;
  }
}
