.fileIcon {
  height: 18px;
}
.fileName {
  font-size: 14px;
}
.filesContainer {
  padding: 12px 0px;
}

.fileContainer {
  padding: 0px 12px 4px 0px;
}

.closeIcon {
  position: relative;
  top: -4px;
  font-size: 12px;
  left: 4px;
  cursor: pointer;
}
@media screen and (max-width: 700px) {
  .fileName {
    font-size: 12px;
  }
  .fileContainer {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
