.blogs-wrapper {
  margin: 10px 30px;
}

.blogs-h1-wrapper {
  text-align: center;
  width: 80%;
  margin: 5px auto 30px auto;
  font-family: 'Roboto';
}

.blogs-h2-wrapper {
  text-align: left;
  margin: 10px 0;
  font-family: 'Roboto';
}

.blogs-body-wrapper {
  text-align: left;
  margin: 10px 0 20px 0;
  font-family: 'Roboto';
}
