.signup-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: -2px 4px 8px rgba(153, 153, 153, 0.25);
  padding: 10px 0 10px 0;
}
.signup-modal-header img {
  height: 60px;
  width: auto;
}
.signinGoogle-img {
  width: 50%;
  margin: 0 auto;
  cursor: pointer;
}
.create-account {
  cursor: pointer;
  font-size: 18px;
}
.header-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 40px 0;
  text-decoration: none;
  color: black;
  text-align: center;
}
.btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
.btn-wrapper button {
  font-size: 24px;
  font-weight: 700;
  width: 300px;
  border-radius: 20px;
  border-color: black;
  border: solid black;
  color: black;
}

.btn-wrapper .profuser-btn,
.profuser-btn:active,
.profuser-btn:focus {
  background-color: #cdc0f2 !important;
  color: black !important;
  border-color: black !important;
}

.btn-wrapper .non-profuser-btn,
.non-profuser-btn:active,
.non-profuser-btn:focus {
  background-color: #f2e1c0 !important;
  color: black !important;
  border-color: black !important;
}
.alreadylogin-wrapper {
  display: flex;
  gap: 5px;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  margin: 40px 0 20px 0;
}
.or-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  color: rgb(115, 115, 115, 1);
  margin: 0 auto;
  width: 75%;
}

@media screen and (max-width: 700px) {
  .signup-modal-header img {
    height: 40px;
    width: auto;
  }
  .header-subtitle {
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    margin: 10px 0 20px 0;
  }
  .header-subtitle span {
    font-size: 14px;
  }
  .signup-modal > div {
    width: 90%;
    margin: 0 auto;
  }
  .btn-wrapper {
    flex-direction: column;
    gap: 0;
    margin: 0 0 0 0;
    justify-content: center;
    align-items: center;
  }
  .signinGoogle-img {
    width: 70%;
    margin: 0 auto;
  }
  .or-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    color: rgb(115, 115, 115, 1);
    margin: 20px auto;
    width: 75%;
  }
  .btn-wrapper button {
    font-size: 18px;
    font-weight: 700;
    width: 230px;
    height: 80px;
    border-radius: 20px;
    border-color: black;
    border: solid black;
    text-align: center;
    --bs-btn-padding-x: 0;
  }
  .alreadylogin-wrapper {
    margin-top: 40px;
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .create-account {
    cursor: pointer;
    font-size: 16px;
  }
}

@media (min-width: 701px) and (max-width: 1120px) {
  .header-subtitle {
    margin: 30px 0 60px 0;
  }
  .btn-wrapper {
    flex-direction: column;
    width: 100%;
  }
  .btn-wrapper button {
    width: 350px;
    height: 120px;
  }
}
