.dateButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.dateButtonContainer {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.dateButton {
  padding: 12px 20px;
  width: 200px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #f1f1f1;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, width 0.3s;
  text-align: center;
}

.dateButton:hover {
  background-color: #e1e1e1;
  border-color: #999999;
}

.dateButton.selected {
  background-color: #000000;
  color: #ffffff;
  width: 220px;
}

.timeButtonsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  max-height: 240px;
  overflow-y: auto;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.timeButtonsContainer::-webkit-scrollbar {
  width: 8px;
}

.timeButtonsContainer::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.timeButtonsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* scheduleMeeting.module.css */
.selecteddateTime {
  font-size: 14px;

  font-weight: normal;
}

.dateSelectorModalBody {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: #333333;
}

.dateButtonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.dateButtonContainer {
  margin: 10px 0;
}

.dateButton {
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 200px;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.selected {
  background-color: #333333;
  color: #ffffff;
}

.timeButtonsContainer {
  margin-top: 10px;
}

.timeButton {
  text-align: center;

  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */

  padding: 8px 10px;
  background-color: #f0f0f0;
  color: #333;

  border-radius: 4px;
  margin: 5px;
  cursor: pointer;

  font-size: 14px;
}

.timeButton:hover:not(.timeButtonDisabled) {
  background-color: #d4d4d4; /* Slightly darker background on hover */
  border-color: #999; /* Darker border on hover */
}

.timeButton:active:not(.timeButtonDisabled) {
  background-color: #c0c0c0; /* Darker background when clicked */
  transform: scale(0.98); /* Small shrink effect on click */
}

/* Disabled button styles */
.timeButtonDisabled {
  cursor: not-allowed;
  background-color: #e1e1e1; /* Light gray background for disabled state */
  color: #bbb; /* Gray text for disabled state */
  border: 2px solid #e1e1e1; /* Same color as background to blend in */
  box-shadow: none; /* No shadow on disabled state */
}

.scheduleForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inputField {
  width: 100%;
  padding: 8px 10px;
  margin: 10px 0;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 14px;
}

.textareaField {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  height: 100px;
}

.scheduleButton {
  padding: 10px 16px;
  background-color: #333333;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
}

.scheduleButton:hover {
  background-color: #555555;
}
.meetingWindowOffContainer {
  font-size: 14px;
  text-align: center;
  font-family: 'Roboto';
}
.meetingMessageButton {
  width: max-content;
  height: max-content;
  background: #fff !important;
  color: #1a1a1a !important;
  margin: 4px 0;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 700px) {
  .dateSelectorModal :global(.modal-content) {
    width: 80%;
    margin: 0 auto;
  }
  .dateButtonsContainer {
    width: 100% !important;
    padding: 0;
    margin: 0;
  }
  .dateButtonsContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
  }
}
