.job-alert-btn {
  padding: 8px 18px;
  line-height: 22px;
  font-size: 14px; /* Font size to keep it readable */
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: #1a1a1a !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 10px;
  width: 100%;
  margin-top: 8px; /* More spacing for better visual balance */
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-alert-btn-mobile {
  display: flex;
  position: fixed;
  bottom: 230px;
  right: 18px;
  z-index: 99;
  color: #fff;
  border-radius: 12px;
  border: 3px solid #000;
  background: #fff !important;
  align-items: center;
  width: 48px; /* Increased size for better touch target */
  height: 48px;
  justify-content: center;
}

.job-alert-btn-mobile svg {
  height: 26px !important;
  width: 26px !important;
}

.job-alert-modal {
  max-width: 720px;
  width: 90%;
  margin: auto;
  font-size: 14px; /* Set the base font size for consistency */
}

.modal-description {
  font-size: 14px;
  color: #6c757d; /* Subtle gray for secondary text */
  margin-bottom: 15px;
}

.alert-modal-title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333; /* Darker shade for better readability */
  margin-bottom: 0;
}
.alert-modal-subtitle {
  font-size: 14px;
  font-weight: bold;
}
.selected-qualifications {
  margin-top: 18px;
  font-size: 14px;
  color: #495057; /* Slightly darker for better legibility */
}

.selected-qualifications ul {
  padding-left: 20px;
  font-size: 14px; /* Consistent font size across lists */
}

.selected-qualifications li {
  list-style-type: disc;
  margin-bottom: 5px; /* Space between items for better separation */
}

.qualification-checkbox {
  display: inline-block;
  margin-right: 12px; /* Adjusted for better spacing */
  font-size: 14px; /* Keep consistent font size */
}

/* Style for the Select component */
.react-select-container {
  font-size: 14px !important; /* Ensure consistency with the modal */
  margin-bottom: 15px; /* Added spacing for better visual clarity */
}

.delete-location-btn {
  margin-left: 10px;
  font-size: 18px;
  color: #fff;
  background-color: #dc3545; /* Red for delete action */
  border-radius: 50%;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.delete-location-btn:hover {
  background-color: #c82333;
}
.selected-loaction-wrapper {
  gap: 12px;
}
.alert-contrat-wrapper {
  flex-direction: column;
  gap: 12px;
}
/* Responsive Styling */
@media (max-width: 768px) {
  .create-jobs-btn {
    font-size: 12px; /* Smaller font size for mobile */
    padding: 6px 14px; /* Adjust padding */
  }

  .job-alert-btn-mobile {
    width: 42px;
    height: 42px;
  }

  .job-alert-btn-mobile svg {
    height: 20px !important;
    width: 20px !important;
    color: #ff0000;
  }

  .modal-description,
  .qualification-title,
  .selected-qualifications,
  .qualification-checkbox {
    font-size: 12px; /* Decrease font size on mobile for compactness */
  }
  .alert-contrat-wrapper {
    flex-direction: column;
    gap: 6px;
  }
}
